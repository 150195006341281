import ProtectedRoute from '_components/ProtectedRouter'
import store from '_redux/store'
import { createBrowserHistory } from 'history'
import React from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import MainPage from './Main'
import SignInPage from './Signin'

const history = createBrowserHistory()

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path='/sign-in' exact component={SignInPage} />
          <ProtectedRoute path='/' component={MainPage} />
        </Switch>
      </Router>
    </Provider>
  )
}
export default App
