import React from 'react'

import { Card, Col } from 'react-bootstrap'
import noAvatar from '_images/noAvartar.jpg'

function UserCard({ info }) {
  const { name, email, photoUrl } = info

  return (
    <>
      <Col md={6} lg={4} className='liveReport__newUsers-layout'>
        <Card style={{ width: '18rem' }}>
          <Card.Img
            variant='top'
            src={photoUrl || noAvatar}
            className='liveReport__newUsers-avatar'
          />
          <Card.Body className='liveReport__newUsers-item'>
            <Card.Title className='liveReport__newUsers-item-name-user'>{name}</Card.Title>
            <Card.Text style={{ fontSize: '18px' }}>{email}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

export default UserCard
