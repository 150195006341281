import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import UserDetail from './UserDetail'
import UserList from './UserList'

function Users() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/list-users`} component={UserList} />
      <Route path={`${path}/detail-user`} component={UserDetail} />
    </Switch>
  )
}

export default Users
