import axiosClient from './axiosClient'

const usersApi = {
  getAllUsers: () => {
    const url = '/list-users'
    return axiosClient.get(url)
  },
  getUsersByDate: (start, end) => {
    const url = `/list-users?start=${start}&end=${end}`
    return axiosClient.get(url)
  },
  updateDeliveryByIdUser: (uid, status) => {
    const url = `/update-paid-features?id=${uid}`
    return axiosClient.patch(url, status)
  },
  getListUserWithScheduleStatus: () => {
    const url = '/list-users-with-schedule-status'
    return axiosClient.get(url)
  },
  updateScheduleStatus: (id, data) => {
    const url = `/update-schedule-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  getListUserWithPremiumStatus: () => {
    const url = '/list-users-with-premium-status'
    return axiosClient.get(url)
  },
  updatePremiumStatus: (id, data) => {
    const url = `/update-premium-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  getListSubscription: () => {
    const url = '/get-list-subscription-payment'
    return axiosClient.get(url)
  },
  updateShopSubscriptionStatus: (subId, data) => {
    const url = `/update-shop-subscription-status?id=${subId}`
    return axiosClient.patch(url, data)
  },
  updateStatusSubscription: (subId, status) => {
    const url = `/update-subscription-status?id=${subId}&status=${status}`
    return axiosClient.patch(url)
  },
  test: () => {
    const url = '/list-packages'
    return axiosClient.get(url)
  },
  test1: (data) => {
    const url = '/create-package'
    return axiosClient.post(url, data)
  },
  test2: (id, data) => {
    const url = `/update-package?id=${id}`
    return axiosClient.patch(url, data)
  },
  createLazadaAdminApp: (postData) => {
    const url = 'create-lazada-admin-app'
    return axiosClient.post(url, postData)
  },
}

export default usersApi
