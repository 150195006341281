import HitPay from '_images/hitpay-logo.jpg'
import {
  PAYMENT_METHOD_HIT_PAY,
  PAYMENT_METHOD_TRANSFER,
  SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN,
  SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_HOST,
  SUBSCRIPTION_PAYMENT_STATUS_FAILED,
  SUBSCRIPTION_PAYMENT_STATUS_PAID,
  SUBSCRIPTION_PAYMENT_STATUS_UNPAID,
  SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
  SUBSCRIPTION_STATUS_APPROVED,
  SUBSCRIPTION_STATUS_CANCEL,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_REFUND,
  SUBSCRIPTION_STATUS_REFUNDED,
} from '_utils/constants'
import { isNull } from 'lodash'
import moment from 'moment'
import React from 'react'
import { AiFillCreditCard } from 'react-icons/ai'

const statusMapping = {
  [SUBSCRIPTION_PAYMENT_STATUS_PAID]: { class: 'bg-success', label: 'Paid' },
  [SUBSCRIPTION_PAYMENT_STATUS_UNPAID]: { class: 'bg-warning', label: 'Unpaid' },
  [SUBSCRIPTION_PAYMENT_STATUS_VERIFY]: { class: 'bg-info', label: 'Verify' },
  [SUBSCRIPTION_PAYMENT_STATUS_FAILED]: { class: 'bg-danger', label: 'Failed' },
  [SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN]: {
    class: 'bg-secondary',
    label: 'Cancel By Admin',
  },
  [SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_HOST]: { class: 'bg-secondary', label: 'Cancel By User' },
  default: { class: 'bg-info', label: 'N/A' },
}

const statusToBadgeMapping = {
  [SUBSCRIPTION_STATUS_APPROVED]: { class: 'bg-success', label: 'Approved' },
  [SUBSCRIPTION_STATUS_PENDING]: { class: 'bg-warning', label: 'Pending' },
  [SUBSCRIPTION_STATUS_CANCEL]: { class: 'bg-secondary', label: 'Cancel' },
  [SUBSCRIPTION_STATUS_REFUNDED]: { class: 'text-secondary', label: 'Refunded' },
  [SUBSCRIPTION_STATUS_REFUND]: { class: 'text-secondary', label: 'Refund' },
}

function RequestItem(props) {
  const { item, index, handleChangeStatus, onViewPaymentInfo, onSelectRequestItem } = props
  const { class: badgeClass, label } = statusMapping[item.paymentStatus] || statusMapping.default

  const defaultBadge = { class: 'bg-info', label: 'N/A' }
  const badge = statusToBadgeMapping[item.status] || defaultBadge

  return (
    <tr>
      <th scope='row' className='table__index' style={{ width: '30px' }}>
        {index + 1}
      </th>

      <td className='table__item'>
        <span>{item.createdUserName}</span>
      </td>

      <td className='table__item'>
        <span>{item.createdUserPhone}</span>
      </td>

      <td className='table__item'>
        <span>{item.createdUserEmail}</span>
      </td>

      <td className='table__item'>
        <span>{item.name}</span>
      </td>

      <td className='table__item'>
        <span>${item.price}</span>
      </td>

      <td className='table__item'>
        <span>
          {!isNull(item.expireTimestamp)
            ? moment.unix(item.expireTimestamp).format('DD/MM/YYYY')
            : 'N/A'}
        </span>
      </td>

      <td className='table__item'>
        {item.paymentType === PAYMENT_METHOD_HIT_PAY ? (
          <a href={item.paymentLink} target={'_blank'} rel='noreferrer'>
            <img src={HitPay} className={'img-fluid me-2'} width={30} alt='HitPay' />
            <span>Hitpay</span>
          </a>
        ) : item.paymentType === PAYMENT_METHOD_TRANSFER ? (
          <div className={'d-flex flex-column'}>
            <button
              type={'button'}
              className='btn p-0 group'
              onClick={() => onViewPaymentInfo(item)}
              style={{ color: '#7c797a', transform: 'translateX(-11px)' }}
            >
              <AiFillCreditCard color={'#f1c40f'} size={30} className={'me-2'} />
              <span className={'f-14'}>Payment Banking</span>
            </button>
            {/* <ImgGallery image={item?.paymentScreenshot} width={50} /> */}
          </div>
        ) : (
          'Free'
        )}
      </td>

      <td className='table__item'>
        <span className={`badge ${badge.class}`}>{badge.label}</span>
      </td>

      <td className='table__item'>
        <span className={`badge ${badgeClass}`}>{label}</span>
      </td>

      <td>
        <select
          name='action-status'
          id='action-status'
          value={item.status}
          onChange={(e) => {
            onSelectRequestItem(item)
            handleChangeStatus(e, item.id)
          }}
          className={'custom-form-select form-select-sm border'}
        >
          <option value=''>Choose status</option>
          <option value={SUBSCRIPTION_STATUS_REFUNDED} disabled={item.price === 0}>
            Refund
          </option>
        </select>
      </td>
    </tr>
  )
}

export default RequestItem
