import { combineReducers } from 'redux'
import contactReducer from './contact'
import dashBroadReducer from './dashboard'
import eventsReducer from './events'
import groupsReducer from './groups'
import lazadaReducer from './lazada'
import ordersReducer from './orders'
import packageReducer from './package'
import shopsReducer from './shops'
import subscriptionReducer from './subscription'
import usersReducer from './users'

const rootReducer = combineReducers({
  groups: groupsReducer,
  dashBroad: dashBroadReducer,
  events: eventsReducer,
  users: usersReducer,
  orders: ordersReducer,
  shops: shopsReducer,
  subscription: subscriptionReducer,
  package: packageReducer,
  lazada: lazadaReducer,
  contact: contactReducer,
})

export default rootReducer
