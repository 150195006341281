/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import { isEmpty } from 'lodash'
import moment from 'moment'
import { DATE_FORMAT, OPTION_CURRENCY_SG } from './constants'

export function mapLazadaAppInfo(paymentRequests, appLazada) {
  const shopIdToAppInfoMap = {}

  appLazada.forEach((app) => {
    app.shopSeller.forEach((shop) => {
      shopIdToAppInfoMap[shop.shopId] = { ...app }
    })
  })

  // Thêm lazadaAppInfo vào từng payment request
  paymentRequests.forEach((request) => {
    if (shopIdToAppInfoMap[request.shopId]) {
      request.lazadaAppInfo = shopIdToAppInfoMap[request.shopId]
    } else {
      request.lazadaAppInfo = null // hoặc bạn có thể để undefined, tùy thuộc vào yêu cầu của bạn
    }
  })

  return paymentRequests
}

export const findLazadaAppKeyById = (paymentRequestId, _listAppLazada) => {
  for (const app of _listAppLazada) {
    for (const seller of app.shopSeller) {
      if (seller.lazadaFeaturePaymentId === paymentRequestId) {
        return app.lazadaAppKey
      }
    }
  }
  return null // Return null if not found
}

export function clearUrlParams(url) {
  // Tạo một đối tượng URL từ URL hiện tại hoặc URL được cung cấp
  const urlObj = new URL(url || window.location.href)

  // Kiểm tra xem URL có chứa dấu chấm hỏi hay không
  if (urlObj.search) {
    // Lặp qua tất cả các tham số trong URL
    for (const param of urlObj.searchParams.keys()) {
      // Xóa từng tham số
      urlObj.searchParams.delete(param)
    }

    // Trả về URL đã được xóa các tham số
    return urlObj.toString()
  }

  // Nếu URL không có tham số, trả về URL ban đầu
  return urlObj.toString()
}

export const formatNumberToCurrency = (
  number,
  countryDollar = 'en-US',
  options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
) => {
  if (!number && !countryDollar && isEmpty(options)) throw new Error('Missing params')

  const currency = new Intl.NumberFormat(countryDollar, options)
  return currency.format(number).replace('$', '').trim()
}

export const formatNumberToCurrencyHasDot = (inputText = '') => {
  const hasDot = inputText.includes('.')

  const sanitizedInput = inputText.replace(/[^\d.]/g, '')

  // Separate integerPart and decimalPart
  const [integerPart, decimalPart] = sanitizedInput.split('.')

  /**
   * Flow typing input:
   * input: 1000
   * format: 1,000
   * input: 1,000.
   * format: 1,000.
   * input: 1,000.15
   * format: 1,000.15
   */
  let formattedIntegerPart = ''

  if (integerPart && !hasDot) {
    formattedIntegerPart = formatNumberToCurrency(integerPart, 'en-SG', OPTION_CURRENCY_SG)
  }

  if (integerPart && hasDot) {
    formattedIntegerPart = `${formatNumberToCurrency(integerPart, 'en-SG', OPTION_CURRENCY_SG)}.`
  }

  const formattedDecimalPart = decimalPart ? `${decimalPart}` : ''

  const formattedInput = `${formattedIntegerPart}${formattedDecimalPart}`

  return formattedInput
}

export const clearDuplicate = (array1, array2) => {
  const a = new Set(array1?.map((x) => x.id))
  const b = new Set(array2?.map((x) => x.id))
  return [...array1?.filter((x) => !b.has(x.id)), ...array2?.filter((x) => !a.has(x.id))]
}

export const checkDuplicate = (indexDB, array) => {
  const checkDataIndex = indexDB.filter((dataIndex) => {
    return array.some((dataArray) => {
      return dataIndex.id === dataArray.id
    })
  })
  return checkDataIndex?.length === 0
}

export const getTimeZoneHour = () => {
  const date = new Date()
  const timeZone = date.getTimezoneOffset()
  return Math.abs(timeZone / 60)
}

// input is String (same as '28-12-2021 03:00')
export const convertToCurrentGMT = (timeString) => {
  const timeZoneToMilliseconds = getTimeZoneHour() * 60 * 60 * 1000
  const timeMilliseconds =
    moment(timeString, DATE_FORMAT).toDate().getTime() + timeZoneToMilliseconds
  return moment(timeMilliseconds).format(DATE_FORMAT)
}

// input is number
export const convertTimestampToCurrentGMT = (timestamp) => {
  const timeZoneToMilliseconds = getTimeZoneHour() * 60 * 60 * 1000
  return (timestamp * 1000 + timeZoneToMilliseconds) / 1000
}

export const editDistance = (s1 = '', s2 = '') => {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j
      } else if (j > 0) {
        let newValue = costs[j - 1]
        if (s1[i - 1] !== s2[j - 1]) {
          newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
        }
        costs[j - 1] = lastValue
        lastValue = newValue
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue
    }
  }
  return costs[s2.length]
}

export const calculatePercentMatch = (s1 = '', s2 = '') => {
  let longer = s1.toLowerCase()
  let shorter = s2.toLowerCase()
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength === 0) {
    return 0.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}
