import React from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import LazadaItem from './LazadaItem'

const tableHeaders = [
  '#',
  'Name',
  'Created Date',
  'Description',
  'For Project',
  'Active',
  'Limit',
  'Actions',
]
const ListLazada = ({ toggleModal, loading }) => {
  const { listLazadaPackage } = useSelector((state) => state.lazada)

  return (
    <div className='scroll__mobile'>
      <div className='table-responsive'>
        <table className='table subscription'>
          <thead>
            <tr className='table-list-title'>
              {tableHeaders.map((headerText, index) => (
                <th scope='col' key={index}>
                  <span>{headerText}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={tableHeaders.length} className='text-center'>
                  Loading...
                </td>
              </tr>
            )}

            {!loading && listLazadaPackage.length
              ? listLazadaPackage.map((info, i) => (
                  <LazadaItem info={info} key={info.id} index={i} toggleModal={toggleModal} />
                ))
              : null}

            {!loading && !listLazadaPackage.length && (
              <tr>
                <td colSpan={tableHeaders.length} className='text-center'>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer autoClose={2000} limit={1} />
    </div>
  )
}

export default ListLazada
