import ordersApi from '_api/ordersApi'

const GET_LIST_ORDER_BY_DATE = 'GET_LIST_ORDER_BY_DATE'
const GET_ALL_ORDERS = ' GET_ALL_ORDERS'
const SET_IS_SYNC_DATA_ORDER = 'SET_IS_SYNC_DATA_ORDER'


const initialState = {
  listAllOrders: null,
  listOrderByDate: null,
  isSyncDataOrder: false
}

export const setIsSyncDataOrder = (bol)  => (dispatch) => {
  dispatch({
    type: SET_IS_SYNC_DATA_ORDER,
    payload:bol
  })
} 


export const getOrdersByDate = (start, end) => async (dispatch) => {
  let listOrderByDate = []
  try {
    const response = await ordersApi.getListOrder(start, end)
    if (response?.msgResp) {
      listOrderByDate = response.msgResp
    }
    dispatch({
      type: GET_LIST_ORDER_BY_DATE,
      payload: listOrderByDate,
    })
  } catch (error) {}
}

export const getAllOrder = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_ORDERS,
    payload: data,
  })
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS: {
      return {
        ...state,
        listAllOrders: [...action.payload],
      }
    }

    case GET_LIST_ORDER_BY_DATE: {
      return {
        ...state,
        listOrderByDate: [...action.payload],
      }
    }

    case SET_IS_SYNC_DATA_ORDER: {
      return {
        ...state,
        isSyncDataOrder: action.payload,
      }
    }

    default:
      return state
  }
}

export default ordersReducer
