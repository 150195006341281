import * as yup from 'yup'

const schema = yup.object({
  subscriptionType: yup.number(),
  packageType: yup.number(),
  description: yup
    .string()
    .min(3, 'Description must be at least 3 characters')
    .required('Description is required'),
  limit: yup.number().typeError('Amount must be a number').integer().required('Limit is required'),
  active: yup.boolean(),
  variations: yup
    .array()
    .of(
      yup.object().shape({
        price: yup
          .number()
          .typeError('Amount must be a number')
          .integer()
          .required('Price is required'),
        inputValue: yup.number().integer().required('Expire value is required'),
        isMonthly: yup.boolean().required('Select intervals is required'),
        discountPrice: yup
          .mixed()
          .test(
            'isNumberOrEmptyString',
            'Discount must be a number or an empty string',
            (value) => {
              if (value === null || value === undefined || value === '') {
                return true
              }
              if (typeof value === 'string' && value.trim() === '') {
                return true
              }
              // eslint-disable-next-line no-restricted-globals
              return !isNaN(value)
            },
          )
          .test(
            'discountLessThanPrice',
            'Discount must be less than or equal to Price',
            // eslint-disable-next-line func-names
            function (discountPrice) {
              // eslint-disable-next-line no-restricted-globals
              const { price } = this.parent
              if (price === null || price === undefined || price === '') {
                return true
              }
              if (discountPrice === '') {
                return true
              }
              return parseFloat(discountPrice) <= parseFloat(price)
            },
          ),
        available: yup.boolean(),
      }),
    )
    .test(
      'atLeastOneVariant',
      'At least one variant is required',
      (variations) => variations && variations.length > 0,
    ),
})

export default schema
