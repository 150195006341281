import React from 'react'
import LongText from '../../components/LongText'

function ContactList({ paginationPage, listContact }) {
  return (
    <>
      <div className='container-fluid'>
        <h3 className='user__list-heading'>Contact Requests</h3>
        <div className='scroll__mobile'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='user__list-title'>
                  <th style={{ width: '32px' }}>#</th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Message</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listContact
                  ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                  ?.map((user, i) => (
                    <tr key={i}>
                      <th scope='row' className='user__list-number-index'>
                        {i + 1}
                      </th>
                      <td className='user__list-name-avatar'>{user?.name}</td>
                      <td className='user__list-email'>{user?.email}</td>
                      <td className='user__list-phone-number'>
                        <LongText content={user?.content} limit={255} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
      </div>
    </>
  )
}

export default ContactList
