import axiosClient from './axiosClient'

const lazadaApi = {
  getListApp: () => {
    const url = '/list-lazada-app'
    return axiosClient.get(url)
  },
  createLazadaApp: (data) => {
    const url = '/create-lazada-app'
    return axiosClient.post(url, data)
  },
  updateStatusPaymentRequest: (data, params) => {
    const url = '/update-shop-lazada-feature'
    return axiosClient.patch(url, data, {
      params,
    })
  },
}

export default lazadaApi
