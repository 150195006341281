/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import useQuery from '_hooks/useQuery'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Button, Image, Accordion, Spinner } from 'react-bootstrap'

import { useIndexedDB } from 'react-indexed-db'
import groupIcon from '_images/group-icon.png'
import { IS_ACTIVE } from '_utils/constants'

import activeGroupIcon from '_images/activeGroupIcon.png'
import unActiveGroupIcon from '_images/unActiveGroupIcon.png'

import GroupDetailListAdmin from '../components/GroupDetailListAdmin'
import GroupDetailListUser from '../components/GroupDetailListUser'
import ButtonUpdateStatusGroup from '../components/ButtonUpdateStatusGroup'

import './style.css'

function GroupDetail() {
  const { getByIndex } = useIndexedDB('groups')

  const [loading, setLoading] = useState(true)

  const params = useQuery()
  const idGroup = params.get('gid')

  const [status, setStatus] = useState()
  const [groupById, setGroupById] = useState()

  useEffect(() => {
    getByIndex('id', idGroup).then((dataGroups) => {
      setGroupById(dataGroups)
    })
  }, [status])

  useEffect(() => {
    if (groupById) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [groupById])

  const newStatus = (status) => {
    setStatus(status)
  }

  if (loading) {
    return <Spinner animation='border' variant='info' />
  }

  return (
    <>
      <Container style={{ marginTop: '60px' }}>
        <div className='group__detail'>
          <div className='group__detail__info-layout'>
            <div className='group__detail__avatar'>
              <Image src={groupById?.photoUrl || groupIcon} alt='' />
            </div>

            <div className='group__detail__info'>
              <div className='group__detail__info-box'>
                <p className='group__detail__info-name'>{groupById?.name}</p>

                <div className='group__detail__info-active'>
                  <Image
                    src={groupById?.active === IS_ACTIVE ? activeGroupIcon : unActiveGroupIcon}
                    className='group__detail-active-img'
                    roundedCircle
                    alt=''
                  />
                  <span style={{ fontSize: '14px' }}>
                    {groupById?.active === IS_ACTIVE ? '(Active)' : '(Inactive)'}
                  </span>
                </div>
              </div>

              <h3 className='group__detail__created-date'>
                Date created: {moment.unix(groupById?.createdAt).format('DD/MM/YYYY')}
              </h3>
            </div>
          </div>

          <div className='group__detail-actions'>
            <LinkContainer to={`/events/list-event?gid=${idGroup}`}>
              <Button className='group__detail-actions-watch-event-btn'>Watch events</Button>
            </LinkContainer>

            <ButtonUpdateStatusGroup
              gid={idGroup}
              isActive={groupById?.active}
              newStatus={newStatus}
            />
          </div>
        </div>

        <Accordion defaultActiveKey='0' style={{ marginTop: '20px' }}>
          <Accordion.Item eventKey='0'>
            <Accordion.Header className='group__deatil__desc-title'>
              Group description
            </Accordion.Header>
            <Accordion.Body className='group__deatil__desc-content-text'>
              {groupById?.description}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className='group__detail-list-item'>
          <h3 className='group__detail-admin-list'>Admin</h3>
          <GroupDetailListAdmin members={groupById?.members} />
          <h3 className='group__detail-member-list'>Users</h3>
          <GroupDetailListUser members={groupById?.members} />
        </div>
      </Container>
    </>
  )
}
export default GroupDetail
