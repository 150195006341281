import React from 'react'
import ListPaymentRequest from './ListPaymentRequest'

const Subscription = () => {
  return (
    <div className='container-fluid'>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h3 className='table-heading'>List Subscription Payment Request</h3>
      </div>
      <ListPaymentRequest />
    </div>
  )
}

export default Subscription