/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import lazadaAppApi from '_api/lazadaApi'
import 'react-toastify/dist/ReactToastify.css'

function ModalApprove({
  show,
  toggleShow,
  setLazadaAppKey,
  lazadaAppKey,
  listApp,
  shopWithLazadaFeature,
}) {
  return (
    <>
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Lazada feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {listApp.length > 0 && (
            <Form.Group className='mb-3'>
              <Form.Label>Lazada App</Form.Label>
              <Form.Select onChange={(e) => setLazadaAppKey(e.target.value)}>
                <option value=''>Choose App</option>
                {listApp.map((app) => (
                  <option key={app.id} value={app.lazadaAppKey}>
                    {app.lazadaAppName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='schedule__feature__cancel-btn'
            variant='danger'
            style={{ borderRadius: '6px' }}
            onClick={toggleShow}
          >
            Close
          </Button>
          <a
            href={`https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${window.location.href}?reqId=${shopWithLazadaFeature?.id}&client_id=${lazadaAppKey}`}
          >
            <Button
              className={`schedule__feature__approve-btn btn-color-primary ${
                lazadaAppKey.length === 0 ? 'btn-secondary' : ''
              }`}
              style={{ borderRadius: '6px' }}
              disabled={lazadaAppKey.length === 0}
            >
              Ok
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function ButtonApprovedLazadaFeature({ shopWithLazadaFeature }) {
  const [show, setShow] = useState(false)
  const [lazadaAppKey, setLazadaAppKey] = useState('')

  const [listApp, setListApp] = useState([])

  useEffect(async () => {
    try {
      const res = await lazadaAppApi.getListApp()
      setListApp(res.msgResp)
    } catch (error) {
      toast.error(error.msgResp)
    }
  }, [])

  const codeAuth = useRef()

  const toggleShow = () => {
    setShow(show !== true)
  }
  return (
    <>
      <Button onClick={toggleShow} className='schedule__feature__approve-btn btn-color-primary'>
        Approve
      </Button>

      <ModalApprove
        shopWithLazadaFeature={shopWithLazadaFeature}
        show={show}
        toggleShow={toggleShow}
        codeAuth={codeAuth}
        setLazadaAppKey={setLazadaAppKey}
        lazadaAppKey={lazadaAppKey}
        listApp={listApp}
      />
    </>
  )
}

export default ButtonApprovedLazadaFeature
