/* eslint-disable jsx-a11y/control-has-associated-label */

import { LIST_TYPE_SUBSCRIPTION_PACKAGE, MONTHLY_VALUE } from '_utils/constants'
import React, { useContext, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { SubscriptionContext } from './index'

const tableHeaders = [
  'Price ($)',
  'Select Intervals (month)',
  'Discount Price ($)',
  'Public',
  'Action',
]

const FormSubscription = () => {
  const { register, handleSubmit, onSubmit, errors, fields, append, remove, control } =
    useContext(SubscriptionContext)

  const { selectedSubscription } = useSelector((state) => state.subscription)

  useEffect(() => {
    if (!selectedSubscription?.variations?.length) {
      append({ price: '', expireValue: '', available: false, isMonthly: true, inputValue: '' })
    }
  }, [selectedSubscription?.variations?.length, append])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className='form-subscription'>
      <Form.Group className='mb-3'>
        <Form.Label>Choose Subscription Type:</Form.Label>
        <Form.Select
          className='mb-3'
          style={{ fontSize: 16 }}
          disabled={selectedSubscription?.subscriptionPackageType}
          defaultValue={selectedSubscription?.subscriptionPackageType}
          {...register('subscriptionType')}
        >
          {LIST_TYPE_SUBSCRIPTION_PACKAGE?.map((item, index) => (
            <option value={item.type} key={index} name='subscriptionType'>
              {item.name}
            </option>
          ))}
        </Form.Select>
        <p className='error-text'>{errors.packageType?.message}</p>
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Description:</Form.Label>
        <Form.Control
          as='textarea'
          name='description'
          defaultValue={selectedSubscription?.description}
          rows={3}
          {...register('description')}
        />
        <p className='error-text'>{errors.description?.message}</p>
      </Form.Group>
      <Form.Group className='mb-3 form-variations'>
        <Form.Label>Variations:</Form.Label>
        <table className='table bg-light rounded' style={{ fontSize: 16 }}>
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  scope='col'
                  style={{
                    fontSize: '0.875em',
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fields.length ? (
              fields.map((item, index) => (
                <tr key={`${item.id}-${index}`}>
                  <td width={100}>
                    <Controller
                      name={`variations.${index}.price`}
                      control={control}
                      render={({ field }) => <Form.Control as='input' {...field} />}
                    />
                    {errors?.variations?.length && (
                      <p className='error-text'>{errors?.variations[index]?.price?.message}</p>
                    )}
                  </td>
                  <td>
                    <Controller
                      name={`variations.${index}.inputValue`}
                      control={control}
                      render={({ field }) => (
                        <div className='input-group'>
                          <Form.Control
                            as='input'
                            placeholder='1 = 1 month'
                            {...field}
                            className='form-control'
                          />
                          <Controller
                            name={`variations.${index}.isMonthly`}
                            control={control}
                            render={({ field: selectField }) => (
                              <Form.Control
                                as='select'
                                {...selectField}
                                className='form-select'
                                style={{ paddingRight: 10 }}
                                onChange={(e) => {
                                  const valueAsBoolean = e.target.value === 'true'
                                  selectField.onChange(valueAsBoolean)
                                }}
                              >
                                <option value='true'>Monthly</option>
                                <option value='false'>Annually</option>
                              </Form.Control>
                            )}
                          />
                        </div>
                      )}
                    />
                  </td>
                  <td width={100}>
                    <Controller
                      name={`variations.${index}.discountPrice`}
                      defaultValue={selectedSubscription[index]?.discountPrice || 0}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Form.Control
                            as='input'
                            defaultValue={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                          />
                        )
                      }}
                    />
                    {errors?.variations?.length && (
                      <p className='error-text'>
                        {errors?.variations[index]?.discountPrice?.message}
                      </p>
                    )}
                  </td>

                  <td>
                    <Controller
                      name={`variations.${index}.available`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Form.Check
                            type='switch'
                            defaultChecked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                            }}
                            className='ms-2'
                          />
                        )
                      }}
                    />
                  </td>
                  <td>
                    {(!selectedSubscription?.variations?.length ||
                      !selectedSubscription.variations[index]?.id) && (
                      <button
                        type='button'
                        className='btn btn-danger rounded-pill'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={`${tableHeaders.length}`} className='text-center'>
                  No Variant
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {errors?.variations && (
          <span className={'text-danger mb-2 d-block'}>{errors.variations.message}</span>
        )}
        <button
          type='button'
          className='btn btn-color-primary w-100 text-white rounded-pill'
          onClick={() => append({ price: '', expireValue: MONTHLY_VALUE, available: false })}
        >
          Add Variation
        </button>
      </Form.Group>

      <Form.Group className='mb-3'>
        <Form.Label>Limit (sign-up):</Form.Label>
        <Form.Control
          as='input'
          name='limit'
          placeholder='Enter limit'
          defaultValue={selectedSubscription?.limit}
          {...register('limit')}
        />
        <p className='error-text'>{errors.limit?.message}</p>
      </Form.Group>
      <Form.Group className='mb-3 d-flex align-items-center'>
        <Form.Label className='mb-0'>Public:</Form.Label>
        <Form.Check
          type='switch'
          id='active'
          className='ms-2'
          defaultChecked={selectedSubscription?.active}
          {...register('active')}
        />
      </Form.Group>
    </Form>
  )
}

export default FormSubscription
