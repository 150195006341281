import groupsApi from '_api/groupsApi'

const GET_ALL_GROUPS = 'GET_ALL_GROUPS'
const GET_LIST_GROUP_BY_DATE = 'GET_LIST_GROUP_BY_DATE'
const SET_IS_SYNC_DATA_GROUP = 'SET_IS_SYNC_DATA_GROUP'

const initialState = {
  listAllGroups: null,
  listGroupByDate: null,
  isSyncDataGroup: false
}

export const setIsSyncDataGroup = (bol)  => (dispatch) => {
  dispatch({
    type: SET_IS_SYNC_DATA_GROUP,
    payload:bol
  })
} 

export const getGroupsByDate = (start, end) => async (dispatch) => {
  let listGroupByDate = []
  try {
    const response = await groupsApi.getGroupsByDate(start, end)
    if (response?.msgResp) {
      listGroupByDate = response.msgResp
    }
    dispatch({
      type: GET_LIST_GROUP_BY_DATE,
      payload: listGroupByDate,
    })
  } catch (error) {}
}

export const getAllGroups = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_GROUPS,
    payload: data,
  })
}

const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS: {
      return {
        ...state,
        listAllGroups: [...action.payload],
      }
    }

    case GET_LIST_GROUP_BY_DATE: {
      return {
        ...state,
        listGroupByDate: [...action.payload],
      }
    }
    case SET_IS_SYNC_DATA_GROUP: {
      return {
        ...state,
        isSyncDataGroup: action.payload,
      }
    }

    default:
      return state
  }
}

export default groupsReducer
