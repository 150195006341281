/* eslint-disable consistent-return */
import { selectSubscription } from '_redux/reducers/subscription'
import { LIST_PACKAGE_TYPE, LIST_TYPE_SUBSCRIPTION_PACKAGE, TURN_ON } from '_utils/constants'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'

function SubscriptionItem({ info, index, toggleModal }) {
  const dispatch = useDispatch()

  const {
    active,
    createdDate,
    description,
    forProject,
    limit,
    subscriptionPackageType,
    packageType,
  } = info

  const onEdit = (item) => {
    dispatch(selectSubscription(item))
    toggleModal()
  }

  return (
    <tr>
      <th scope='row' className='table__index' style={{ width: '30px' }}>
        {index + 1}
      </th>

      <td className='table__avatar table__item'>
        <span>
          {
            LIST_TYPE_SUBSCRIPTION_PACKAGE.find((item) => item.type === subscriptionPackageType)
              ?.name
          }
        </span>
      </td>

      <td className='table__item'>
        <span>{moment.unix(createdDate).format('DD/MM/YYYY')}</span>
      </td>

      <td className='table__item' width={200}>
        <span>{description}</span>
      </td>

      <td className='table__item'>
        <span>{forProject}</span>
      </td>

      <td className='table__item'>
        <span>{active === TURN_ON ? 'on' : 'off'}</span>
      </td>

      <td className='table__item'>
        <span>{limit}</span>
      </td>

      <td className='table__item'>
        <span>{LIST_PACKAGE_TYPE.find((item) => item.type === packageType)?.name}</span>
      </td>

      <td>
        <span className='table__item-actions justify-content-start'>
          <button
            type='button'
            className='btn btn-success rounded-pill'
            onClick={() => onEdit(info)}
          >
            Edit
          </button>
        </span>
      </td>
    </tr>
  )
}

export default SubscriptionItem
