import { clearSelectSubscription } from '_redux/reducers/subscription'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormSubscription from './FormSubscription'
import { SubscriptionContext } from './index'

const ModalSubscription = () => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('Add new subscription')

  const {
    isOpenModal,
    toggleModal,
    handleSubmit,
    onSubmit,
    onUpdate,
    append: appendVariations,
    remove: removeVariation,
    reset,
    isSubmitting,
  } = useContext(SubscriptionContext)

  const { selectedSubscription } = useSelector((state) => state.subscription)

  useEffect(() => {
    if (!isEmpty(selectedSubscription)) {
      setTitle('Edit subscription')
      selectedSubscription?.variations?.forEach((variant) => {
        appendVariations({
          ...variant,
          discountPrice: variant.discountPrice || 0,
        })
      })
    }

    return () => {
      dispatch(clearSelectSubscription())
      setTitle('Add new subscription')
      removeVariation()
      reset()
    }
  }, [])

  const onSubmitClick = (data) => {
    if (isEmpty(selectedSubscription)) {
      onSubmit(data)
    } else {
      onUpdate(data, selectedSubscription?.id)
    }
  }

  return (
    <>
      <Modal
        show={isOpenModal}
        onHide={toggleModal}
        backdrop='static'
        className='modal-add'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormSubscription />
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary rounded-pill' onClick={toggleModal}>
            Cancel
          </button>
          <button
            type={'button'}
            disabled={isSubmitting}
            className={'btn btn-color-primary text-white rounded-pill'}
            onClick={handleSubmit((data) => onSubmitClick(data))}
          >
            {isSubmitting
              ? !isEmpty(selectedSubscription)
                ? 'Updating...'
                : 'Saving...'
              : !isEmpty(selectedSubscription)
                ? 'Update'
                : 'Save'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalSubscription
