import axiosClient from './axiosClient'

const packagesApi = {
  updatePackage: (data) => {
    const url = `update-package?id=${data.id}`
    return axiosClient.patch(url, data)
  },
  getListPackageByType: (packageType) => {
    const url = '/get-list-package-by-type'
    return axiosClient.get(url, { params: { packageType } })
  },
  createSubscriptionPackage: (data) => {
    const url = '/create-subscription-package'
    return axiosClient.post(url, data)
  },
  updateSubscriptionPackage: (data) => {
    const url = `/update-subscription-package?id=${data.id}`
    return axiosClient.patch(url, data)
  },
  createLazadaPackage: (data) => {
    const url = '/create-lazada-package'
    return axiosClient.post(url, data)
  },
  updateLazadaPackage: (data) => {
    const url = `/update-lazada-package?id=${data.id}`
    return axiosClient.patch(url, data)
  },
  getListLazadaPaymentRequest: () => {
    const url = '/list-shop-lazada-feature'
    return axiosClient.get(url)
  },
}

export default packagesApi
