import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { LinkContainer } from 'react-router-bootstrap'
import { useIndexedDB } from 'react-indexed-db'
import { useSelector } from 'react-redux'

import noAvatar from '_images/noAvatar.jpg'

function UserItem({ user, index }) {
  const { listGroupByDate } = useSelector((state) => state.groups)
  const { getAll } = useIndexedDB('groups')

  const { id, name, email, photoUrl, phone, createdAt } = user

  const [groupOfMember, setGroupOfMember] = useState()

  useEffect(() => {
    getAll().then((dataGroups) => {
      if (dataGroups?.length > 0) {
        const getGroupByUid = dataGroups?.filter((groups) => {
          return groups.members?.some((member) => member.uid === id)
        })
        setGroupOfMember(getGroupByUid)
      }
    })
  }, [])

  useEffect(() => {
    if (listGroupByDate) {
      if (!groupOfMember) {
        const getGroupByUid = listGroupByDate?.filter((groups) => {
          return groups.members.some((member) => member.uid === id)
        })
        setGroupOfMember(getGroupByUid)
      }
    }
  }, [listGroupByDate])

  return (
    <>
      <LinkContainer to={`/users/detail-user?uid=${id}`} style={{ cursor: 'pointer' }}>
        <tr key={index}>
          <th scope='row' className='user__list-number-index'>
            {index + 1}
          </th>
          <td className='user__list-name-avatar'>
            <img src={photoUrl || noAvatar} alt='' />
            {name}
          </td>
          <td className='user__list-email'>{email}</td>
          <td className='user__list-phone-number'>{phone}</td>
          <td className='user__list-created-date'>{moment.unix(createdAt).format('DD-MM-YYYY')}</td>
          <td className='user__list-groups'>
            <span className='user__list-groupsp-name'>
              {groupOfMember?.map((group, i) => (
                <span key={i}>{group.name}, </span>
              ))}
            </span>
          </td>
        </tr>
      </LinkContainer>
    </>
  )
}

export default UserItem
