// import packagesApi from '_api/packages'

const GET_LIST_PACKAGE = 'GET_LIST_PACKAGE'
const CREATE_PACKAGE = 'CREATE_PACKAGE'
const SELECT_PACKAGE = 'SELECT_PACKAGE'
const CLEAR_PACKAGE = 'CLEAR_PACKAGE'

const initialState = {
  listPackage: [],
  selectPackage: {},
}

export const clearListPackage = () => (dispatch) => {
  dispatch({
    type: CLEAR_PACKAGE,
    payload: {
      listPackage: [],
    },
  })
}

export const selectPackage = (data) => (dispatch) => {
  dispatch({
    type: SELECT_PACKAGE,
    payload: {
      selectedPackage: data,
    },
  })
}

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PACKAGE:
    case CREATE_PACKAGE:
    case CLEAR_PACKAGE:
    case GET_LIST_PACKAGE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default packageReducer
