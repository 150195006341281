import { clearSelectLazada } from '_redux/reducers/lazada'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LazadaContext } from './index'

const ModalLazada = () => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const { selectedLazada } = useSelector((state) => state.lazada)
  const { errors, register, isOpenModal, onSubmit, toggleModal, onUpdate, handleSubmit, reset } =
    useContext(LazadaContext)

  useEffect(() => {
    if (!isEmpty(selectedLazada)) {
      setTitle('Update Lazada Package')
    } else {
      setTitle('Add New Lazada Package')
    }

    return () => {
      dispatch(clearSelectLazada())
      setTitle('Add New Lazada Package')
      reset()
    }
  }, [JSON.stringify(selectedLazada)])

  return (
    <Modal show={isOpenModal} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='create-app-lazada' onSubmit={onSubmit}>
          <div className='form-group mb-3'>
            <label htmlFor='appName' className='mb-2'>
              Name:
            </label>
            <input
              type='text'
              defaultValue={selectedLazada?.name}
              className='form-control'
              id='name'
              {...register('name')}
            />
            {errors.name && <span className='text-danger'>{errors.name.message}</span>}
          </div>
          <div className='form-group mb-3'>
            <label htmlFor='description' className='mb-2'>
              Description:
            </label>
            <textarea
              rows={3}
              className='form-control'
              id='description'
              defaultValue={selectedLazada?.description}
              {...register('description')}
            />
            {errors.description && (
              <span className='text-danger'>{errors.description.message}</span>
            )}
          </div>
          <div className='form-group mb-3'>
            <label htmlFor='appSecret' className='mb-2'>
              Price ($):
            </label>
            <input
              type='text'
              className='form-control'
              id='price'
              defaultValue={selectedLazada?.price}
              {...register('price')}
            />
            {errors.price && <span className='text-danger'>{errors.price.message}</span>}
          </div>
          <div className='form-group mb-3'>
            <label htmlFor='appSecret' className='mb-2'>
              Discount Price ($):
            </label>
            <input
              type='text'
              className='form-control'
              id='discountPrice'
              defaultValue={selectedLazada?.discountPrice}
              {...register('discountPrice')}
            />
            {errors.discountPrice && (
              <span className='text-danger'>{errors.discountPrice.message}</span>
            )}
          </div>
          <div className='form-group mb-3'>
            <label htmlFor='expireValue' className='mb-2'>
              Expire Value (month):
            </label>
            <input
              type='text'
              className='form-control'
              id='expireValue'
              defaultValue={selectedLazada?.expireValue}
              placeholder={'1 = 1 month'}
              {...register('expireValue')}
            />
            {errors.expireValue && (
              <span className='text-danger'>{errors.expireValue.message}</span>
            )}
          </div>
          <div className='form-group mb-3'>
            <Form.Label className='mb-0'>Active:</Form.Label>
            <Form.Check
              type='switch'
              id='active'
              // className='ms-2'
              defaultChecked={selectedLazada?.active}
              {...register('active')}
            />
          </div>
          <div className='d-block text-end'>
            <button
              type={'button'}
              onClick={toggleModal}
              className='me-2 btn btn-secondary rounded-pill'
            >
              Cancel
            </button>
            <button
              type={'button'}
              className={'btn btn-color-primary text-white rounded-pill'}
              onClick={handleSubmit((data) =>
                isEmpty(selectedLazada) ? onSubmit(data) : onUpdate(data, selectedLazada?.id),
              )}
            >
              {!isEmpty(selectedLazada) ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ModalLazada
