import {
  SUBSCRIPTION_STATUS_APPROVED,
  SUBSCRIPTION_STATUS_CANCEL,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_REFUNDED,
} from '_utils/constants'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import '../../Package/style.css'
import RequestItem from './RequestItem'

const tableHeaders = [
  '#', 
  'Shop Name',
  'Phone',
  'Email',
  'Package',
  'Price',
  'Expire Date',
  'Payment Method',
  'Status',
  'Payment Status',
  'App',
  'Actions',
]

const ListLazadaPaymentRequest = ({ loading, listLazadaPaymentRequest }) => {
  const [listPaymentReq, setListPaymentReq] = useState([])

  useEffect(() => {
    if (listLazadaPaymentRequest.length) {
      setListPaymentReq(listLazadaPaymentRequest)
    }
  }, [listLazadaPaymentRequest.length])

  const onFilterListReq = (status) => {
    if (status === 'all') {
      setListPaymentReq(listLazadaPaymentRequest)
    } else {
      setListPaymentReq(listLazadaPaymentRequest.filter((item) => item.status === Number(status)))
    }
  }

  return (
    <div className='list-lazada-payment-request'>
      <ToastContainer limit={1} />
      <div className='select'>
        <label className='table-choose-status'>Choose shop lazada status:</label>
        <select className='table-select-status' onChange={(e) => onFilterListReq(e.target.value)}>
          <option value='all'>All</option>
          <option value={SUBSCRIPTION_STATUS_APPROVED}>Approved</option>
          <option value={SUBSCRIPTION_STATUS_PENDING}>Pending</option>
          <option value={SUBSCRIPTION_STATUS_CANCEL}>Cancel</option>
          <option value={SUBSCRIPTION_STATUS_REFUNDED}>Refunded</option>
        </select>
      </div>
      <div className='scroll__mobile'>
        <div className='table-responsive'>
          <table className='table lazada'>
            <thead>
              <tr className='table-list-title'>
                {tableHeaders.map((headerText, index) => (
                  <th scope='col' key={index} className='f-14'>
                    <span>{headerText}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='f-14'>
              {loading && (
                <tr>
                  <td colSpan={tableHeaders.length} className='text-center'>
                    Loading...
                  </td>
                </tr>
              )}

              {!loading && listPaymentReq.length
                ? listPaymentReq.map((info, i) => (
                    <RequestItem item={info} key={info.id} index={i} />
                  ))
                : null}

              {!loading && !listPaymentReq.length && (
                <tr>
                  <td colSpan={tableHeaders.length} className='text-center'>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ListLazadaPaymentRequest
