import shopApi from '_api/shopApi'

const GET_LIST_SHOP_WITH_WIX_FEATURE = 'GET_LIST_SHOP_WITH_WIX_FEATURE'
const GET_LIST_SHOP_WITH_LAZADA_FEATURE = 'GET_LIST_SHOP_WITH_LAZADA_FEATURE'
const FETCH_LIST_SHOP = 'FETCH_LIST_SHOP'
const UPDATE_SHOP = 'UPDATE_SHOP'

const initialState = {
  listShopWithWixFeature: null,
  listShopWithLazadaFeature: null,
  listShop: [],
}

export const updateShop = (shopId, status) => async (dispatch, getState) => {
  const { shops } = getState()
  const { listShop } = shops
  try {
    await shopApi.updateShop(shopId, status)
    dispatch({
      type: UPDATE_SHOP,
      payload: {
        listShop: listShop.map((shop) => {
          if (shop.id === shopId) {
            return {
              ...shop,
              active: status,
            }
          }

          return shop
        }),
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const fetchListShop = (startDate, endDate) => async (dispatch) => {
  try {
    const { msgResp } = await shopApi.getListShop(startDate, endDate)
    dispatch({
      type: FETCH_LIST_SHOP,
      payload: {
        listShop: msgResp,
      },
    })
  } catch (error) {
    throw new Error(error.msgResp || error.message)
  }
}

export const getListShopWithWixFeature = () => async (dispatch) => {
  let list = []
  try {
    const response = await shopApi.getListShopWithWixFeatureStatus()
    if (response?.msgResp) {
      list = response?.msgResp
    }
    dispatch({
      type: GET_LIST_SHOP_WITH_WIX_FEATURE,
      payload: list,
    })
  } catch (error) {}
}

export const getListShopWithLazadaFeature = () => async (dispatch) => {
  let list = []
  try {
    const response = await shopApi.getListShopWithLazadaFeatureStatus()
    if (response?.msgResp) {
      list = response?.msgResp
    }
    dispatch({
      type: GET_LIST_SHOP_WITH_LAZADA_FEATURE,
      payload: list,
    })
  } catch (error) {}
}

const shopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_SHOP_WITH_WIX_FEATURE: {
      return {
        ...state,
        listShopWithWixFeature: action.payload,
      }
    }

    case GET_LIST_SHOP_WITH_LAZADA_FEATURE: {
      return {
        ...state,
        listShopWithLazadaFeature: action.payload,
      }
    }

    case UPDATE_SHOP:
    case FETCH_LIST_SHOP:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default shopsReducer
