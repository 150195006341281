import axiosClient from './axiosClient'

const eventsApi = {
  getListEventByGid: (gid) => {
    const url = `/list-events?groupId=${gid}`
    return axiosClient.get(url)
  },
  getEventsByDate: (start,end) => {
    const url = `/list-events?start=${start}&end=${end}`
    return axiosClient.get(url)
  }
}

export default eventsApi
