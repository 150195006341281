import axiosClient from './axiosClient'

const groupsApi = {
  getAllGroups: () => {
    const url = '/list-groups'
    return axiosClient.get(url)
  },

  activeGroup:(id,isActive) => {
    const url = `/update-group-status?id=${id}`
    return axiosClient.post(url,isActive)
  },
    
  getGroupsByDate: (start,end) => {
    const url = `/list-groups?start=${start}&end=${end}`
    return axiosClient.get(url)
  }
}

export default groupsApi
