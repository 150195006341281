import axiosClient from './axiosClient'

const dashboardApi = {
  getDashboard: () => {
    const url = '/reports'
    return axiosClient.get(url)
  },
  getDataLiveReport: () => {
    const url = '/live-reports'
    return axiosClient.get(url)
  },
  getDataLiveReportByDate: (start, end) => {
    const url = `/live-reports?start=${start}&end=${end}`
    return axiosClient.get(url)
  },
  getListPackage: () => {
    const url = '/list-package'
    return axiosClient.get(url)
  },
  createPackage: (data) => {
    const url = '/create-package'
    return axiosClient.post(url, data)
  }
}

export default dashboardApi
