import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from '../../components/LoadingOverlay'
import { getListContact } from '../../redux/reducers/contact'
import ContactList from './ContactList'

function ContactRequests() {
  const dispatch = useDispatch()
  const { listContactRequest } = useSelector((state) => state.contact)

  const [paginationPage, setPaginationPage] = useState()
  const [paginationListContact, setPaginationListContact] = useState({
    listContact: listContactRequest.slice(0, 10),
    index: 0,
    pageActive: 1,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await dispatch(getListContact())
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    setPaginationListContact({
      listContact: listContactRequest.slice(0, 10),
      index: 0,
      pageActive: 1,
    })
  }, [listContactRequest.length])

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListContact({
        listContact: listContactRequest?.slice(0, 10),
        index: 0,
        pageActive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(listContactRequest?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListContact({
        listContact: listContactRequest?.slice(currentPage, nextPage),
        index: currentPage,
        pageActive: page,
      })
    }
  }

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(listContactRequest?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListContact?.pageActive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    if (listContactRequest?.length >= 10) {
      const component = (
        <Pagination
          style={{
            columnGap: '10px',
            marginTop: '20px',
          }}
        >
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListContact?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListContact?.pageAtive + 1)} />
          <Pagination.Last
            onClick={() => handlePagination(Math.ceil(listContactRequest?.length / 10))}
          />
        </Pagination>
      )

      setPaginationPage(component)
    }
  }, [paginationListContact])

  if (loading) {
    return <LoadingOverlay />
  }

  if (!loading && !listContactRequest.length) {
    return (
      <div className='fw-bold text-center' style={{ color: '#808080' }}>
        No contact requests
      </div>
    )
  }

  return (
    <div>
      <ContactList
        paginationPage={paginationPage}
        listContact={paginationListContact.listContact}
      />
    </div>
  )
}

export default ContactRequests
