/* eslint-disable no-restricted-globals */
import { handleRefundPaymentReq } from '_redux/reducers/lazada'
import { SUBSCRIPTION_STATUS_REFUNDED, optionToast } from '_utils/constants'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

function ModalRefund({
  isOpenModalRefund,
  setIsOpenModalRefund,
  paymentRequestRefund,
  setPaymentRequestRefund,
}) {
  const dispatch = useDispatch()
  const { selectedLazada } = useSelector((state) => state.lazada)

  const [refundAmount, setRefundAmount] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  if (!selectedLazada) return null

  const onSubmit = async (e) => {
    e.preventDefault()

    if (isNaN(refundAmount)) {
      alert('Refund amount must be a number')
    } else if (refundAmount <= 0) {
      alert('Refund amount must be greater than 0')
    } else {
      try {
        setIsSubmitting(true)
        const postData = {
          refundAmount: Number(refundAmount),
        }

        const params = {
          id: paymentRequestRefund.id,
          status: SUBSCRIPTION_STATUS_REFUNDED,
        }

        // await lazadaApi.updateStatusPaymentRequest(postData, params)
        await dispatch(handleRefundPaymentReq(postData, params, paymentRequestRefund))

        toast.success('Refund successfully', optionToast)
      } catch (error) {
        toast.error(error.message || error.msgResp, optionToast)
      } finally {
        setIsSubmitting(false)
        setIsOpenModalRefund(false)
        setPaymentRequestRefund(null)
      }
    }
  }

  return (
    <Modal show={isOpenModalRefund} onHide={() => setIsOpenModalRefund(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Refund - {selectedLazada.shopName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'text-center'}>
        <form onSubmit={onSubmit}>
          <div className='form-group text-start'>
            <label htmlFor='refund-amount' className='mb-1'>
              Refund amount ($):
            </label>
            <div className='d-flex align-items-center gap-2'>
              <input
                type='text'
                id='refund-amount'
                placeholder='Enter refund amount'
                className='form-control'
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
              />
              <button
                type='submit'
                className='btn btn-color-primary text-white'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Processing...' : 'Refund'}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='rounded-pill'
          onClick={() => setIsOpenModalRefund(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalRefund
