/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDashBroad, getDataLiveReport } from '_redux/reducers/dashboard'
import { Button } from 'react-bootstrap'
import { getDataReport } from '_utils/localData'
import { ToastContainer, toast } from 'react-toastify'
import TableDataItem from '../components/TableDataItem'

import 'react-toastify/dist/ReactToastify.css'
import './style.css'

function TablesData() {
  const dispatch = useDispatch()

  const { listDashBroad } = useSelector((state) => state.dashBroad)

  const [dataDashBroad, setDataDashBroad] = useState()

  useEffect(() => {
    const dataReport = getDataReport()
    if (dataReport) {
      setDataDashBroad(dataReport)
    } else {
      dispatch(getDashBroad)
      setDataDashBroad(listDashBroad)
    }
  }, [])

  useEffect(() => {
    if (listDashBroad) {
      setDataDashBroad(listDashBroad)
    }
  }, [listDashBroad])

  const handleRefreshData = () => {
    dispatch(getDashBroad)
    toast.success('Update successful')
  }

  return (
    <>
      <ToastContainer />
      <div className='container-fluid'>
        <div className='container'>
          <Button className='refresh__data' onClick={handleRefreshData} variant='primary'>
            Update Report
          </Button>
          <div className='row'>
            <TableDataItem data={dataDashBroad} />
          </div>
        </div>
      </div>
    </>
  )
}

export default TablesData
