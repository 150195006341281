import {
  changeLazAppForPaymentReq,
  changeStatusPaymentRequest,
  fetchListPackageLazada,
  getListAppLazada,
  getListLazadaPaymentRequest,
  handleMapLazAppAndPaymentReq,
  setDefaultLazAppForFirstPaymentReq,
} from '_redux/reducers/lazada'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import {
  LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
  LAZADA_FEATURE_PAYMENT_STATUS_CANCEL,
  LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_CANCEL_BY_ADMIN,
  SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
  SUBSCRIPTION_STATUS_APPROVED,
  optionToast,
} from '_utils/constants'

import { isEmpty } from 'lodash'

import ModalPaymentInfo from '../Subscription/ModalPaymentInfo'
import ListLazadaPaymentRequest from './ListPaymentRequest'
import ModalRefund from './ModalRefund'

export const LazadaPaymentRequestContext = createContext()

const Lazada = () => {
  const dispatch = useDispatch()

  const queryParams = new URLSearchParams(window.location.search)
  const codeAuthUrlParam = queryParams.get('code')
  const selectedLazApp = localStorage.getItem('selectedLazApp')
    ? JSON.parse(localStorage.getItem('selectedLazApp'))
    : null

  const [loading, setLoading] = useState(false)
  const [isOpenModalPaymentInfo, setIsOpenModalPaymentInfo] = useState(false)
  const [isOpenModalRefund, setIsOpenModalRefund] = useState(false)
  const [paymentRequestRefund, setPaymentRequestRefund] = useState({})

  const { listLazadaPaymentRequest, listAppLazada, listPaymentReqWithApp } = useSelector(
    (state) => state.lazada,
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await Promise.all([
        dispatch(getListLazadaPaymentRequest()),
        dispatch(fetchListPackageLazada()),
        dispatch(getListAppLazada()),
      ])
      setLoading(false)
    }

    fetchData().then(() => {})
  }, [])

  useEffect(() => {
    if (listAppLazada.length > 0 && listLazadaPaymentRequest.length > 0) {
      dispatch(handleMapLazAppAndPaymentReq(listLazadaPaymentRequest, listAppLazada))

      if (!selectedLazApp) {
        const appHasStillSlot = listAppLazada.find((app) => app.isFullSlot === false)
        dispatch(setDefaultLazAppForFirstPaymentReq(appHasStillSlot))
        localStorage.setItem('selectedLazApp', JSON.stringify(appHasStillSlot))
      } else {
        dispatch(setDefaultLazAppForFirstPaymentReq(selectedLazApp))
      }
    }
  }, [listAppLazada.length, listLazadaPaymentRequest.length, JSON.stringify(selectedLazApp)])

  useEffect(() => {
    // listLazadaPaymentRequest was sorted by createdDateTimestamp
    // So we only need to check the first element
    if (codeAuthUrlParam && listPaymentReqWithApp.length > 0 && !isEmpty(selectedLazApp)) {
      const fetchData = async () => {
        try {
          const postData = {
            lazadaAppKey: selectedLazApp?.lazadaAppKey,
            lazadaAppName: selectedLazApp?.lazadaAppName,
            codeAuth: codeAuthUrlParam,
          }

          const postStatus = {
            paymentStatus: SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
            status: SUBSCRIPTION_STATUS_APPROVED,
          }

          const params = {
            id: listPaymentReqWithApp[0].id,
            status: SUBSCRIPTION_STATUS_APPROVED,
          }

          await dispatch(
            changeStatusPaymentRequest(postData, params, postStatus, listPaymentReqWithApp[0]),
          )
          toast.success('Update Status Success', optionToast)
        } catch (error) {
          toast.error(error.message, optionToast)
        } finally {
          localStorage.removeItem('selectedLazApp')
          const url = new URL(window.location.href)
          url.search = ''
          window.history.pushState({}, '', url.toString())
        }
      }

      fetchData()
    }
  }, [codeAuthUrlParam, listPaymentReqWithApp.length, JSON.stringify(selectedLazApp)])

  const toggleModal = () => setIsOpenModalPaymentInfo(!isOpenModalPaymentInfo)
  const toggleModalRefund = () => setIsOpenModalRefund(!isOpenModalRefund)

  const onChangeLazAppForPaymentReq = async (e, paymentReqId) => {
    const { value: app } = e.target
    const parsedApp = JSON.parse(app)

    try {
      dispatch(changeLazAppForPaymentReq(parsedApp, paymentReqId))
      localStorage.setItem('selectedLazApp', app)
    } catch (error) {
      toast.error(error.message, optionToast)
    }
  }

  const onChangeStatus = async (e, lazadaRequestItem) => {
    const { value: status } = e.target
    const numberStatus = Number(status)
    const { createdUserId } = lazadaRequestItem
    const userAlreadyRegisterApp = listAppLazada.some((app) => {
      const { shopSeller } = app
      return shopSeller.some((seller) => seller.ownerId === createdUserId)
    })

    if (userAlreadyRegisterApp && numberStatus !== LAZADA_FEATURE_PAYMENT_STATUS_CANCEL) {
      toast.error('User already register app', optionToast)
      return
    }

    if (lazadaRequestItem.isFullSlot === true) {
      toast.error(
        `Lazada ${lazadaRequestItem.lazadaAppInfo?.lazadaAppName} is full slot`,
        optionToast,
      )
      return
    }

    const confirmMessage = 'Are you sure you want to change the status of this Lazada?'

    if (!confirmMessage || window.confirm(confirmMessage)) {
      if (numberStatus === LAZADA_FEATURE_PAYMENT_STATUS_APPROVED) {
        const selectedAppKey = lazadaRequestItem.lazadaAppInfo?.lazadaAppKey

        const authUrl = `https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=https://admin-co-bee-stg.firebaseapp.com/lazada-feature/list-request/true&redirect_uri=&client_id=${selectedAppKey}`
        window.open(authUrl, '_blank')
        localStorage.setItem('codeAuthTimestamp', Date.now())
        localStorage.removeItem('codeAuth')
      } 
      // else {
      //   // handle cancel
      //   const postData = {
      //     lazadaAppKey: lazadaRequestItem.lazadaAppInfo?.lazadaAppKey,
      //     lazadaAppName: lazadaRequestItem.lazadaAppInfo?.lazadaAppName,
      //     codeAuth: codeAuthUrlParam,
      //   }

      //   const postStatus = {
      //     paymentStatus: LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_CANCEL_BY_ADMIN,
      //     status: LAZADA_FEATURE_PAYMENT_STATUS_CANCEL,
      //   }

      //   const params = {
      //     id: lazadaRequestItem.id,
      //     status: LAZADA_FEATURE_PAYMENT_STATUS_CANCEL,
      //   }

      //   try {
      //     await dispatch(
      //       changeStatusPaymentRequest(postData, params, postStatus, lazadaRequestItem),
      //     )
      //     toast.success('Update Status Success', optionToast)
      //   } catch (error) {
      //     toast.error(error.message, optionToast)
      //   }
      // }
    }
  }

  const onViewPaymentInfo = () => {
    toggleModal()
  }

  return (
    <div className='container-fluid'>
      <ToastContainer autoClose={2000} limit={1} />
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h3 className='table-heading'>List Lazada Payment Request</h3>
      </div>

      <LazadaPaymentRequestContext.Provider
        value={{
          toggleModal,
          toggleModalRefund,
          onChangeLazAppForPaymentReq,
          onChangeStatus,
          onViewPaymentInfo,
        }}
      >
        <ListLazadaPaymentRequest
          loading={loading}
          listLazadaPaymentRequest={listPaymentReqWithApp}
        />
      </LazadaPaymentRequestContext.Provider>

      {isOpenModalPaymentInfo && (
        <ModalPaymentInfo
          isOpenModal={isOpenModalPaymentInfo}
          setIsOpenModal={setIsOpenModalPaymentInfo}
        />
      )}

      {isOpenModalRefund && (
        <ModalRefund
          isOpenModalRefund={isOpenModalRefund}
          setIsOpenModalRefund={setIsOpenModalRefund}
          paymentRequestRefund={paymentRequestRefund}
          setPaymentRequestRefund={setPaymentRequestRefund}
        />
      )}
    </div>
  )
}

export default Lazada
