import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'

import noProduct from '_images/noProduct.png'

function ProductItem({ info, index }) {
  const { limitPerOrder, maxQuantity, description, name, createdAt, price, orderCount, photoUrls } =
    info
  return (
    <>
      <tr>
        <td className='product__number-index'>{index + 1}</td>

        <td className='product__name'>
          <img src={photoUrls[0] || noProduct} className='product__photo' alt='' />
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='button-tooltip-2'>{name}</Tooltip>}
          >
            <span>{name}</span>
          </OverlayTrigger>
        </td>

        <td className='product__price'>
          <span>${price}</span>
        </td>

        <td className='product__date_Created'>{moment.unix(createdAt).format('DD/MM/YYYY')}</td>

        <td className='product__description'>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='button-tooltip-2'>{description || 'No Description'}</Tooltip>}
          >
            <span>{description || 'No Description'}</span>
          </OverlayTrigger>
        </td>

        <td>
          <span className='btn btn-outline-warning'>{maxQuantity}</span>
        </td>

        <td>
          <span className='btn btn-outline-warning'>{limitPerOrder}</span>
        </td>

        <td>
          <span className='btn btn-outline-warning'>{orderCount}</span>
        </td>
      </tr>
    </>
  )
}

export default ProductItem
