import React, { useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import moment from 'moment'

import groupIcon from '_images/group-icon.png'

function GroupItem(props) {
  const { uid } = props

  const { getAll } = useIndexedDB('groups')

  const [groupByUid, setGroupByUid] = useState()

  useEffect(() => {
    getAll().then((dataGroups) => {
      const getGroupByUid = dataGroups?.filter((groups) => {
        return groups.members.some((member) => member.uid === uid)
      })
      setGroupByUid(getGroupByUid)
    })
  }, [])

  return (
    <>
      {groupByUid?.length > 0 ? (
        <table className='table '>
          <thead>
            <tr className='user__detail-group-list-title'>
              <th style={{ width: '30px' }}>#</th>
              <th>Group Name</th>
              <th>Description</th>
              <th>Date</th>
              <th>Group detail</th>
            </tr>
          </thead>
          <tbody>
            {groupByUid?.map((group, i) => (
              <tr key={i}>
                <td className='user__detail-group-list-mumber-index'>{i + 1}</td>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip id='button-tooltip-2'>{group.name}</Tooltip>}
                >
                  <td className='user__detail-group-list-avatar-name'>
                    <img src={group.photoUrl || groupIcon} alt='' />
                    <span>{group.name}</span>
                  </td>
                </OverlayTrigger>

                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip id='button-tooltip-2'>{group.description}</Tooltip>}
                >
                  <td className='user__detail-group-list-description'>
                    <span>{group.description}</span>
                  </td>
                </OverlayTrigger>
                <td className='user__detail-group-list-created-date'>
                  {moment.unix(group.createdAt).format('DD/MM/YYYY')}
                </td>
                <td>
                  <LinkContainer to={`/groups/detail-groups?gid=${group.id}`}>
                    <Button className='user__detail-group-list-to-watch-btn btn-color-primary'>
                      Detail
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className='text-secondary'>
          <b>This user has not joined any groups yet !</b>
        </p>
      )}
    </>
  )
}

export default GroupItem
