/* eslint-disable no-unused-vars */
import { TOKEN_EXPIRED, UNAUTHORIZED } from '_utils/constants'
import { clearLocalStorage, getUserToken } from '_utils/localData'
/* eslint-disable consistent-return */
import axios from 'axios'
import queryString from 'query-string'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(async (config) => {
  const token = getUserToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response?.data?.msgCode === TOKEN_EXPIRED || response?.data?.msgResp === UNAUTHORIZED) {
      clearLocalStorage()
      window.location.href = '/sign-in'
      return
    }
    return response.data
  },
  (error) => {
    if (error?.response?.data?.msgResp === UNAUTHORIZED) {
      clearLocalStorage()
      window.location.href = '/sign-in'
      return
    }
    return Promise.reject(error.response.data)
  },
)

export default axiosClient
