import axiosClient from './axiosClient'

const authApi = {
  login1st: (data) => {
    const url = '/login'
    return axiosClient.post(url, data)
  },

  login2nd: (data) => {
    const url = '/login-2nd-auth'
    return axiosClient.post(url, data)
  },
}
export default authApi
