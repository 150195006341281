import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Container, Button, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { getDataLiveReport } from '_redux/reducers/dashboard'

import dashboardApi from '_api/dashboardApi'

import GroupItem from './components/GroupItem'
import UserCard from './components/UserCard'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import './style.css'

const dateCurrent = moment().format('DD-MM-YYYY')
const lastWeek = moment().subtract(7, 'd').format('DD-MM-YYYY')

function LiveReport() {
  const dispatch = useDispatch()

  const { dataLiveReport } = useSelector((state) => state.dashBroad)

  const [startDay, setStartDay] = useState(lastWeek)
  const [endDay, setEndDay] = useState(dateCurrent)
  const [liveReport, setLiveReport] = useState()

  const [checkInDate, setCheckInDate] = useState(moment().subtract(7, 'd')._d)
  const [checkOutDate, setCheckOutDate] = useState(moment()._d)

  useEffect(() => {
    dispatch(getDataLiveReport)
  }, [])

  useEffect(() => {
    setLiveReport(dataLiveReport)
  }, [dataLiveReport])

  const handleCheckInDate = (date) => {
    setCheckInDate(date)
    setCheckOutDate(null)
  }

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date)
  }

  const handleSyncLiveReport = async () => {
    try {
      const from = moment(checkInDate).unix()
      const to = moment(checkOutDate).unix()

      if (from && to) {
        setStartDay(moment.unix(from).format('DD-MM-YYYY'))
        setEndDay(moment.unix(to).format('DD-MM-YYYY'))

        const response = await dashboardApi.getDataLiveReportByDate(from, to)
        const { totalBuy, totalGroups, totalPaidValue, totalUsers, totalValue } = response.msgResp
        const sumDataLiveReport = totalBuy + totalGroups + totalPaidValue + totalUsers + totalValue

        if (sumDataLiveReport > 0) {
          setLiveReport(response.msgResp)
          toast.success('Updated data 👌')
        } else {
          toast.info('No data this period 😅')
        }
      } else {
        toast.error('Please pick date')
      }
    } catch (error) {}
  }

  return (
    <Container style={{ marginTop: '32px' }}>
      <ToastContainer autoClose={2000} />
      <div className='liveReport'>
        <div className='liveReport__datePicker'>
          <div className='liveReport__datePicker-checkInDate'>
            <div className='liveReport__datePicker-checkInDate-title'>Start Date</div>
            <DatePicker
              selected={checkInDate}
              onChange={handleCheckInDate}
              placeholderText={startDay}
              dateFormat='dd/MM/yyyy'
              className='liveReport__datePicker-checkInDate-input'
            />
          </div>
          <div className='liveReport__datePicker-checkOutDate'>
            <div className='liveReport__datePicker-checkOutDate-title'>End Date</div>
            <DatePicker
              selected={checkOutDate}
              minDate={checkInDate}
              onChange={handleCheckOutDate}
              placeholderText={endDay}
              className='liveReport__datePicker-checkOutDate-input'
              dateFormat='dd/MM/yyyy'
            />
          </div>
          <Button
            className='liveReport__datePicker-btn-sync btn-color-primary'
            onClick={handleSyncLiveReport}
          >
            Sync
          </Button>
        </div>

        <div className='liveReport__datePicker-time-default'>
          <div className='liveReport__datePicker-time-from'>
            <p>
              <label>From</label>: {startDay}
            </p>
          </div>
          <div className='liveReport__datePicker-time-to'>
            <p>
              <label>To</label>: {endDay}
            </p>
          </div>
        </div>
      </div>

      <div className='liveReport__chart'>
        <Bar
          data={{
            labels: ['Total User', 'Total Groups', 'Total Values', 'Total Paid Value', 'Total Buy'],
            datasets: [
              {
                label: 'Live Report ',
                backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'],
                data: [
                  liveReport?.totalUsers,
                  liveReport?.totalGroups,
                  liveReport?.totalValue,
                  liveReport?.totalPaidValue,
                  liveReport?.totalBuy,
                ],
              },
            ],
          }}
        />
      </div>

      <div className='liveReport__newGroups'>
        <h3 className='liveReport__newGroups-heading'>New Groups</h3>
        {liveReport?.groups?.length > 0 ? (
          <GroupItem groups={liveReport?.groups} />
        ) : (
          <p className='text-secondary'>
            <b>No new groups yet ! </b>
          </p>
        )}
      </div>

      <div className='liveReport__newUsers'>
        <h3 className='liveReport__newUsers-heading'>New Users</h3>
        {liveReport?.users?.length > 0 ? (
          <Row>
            {liveReport?.users?.map((user, i) => (
              <UserCard info={user} key={i} />
            ))}
          </Row>
        ) : (
          <p className='text-secondary'>
            <b>No new users yet ! </b>
          </p>
        )}
      </div>
    </Container>
  )
}
export default LiveReport
