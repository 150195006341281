import shopApi from '_api/shopApi'
import { getListShopWithLazadaFeature } from '_redux/reducers/shops'
import {
  LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
  LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_VERIFY,
  LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
} from '_utils/constants'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FeaturePaymentRequestItem from '../components/FeaturePaymentRequestItem'
import './style.css'

function LazadaFeatureRequestList() {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [listShopRegisterLazada, setListShopRegisterLazada] = useState([])
  const { listShopWithLazadaFeature } = useSelector((state) => state.shops)

  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')
  const reqId = new URLSearchParams(search).get('reqId')

  const clearUrl = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    url.search = ''

    const clearUrlString = url.toString()

    window.history.pushState({}, document.title, clearUrlString)
  }

  const toggleModal = () => {
    setShow(!show)
  }

  const handleUpdateSucceed = () => {
    const index = listShopRegisterLazada.findIndex((req) => req.id === reqId)
    const requestIsApproved = listShopRegisterLazada.find((req) => req.id === reqId)
    requestIsApproved.lazadaFeatureStatus = LAZADA_FEATURE_PAYMENT_STATUS_APPROVED
    requestIsApproved.lazadaFeaturePaymentStatus = LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_VERIFY
    listShopRegisterLazada[index] = requestIsApproved
    setListShopRegisterLazada(listShopRegisterLazada)
    clearUrl()
    setShow(false)
  }

  const updateStatusLazadaFeature = async () => {
    try {
      const data = {
        lazadaAppKey: code.slice(2, 8),
        codeAuth: code,
      }
      await shopApi.updateShopWithLazadaFeatureStatus(
        reqId,
        data,
        LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
      )
      handleUpdateSucceed()
    } catch (error) {}
  }

  useEffect(() => {
    if (code !== null && reqId !== null) {
      setShow(true)
    }
  }, [])

  const handleSelectedStatus = (e) => {
    if (e.target.value === LAZADA_FEATURE_PAYMENT_STATUS_PENDING.toString()) {
      setListShopRegisterLazada(
        listShopWithLazadaFeature?.filter(
          (item) => item?.lazadaFeatureStatus === LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
        ),
      )
    } else if (e.target.value === LAZADA_FEATURE_PAYMENT_STATUS_APPROVED.toString()) {
      setListShopRegisterLazada(
        listShopRegisterLazada?.filter(
          (item) => item?.lazadaFeatureStatus === LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
        ),
      )
    } else {
      setListShopRegisterLazada(listShopWithLazadaFeature)
    }
  }

  useEffect(() => {
    dispatch(getListShopWithLazadaFeature())
  }, [])

  useEffect(() => {
    setListShopRegisterLazada(listShopWithLazadaFeature)
  }, [listShopWithLazadaFeature?.length])

  return (
    <>
      <div className='container-fluid'>
        <h3 className='lazada__feature-heading'>Requests account lazada feature</h3>
        <div>
          <label className='lazada__feature-choose-status'>Choose lazada feature status:</label>
          <select
            className='lazada__feature-select-status'
            onChange={(e) => handleSelectedStatus(e)}
          >
            <option value='all'>All</option>
            <option value='1'>Pending</option>
            <option value='2'>Approved</option>
          </select>
        </div>
        <div className='scroll__mobile'>
          <div className='table-responsive'>
            <table className='table lazada'>
              <thead style={{ fontSize: 14 }}>
                <tr className='lazada__feature-list-title'>
                  <th scope='col'>#</th>
                  <th scope='col'>
                    <p>Shop Name</p>
                  </th>
                  <th scope='col'>
                    <p>Shop Phone</p>
                  </th>
                  <th scope='col'>
                    <p>Shop Email</p>
                  </th>
                  <th scope='col'>
                    <p>Date</p>
                  </th>
                  <th scope='col'>
                    <p>Price</p>
                  </th>
                  <th scope='col'>
                    <p>Package Name</p>
                  </th>
                  <th scope='col'>
                    <p>Created Date</p>
                  </th>
                  <th scope='col'>
                    <p>Payment Method</p>
                  </th>
                  <th scope='col'>
                    <p>Status</p>
                  </th>
                  <th scope='col'>
                    <p>Confirm</p>
                  </th>
                  <th scope='col'>
                    <p>Actions</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listShopRegisterLazada?.map((info, i) => (
                  <FeaturePaymentRequestItem info={info} key={info.id} index={i} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={toggleModal} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Approved success !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`${
            listShopRegisterLazada?.find((item) => item?.id === reqId)?.shopName
          } is approve lazada feature success! `}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={updateStatusLazadaFeature}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LazadaFeatureRequestList
