import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './style.css'

function ButtonClearData() {
  const [show, setShow] = useState(false)

  const toggleShow = (isShow) => {
    setShow(isShow)
  }

  const clearAllData = () => {
    window.indexedDB.deleteDatabase('Cobee-Admin-db')
    setTimeout(() => {
      toast.info('Data has been deleted')
      window.location.reload()
    }, 500)
  }

  return (
    <>
      <ToastContainer autoClose={1500} />
      <Button
        variant='danger'
        onClick={() => toggleShow(true)}
        className='btn__clear__data'
      >
        Clear Data
      </Button>

      <Modal show={show} onHide={() => toggleShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Note !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to clear data </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => toggleShow(false)}>
            No
          </Button>
          <Button variant='danger' onClick={clearAllData}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ButtonClearData
