import axiosClient from './axiosClient'

const ordersApi = {
  getListOrder: (start, end) => {
    const url = `/list-orders?start=${start}&end=${end}`
    return axiosClient.get(url)
  },
}

export default ordersApi
