import lazadaApi from '_api/lazadaApi'
import packagesApi from '_api/packages'
import {
  PACKAGE_LAZADA,
  SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN,
  SUBSCRIPTION_STATUS_REFUNDED,
} from '_utils/constants'

import { mapLazadaAppInfo } from '_utils/functions'

const FETCH_LIST_PACKAGE_LAZADA = 'FETCH_LIST_PACKAGE_LAZADA'
const CREATE_LAZADA_PACKAGE = 'CREATE_LAZADA_PACKAGE'
const SELECT_LAZADA = 'SELECT_LAZADA'
const CLEAR_SELECT_LAZADA = 'CLEAR_SELECT_LAZADA'
const UPDATE_LAZADA_PACKAGE = 'UPDATE_LAZADA_PACKAGE'
const FETCH_LIST_LAZADA_PAYMENT_REQUEST = 'FETCH_LIST_LAZADA_PAYMENT_REQUEST'
const CHANGE_STATUS_PAYMENT_REQUEST = 'CHANGE_STATUS_PAYMENT_REQUEST'
const SET_DEFAULT_LAZ_APP_FOR_FIRST_PAYMENT_REQ = 'SET_DEFAULT_LAZ_APP_FOR_FIRST_PAYMENT_REQ'
const FETCH_LIST_APP_LAZADA = 'FETCH_LIST_APP_LAZADA'
const HANDLE_MAP_LAZ_APP_AND_PAYMENT_REQ = 'HANDLE_MAP_LAZ_APP_AND_PAYMENT_REQ'
const ON_CHANGE_LAZ_APP_FOR_PAYMENT_REQ = 'ON_CHANGE_LAZ_APP_FOR_PAYMENT_REQ'

const initialState = {
  listLazadaPackage: [],
  selectedLazada: {},
  listLazadaPaymentRequest: [],
  termListLazadaPaymentRequest: [],
  defaultLazApp: {},
  listAppLazada: [],
  listPaymentReqWithApp: [],
}

export const getListAppLazada = () => async (dispatch) => {
  try {
    const { msgResp } = await lazadaApi.getListApp()
    dispatch({
      type: FETCH_LIST_APP_LAZADA,
      payload: {
        listAppLazada: msgResp,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const updateLazadaPackage = (data) => async (dispatch, getState) => {
  const { lazada } = getState()
  const { listLazadaPackage, selectedLazada } = lazada
  const newListLazada = [...listLazadaPackage]
  const index = newListLazada.findIndex((item) => item.id === data.id)

  if (index !== -1) {
    newListLazada[index] = {
      ...data,
      createdDate: selectedLazada.createdDate,
      forProject: selectedLazada.forProject,
      packageType: selectedLazada.packageType,
    }
  }

  try {
    await packagesApi.updateLazadaPackage(data)
    dispatch({
      type: UPDATE_LAZADA_PACKAGE,
      payload: {
        listLazadaPackage: newListLazada,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const clearSelectLazada = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECT_LAZADA,
    payload: {
      selectedLazada: {},
    },
  })
}

export const selectLazada = (lazada) => (dispatch) => {
  dispatch({
    type: SELECT_LAZADA,
    payload: {
      selectedLazada: lazada,
    },
  })
}

export const fetchListPackageLazada = () => async (dispatch) => {
  try {
    const { msgResp } = await packagesApi.getListPackageByType(PACKAGE_LAZADA)
    dispatch({
      type: FETCH_LIST_PACKAGE_LAZADA,
      payload: {
        listLazadaPackage: msgResp,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const createLazadaPackage = (data) => async (dispatch, getState) => {
  try {
    const { lazada } = getState()
    const { listLazadaPackage } = lazada
    const newListLazada = [...listLazadaPackage]

    const { msgResp } = await packagesApi.createLazadaPackage(data)

    newListLazada.push(msgResp)

    dispatch({
      type: CREATE_LAZADA_PACKAGE,
      payload: {
        listLazadaPackage: newListLazada,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const getListLazadaPaymentRequest = () => async (dispatch) => {
  try {
    const { msgResp } = await packagesApi.getListLazadaPaymentRequest()
    const sortedList = msgResp.sort((a, b) => b.createdDateTimestamp - a.createdDateTimestamp)

    dispatch({
      type: FETCH_LIST_LAZADA_PAYMENT_REQUEST,
      payload: {
        listLazadaPaymentRequest: sortedList,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const changeStatusPaymentRequest =
  (postData, params, postStatus, paymentRequest) => async (dispatch, getState) => {
    const { lazada } = getState()
    const { listPaymentReqWithApp } = lazada
    const newListPaymentReqWithApp = [...listPaymentReqWithApp]
    const index = newListPaymentReqWithApp.findIndex((item) => item.id === paymentRequest.id)

    if (index !== -1) {
      try {
        const convertedPostData = {
          lazadaAppKey: postData.lazadaAppKey,
          codeAuth: postData.codeAuth,
        }
        await lazadaApi.updateStatusPaymentRequest(convertedPostData, params)

        newListPaymentReqWithApp[index].status = Number(postStatus.status)
        newListPaymentReqWithApp[index].paymentStatus = Number(postStatus.paymentStatus)
        newListPaymentReqWithApp[index].lazadaAppInfo = {
          lazadaAppKey: postData.lazadaAppKey,
          lazadaAppName: postData.lazadaAppName,
        }

        dispatch({
          type: CHANGE_STATUS_PAYMENT_REQUEST,
          payload: {
            listPaymentReqWithApp: newListPaymentReqWithApp,
          },
        })
      } catch (e) {
        throw new Error(e.message || e.msgResp)
      }
    }
  }

export const handleRefundPaymentReq =
  (postData, params, paymentReqItem) => async (dispatch, getState) => {
    const { lazada } = getState()
    const { listPaymentReqWithApp } = lazada
    const newListPaymentReqWithApp = [...listPaymentReqWithApp]
    const index = newListPaymentReqWithApp.findIndex((item) => item.id === paymentReqItem.id)

    if (index !== -1) {
      try {
        const convertedPostData = {
          lazadaAppKey: postData.lazadaAppKey,
          codeAuth: postData.codeAuth,
        }

        await lazadaApi.updateStatusPaymentRequest(convertedPostData, params)

        newListPaymentReqWithApp[index].status = SUBSCRIPTION_STATUS_REFUNDED
        newListPaymentReqWithApp[index].paymentStatus = SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN

        dispatch({
          type: CHANGE_STATUS_PAYMENT_REQUEST,
          payload: {
            listPaymentReqWithApp: newListPaymentReqWithApp,
          },
        })
      } catch (e) {
        throw new Error(e.message || e.msgResp)
      }
    } else {
      throw new Error('Not found')
    }
  }



export const handleMapLazAppAndPaymentReq =
  (listLazadaPaymentRequest, listAppLazada) => (dispatch) => {
    const mappedList = mapLazadaAppInfo(listLazadaPaymentRequest, listAppLazada)
    const sortedList = mappedList.sort((a, b) => b.createdDateTimestamp - a.createdDateTimestamp)

    dispatch({
      type: HANDLE_MAP_LAZ_APP_AND_PAYMENT_REQ,
      payload: {
        listPaymentReqWithApp: sortedList,
      },
    })
  }

export const setDefaultLazAppForFirstPaymentReq = (app) => (dispatch, getState) => {
  const { lazada } = getState()
  const { listPaymentReqWithApp } = lazada
  const newListPaymentReqWithApp = [...listPaymentReqWithApp]

  if (newListPaymentReqWithApp.length > 0) {
    newListPaymentReqWithApp[0].lazadaAppInfo = { ...app }

    dispatch({
      type: SET_DEFAULT_LAZ_APP_FOR_FIRST_PAYMENT_REQ,
      payload: {
        listPaymentReqWithApp: newListPaymentReqWithApp,
      },
    })
  }
}

export const changeLazAppForPaymentReq =
  (appSelected, paymentReqItemId) => async (dispatch, getState) => {
    const { lazada } = getState()
    const { listPaymentReqWithApp } = lazada
    const newListPaymentReqWithApp = [...listPaymentReqWithApp]

    const findIndex = newListPaymentReqWithApp.findIndex((item) => item.id === paymentReqItemId)

    if (findIndex !== -1) {
      try {
        newListPaymentReqWithApp[findIndex].lazadaAppInfo = { ...appSelected }

        dispatch({
          type: ON_CHANGE_LAZ_APP_FOR_PAYMENT_REQ,
          payload: {
            listPaymentReqWithApp: newListPaymentReqWithApp,
          },
        })
      } catch (error) {
        throw new Error(error.message || error.msgResp)
      }
    } else {
      throw new Error('Not found')
    }
  }

const lazadaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_CHANGE_LAZ_APP_FOR_PAYMENT_REQ:
    case HANDLE_MAP_LAZ_APP_AND_PAYMENT_REQ:
    case FETCH_LIST_APP_LAZADA:
    case SET_DEFAULT_LAZ_APP_FOR_FIRST_PAYMENT_REQ:
    case CHANGE_STATUS_PAYMENT_REQUEST:
    case FETCH_LIST_LAZADA_PAYMENT_REQUEST:
    case UPDATE_LAZADA_PACKAGE:
    case CLEAR_SELECT_LAZADA:
    case SELECT_LAZADA:
    case CREATE_LAZADA_PACKAGE:
    case FETCH_LIST_PACKAGE_LAZADA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default lazadaReducer
