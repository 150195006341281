import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'

// const checkTokenExpirationMiddleware = () => (next) => (action) => {
//   const fakeExpiration = localStorage.getItem(FAKE_EXPIRATION)
//
//   if (fakeExpiration) {
//     const fakeExpirationTime = moment(fakeExpiration)
//     const currentTime = moment()
//
//     if (currentTime.isAfter(fakeExpirationTime)) {
//       clearLocalStorage()
//     }
//   }
//
//   next(action)
// }

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const store = createStore(rootReducer, composedEnhancer)
export default store
