import { yupResolver } from '@hookform/resolvers/yup'
import lazadaAppApi from '_api/lazadaApi'
import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import * as Yup from 'yup'
import AppItem from './AppItem'

import './style.css'

const schema = Yup.object()
  .shape({
    appName: Yup.string().required('App name is required'),
    appKey: Yup.string().required('App key is required'),
    appCountry: Yup.string()
      .required('App country is required')
      .oneOf(
        ['SINGAPORE', 'THAILAND', 'MALAYSIA', 'VIETNAM', 'PHILIPPINES', 'INDONESIA'],
        'App country must be one of examples',
      ),
    appSecret: Yup.string().required('App secret is required'),
  })
  .required()

function LazadaApp() {
  const [listApp, setListApp] = useState([])
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const { handleSubmit, register, reset, getValues, formState, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      appName: '',
      appKey: '',
      appCountry: '',
      appSecret: '',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (watch('appCountry')) {
      setValue('appCountry', watch('appCountry').toUpperCase())
    }
  }, [setValue, watch('appCountry')])

  const defaultValues = getValues()

  const handleClose = () => {
    setShow(false)
    reset(defaultValues)
  }
  const handleShow = () => setShow(true)

  const fetchList = async () => {
    setLoading(true)
    try {
      const { msgResp } = await lazadaAppApi.getListApp()
      setListApp(msgResp)
    } catch (error) {
      toast.error(error.msgResp)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchList()
  }, [])

  const onSubmit = async (data) => {
    setIsSubmit(true)
    try {
      const newData = {
        ...data,
        lazadaAppKey: data.appKey,
        lazadaAppSecret: data.appSecret,
        lazadaAppCountry: data.appCountry,
        lazadaAppName: data.appName,
      }
      await lazadaAppApi.createLazadaApp(newData)
      handleClose()
      reset(defaultValues)
      toast.success('Create app lazada success')
    } catch (error) {
      toast.error(error.msgResp)
    } finally {
      setIsSubmit(false)
    }
  }

  const columns = [
    { label: '#', style: {} },
    { label: 'App Name', style: {} },
    { label: 'App Key', style: {} },
    { label: 'App Country', style: {} },
    { label: 'App Slot', style: {} },
    { label: 'Created App Date', style: {} },
  ]

  return (
    <div className='container-fluid'>
      <div className='d-flex align-items-center justify-content-between'>
        <h3 className='lazada__feature-heading'>Requests account lazada feature</h3>
        <button type='button' className='btn btn-outline-success rounded-pill' onClick={handleShow}>
          Create App Lazada
        </button>
      </div>

      <div className='scroll__mobile'>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr className='lazada__feature-list-title'>
                {columns.map((column, index) => (
                  <th key={index} style={column.style}>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={columns.length} className='text-center'>
                    <div className='loading'>Loading...</div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {listApp.map((item, index) => (
                  <AppItem item={item} index={index} key={item.id} />
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create App Lazada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='create-app-lazada' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group mb-3'>
              <label htmlFor='appName' className='mb-2'>
                App Name
              </label>
              <input type='text' className='form-control' id='appName' {...register('appName')} />
              {formState.errors.appName && (
                <span className='error-text'>{formState.errors.appName.message}</span>
              )}
            </div>
            <div className='form-group mb-3'>
              <label htmlFor='appKey' className='mb-2'>
                App Key
              </label>
              <input type='text' className='form-control' id='appKey' {...register('appKey')} />
              {formState.errors.appKey && (
                <span className='error-text'>{formState.errors.appKey.message}</span>
              )}
            </div>
            <div className='form-group mb-3'>
              <label htmlFor='appSecret' className='mb-2'>
                App Secret
              </label>
              <input
                type='text'
                className='form-control'
                id='appSecret'
                {...register('appSecret')}
              />
              {formState.errors.appSecret && (
                <span className='error-text'>{formState.errors.appSecret.message}</span>
              )}
            </div>
            <div className='form-group mb-3'>
              <label htmlFor='appCountry' className='mb-2'>
                App Country
              </label>
              <input
                type='text'
                className='form-control'
                id='appCountry'
                placeholder='For example: SINGAPORE, VIETNAM, MALAYSIA, THAILAND, PHILIPPINES, INDONESIA'
                {...register('appCountry')}
              />
              {formState.errors.appCountry && (
                <span className='error-text'>{formState.errors.appCountry.message}</span>
              )}
            </div>
            <div className='d-block text-end'>
              <button
                type='button'
                onClick={handleClose}
                className='me-2 btn btn-secondary rounded-pill'
              >
                Close
              </button>
              <button
                type='submit'
                disabled={isSubmit}
                className='btn btn-color-primary text-white rounded-pill'
              >
                {isSubmit ? (
                  <Spinner animation='border' role='status' size='sm'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <ToastContainer autoClose={2000} limit={1} />
    </div>
  )
}

export default LazadaApp
