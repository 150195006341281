import React from 'react'
import { useSelector } from 'react-redux'
import '../style.css'
import SubscriptionItem from './SubscriptionItem'

function ListSubscription({ toggleModal, loading }) {
  const { listSubscription } = useSelector((state) => state.subscription)

  const tableHeaders = [
    '#',
    'Name',
    'Created Date',
    'Description',
    'For Project',
    'Active',
    'Limit',
    'Type',
    'Actions',
  ]

  return (
    <div className='scroll__mobile'>
      <div className='table-responsive'>
        <table className='table subscription'>
          <thead>
            <tr className='table-list-title'>
              {tableHeaders.map((headerText, index) => (
                <th scope='col' key={index}>
                  <span>{headerText}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={tableHeaders.length} className='text-center'>
                  Loading...
                </td>
              </tr>
            )}

            {!loading && listSubscription.length
              ? listSubscription.map((info, i) => (
                  <SubscriptionItem info={info} key={info.id} index={i} toggleModal={toggleModal} />
                ))
              : null}

            {!loading && !listSubscription.length && (
              <tr>
                <td colSpan={tableHeaders.length} className='text-center'>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListSubscription
