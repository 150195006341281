import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { IS_ACTIVE } from '_utils/constants'
import { useIndexedDB } from 'react-indexed-db'
import groupsApi from '_api/groupsApi'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

function ButtonUpdateStatusGroup(props) {
  const { update, getByIndex } = useIndexedDB('groups')

  const { gid, isActive, newStatus } = props

  const [show, setShow] = useState(false)
  const [message, setMessage] = useState()

  const [groupById, setGroupById] = useState()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (isActive === IS_ACTIVE) {
      setMessage('Group is active ! Do you want to turn off this group activity ?')
    } else {
      setMessage('Group is inactive ! Do you want to turn on this group activity ?')
    }
    getByIndex('id', gid).then((group) => {
      setGroupById(group)
    })
  }, [])

  const handleEditStatusGroup = async () => {
    try {
      const statusUpdate = {
        active: isActive === IS_ACTIVE ? 0 : 1,
      }
      await groupsApi.activeGroup(gid, statusUpdate)
      update({ ...groupById, active: isActive === IS_ACTIVE ? 0 : 1 }).then(() => {
        toast.success('Group status has been updated 👌 ')
        newStatus(statusUpdate.active)
      })
    } catch (error) {
      toast.error('An error occurred during the update')
    }
    handleClose()
  }

  return (
    <>
      <ToastContainer />
      <Button
        variant='primary'
        onClick={handleShow}
        className='group__update-status-btn btn-color-primary'
      >
        Update status
      </Button>

      <Modal show={show} onHide={handleClose} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Update Status Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button className='btn-color-grey' onClick={handleClose}>
            No
          </Button>
          <Button className='btn-color-primary' onClick={handleEditStatusGroup}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ButtonUpdateStatusGroup
