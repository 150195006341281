/* eslint-disable no-unused-vars */
import ImgGallery from '_components/ImgGallery'
import noAvatar from '_images/noAvatar.jpg'
import {
  LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
  LAZADA_FEATURE_PAYMENT_STATUS_CANCEL,
  LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
  LAZADA_FEATURE_PAYMENT_STATUS_REFUND,
  LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED,
  PAYMENT_METHOD_TRANSFER,
  SUBSCRIPTION_FEE_TRIAL,
} from '_utils/constants'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import SearchIcon from '_images/search.png'
import ButtonApprovedLazadaFeature from './ButtonApproveLazadaFeature'
import ButtonCancelLazadaFeature from './ButtonCancelLazadaFeature'

function FeaturePaymentRequestItem({ info, index }) {
  const {
    shopLogo,
    shopEmail,
    shopPhone,
    shopName,
    createdDateTimestamp,
    status,
    price,
    paymentScreenshot,
    paymentLink,
    paymentType,
    name,
  } = info

  const [currentLazadaStatus, setCurrentLazadaStatus] = useState(status)
  const [actions, setActions] = useState()

  const updateNewStatus = (newStatus) => {
    setCurrentLazadaStatus(newStatus)
  }

  useEffect(() => {
    setCurrentLazadaStatus(status)
  }, [status])

  useEffect(() => {
    if (currentLazadaStatus === LAZADA_FEATURE_PAYMENT_STATUS_PENDING) {
      setActions(
        <>
          <ButtonApprovedLazadaFeature
            updateNewStatus={updateNewStatus}
            shopWithLazadaFeature={info}
          />
          <ButtonCancelLazadaFeature
            updateNewStatus={updateNewStatus}
            shopWithLazadaFeature={info}
          />
        </>,
      )
    } else {
      setActions('N/A')
    }
  }, [currentLazadaStatus])

  const openNewTab = (url) => {
    window.open(url)
  }

  function getPaymentStatusText() {
    switch (currentLazadaStatus) {
      case LAZADA_FEATURE_PAYMENT_STATUS_APPROVED:
        return 'Approved'
      case LAZADA_FEATURE_PAYMENT_STATUS_PENDING:
        return 'Pending'
      case LAZADA_FEATURE_PAYMENT_STATUS_CANCEL:
        return 'Cancelled'
      case LAZADA_FEATURE_PAYMENT_STATUS_REFUND:
        return 'Refund'
      case LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED:
        return 'Refunded'
      default:
        return 'Status Unknown'
    }
  }

  return (
    <>
      <tr>
        <th scope='row' className='lazada__feature__item-number-index' style={{ width: '30px' }}>
          {index + 1}
        </th>

        <td className='table__avatar'>
          <img
            src={shopLogo[0] || noAvatar}
            alt=''
            className='object-cover img-fluid'
            width={200}
          />{' '}
          <br />
          <span>{shopName}</span>
        </td>

        <td className='table__item'>
          <span>{shopPhone}</span>
        </td>

        <td className='table__item'>
          <span>{shopEmail}</span>
        </td>

        <td className='table__item'>
          <span>{moment.unix(createdDateTimestamp).format('DD-MM-YYYY')}</span>
        </td>

        <td className='table__item-price'>
          <span>$ {price || 'free'}</span>
        </td>
        <td className='table__item'>
          <span>{price === SUBSCRIPTION_FEE_TRIAL ? 'Try out' : `${name}`}</span>
        </td>
        <td className='table__item'>
          <span>{moment.unix(createdDateTimestamp).format('DD-MM-YYYY')}</span>
        </td>

        <td className='table__item'>
          <span>
            {price !== SUBSCRIPTION_FEE_TRIAL ? (
              <span>{paymentType === PAYMENT_METHOD_TRANSFER ? 'Transfer' : 'Hit-Pay'}</span>
            ) : (
              'Try out'
            )}
          </span>
        </td>

        <td className='table__item'>
          <span>{getPaymentStatusText(status)}</span>
        </td>

        <td style={{ cursor: 'pointer' }}>
          {price === SUBSCRIPTION_FEE_TRIAL ? (
            'Try out'
          ) : (
            <>
              {paymentType === PAYMENT_METHOD_TRANSFER ? (
                <ImgGallery image={paymentScreenshot} width='36px' />
              ) : (
                <p onClick={() => openNewTab(paymentLink)}>
                  <img alt='' src={SearchIcon} />
                </p>
              )}
            </>
          )}
        </td>
        <td>
          <div className='lazada__feature__item-actions justify-content-start'>{actions}</div>
        </td>
      </tr>
    </>
  )
}

export default FeaturePaymentRequestItem
