import React, { useEffect, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { IS_ADMIN } from '_utils/constants'
import { LinkContainer } from 'react-router-bootstrap'
import noAvatar from '_images/noAvartar.jpg'

import 'overlayscrollbars/css/OverlayScrollbars.css'

function GroupDetailListAdmin(props) {
  const { members } = props
  const [listAdmin, setListAdmin] = useState()

  useEffect(() => {
    const getListAdmin = members?.filter((admin) => {
      return admin.isAdmin === IS_ADMIN
    })
    setListAdmin(getListAdmin)
  }, [members])

  return (
    <OverlayScrollbarsComponent>
      <div className='scroll__detail__list-admin'>
        <table className='table'>
          <thead>
            <tr style={{ color: '#51aaaa' }}>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Total Events</th>
            </tr>
          </thead>
          <tbody>
            {listAdmin?.map((admin, i) => (
              <LinkContainer
                key={i}
                to={`/users/detail-user?uid=${admin.uid}`}
                style={{ cursor: 'pointer' }}
              >
                <tr>
                  <td style={{ color: '#7e7e7e' }}>{i + 1}</td>
                  <td className='group__detail-name-avatar'>
                    <img src={admin.photoUrl || noAvatar} alt='' />
                    {admin.name}
                  </td>
                  <td className='group__detail-admin-text-color'>{admin.phone}</td>
                  <td className='group__detail-admin-text-color'>{admin.email}</td>
                  <td style={{ color: '#f06a0f' }}>{admin.totalEvents || 0}</td>
                </tr>
              </LinkContainer>
            ))}
          </tbody>
        </table>
      </div>
    </OverlayScrollbarsComponent>
  )
}

export default GroupDetailListAdmin
