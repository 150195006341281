import usersApi from '_api/usersApi'

const GET_ALL_USERS = 'GET_ALL_USERS'
const GET_LIST_USER_BY_DATE = 'GET_LIST_USER_BY_DATE'
const GET_LIST_USE_WITH_SCHEDULE_STATUS = 'GET_LIST_USE_WITH_SCHEDULE_STATUS'
const GET_LIST_USE_WITH_PREMIUM_STATUS = 'GET_LIST_USE_WITH_PREMIUM_STATUS'
const GET_LIST_SUBSCRIPTION = 'GET_LIST_SUBSCRIPTION'
const SET_IS_SYNC_DATA_USER = 'SET_IS_SYNC_DATA_USER'
const GET_LIST_USER_WITH_WIX_FEATURE = 'GET_LIST_USER_WITH_WIX_FEATURE'
const GET_LIST_USER_WITH_LAZADA_FEATURE = 'GET_LIST_USER_WITH_LAZADA_FEATURE'
const FILTER_LIST_SUBSCRIPTION = 'FILTER_LIST_SUBSCRIPTION'

const initialState = {
  listAllUsers: null,
  listUserByDate: null,
  isSyncDataUser: false,
  listUserWithScheduleStatus: null,
  listUserWithPremiumStatus: null,
}

export const setIsSyncDataUser = (bol) => (dispatch) => {
  dispatch({
    type: SET_IS_SYNC_DATA_USER,
    payload: bol,
  })
}

export const getListUserWithLazadaFeature = () => async (dispatch) => {
  let list = []
  try {
    const response = await usersApi.getListUserWithLazadaFeatureStatus()
    if (response?.msgResp) {
      list = response?.msgResp
    }
    dispatch({
      type: GET_LIST_USER_WITH_LAZADA_FEATURE,
      payload: list,
    })
  } catch (error) {}
}

export const getListUserWithWixFeature = () => async (dispatch) => {
  let list = []
  try {
    const response = await usersApi.getListUserWithWixFeatureStatus()
    if (response?.msgResp) {
      list = response?.msgResp
    }
    dispatch({
      type: GET_LIST_USER_WITH_WIX_FEATURE,
      payload: list,
    })
  } catch (error) {}
}

export const getUsersByDate = (start, end) => async (dispatch) => {
  let listUserByDate = []
  try {
    const response = await usersApi.getUsersByDate(start, end)
    if (response?.msgResp) {
      listUserByDate = response.msgResp
    }
    dispatch({
      type: GET_LIST_USER_BY_DATE,
      payload: listUserByDate,
    })
  } catch (error) {}
}

export const getAllUsers = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_USERS,
    payload: data,
  })
}

export const getListUserWithScheduleStatus = () => async (dispatch) => {
  let listUserWithScheduleStatus = []
  const response = await usersApi.getListUserWithScheduleStatus()
  if (response.msgResp) {
    listUserWithScheduleStatus = response.msgResp
  }
  dispatch({
    type: GET_LIST_USE_WITH_SCHEDULE_STATUS,
    payload: listUserWithScheduleStatus,
  })
}

export const getListUserWithPremiumStatus = () => async (dispatch) => {
  let listUserWithPremiumStatus = []
  const response = await usersApi.getListUserWithPremiumStatus()
  if (response.msgResp) {
    listUserWithPremiumStatus = response.msgResp
  }
  dispatch({
    type: GET_LIST_USE_WITH_PREMIUM_STATUS,
    payload: listUserWithPremiumStatus,
  })
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS: {
      return {
        ...state,
        listAllUsers: [...action.payload],
      }
    }

    case GET_LIST_USER_BY_DATE: {
      return {
        ...state,
        listUserByDate: [...action.payload],
      }
    }

    case GET_LIST_USE_WITH_SCHEDULE_STATUS:
      return {
        ...state,
        listUserWithScheduleStatus: [...action.payload],
      }

    case GET_LIST_USE_WITH_PREMIUM_STATUS:
      return {
        ...state,
        listUserWithPremiumStatus: [...action.payload],
      }

    case GET_LIST_SUBSCRIPTION:
      return {
        ...state,
        listSubscription: action.payload,
      }
    case SET_IS_SYNC_DATA_USER: {
      return {
        ...state,
        isSyncDataUser: action.payload,
      }
    }

    case GET_LIST_USER_WITH_WIX_FEATURE: {
      return {
        ...state,
        listUserWithWixFeature: [...action.payload],
      }
    }

    case GET_LIST_USER_WITH_LAZADA_FEATURE: {
      return {
        ...state,
        listUserWithLazadaFeature: [...action.payload],
      }
    }
    case FILTER_LIST_SUBSCRIPTION: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

export default usersReducer
