import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { IS_ACTIVE, IS_PAYNOW } from '_utils/constants'
import { useIndexedDB } from 'react-indexed-db'
import moment from 'moment'
import { convertToCurrentGMT, convertTimestampToCurrentGMT } from '_utils/functions'
import ImageGallery from 'react-image-gallery'

import useQuery from '_hooks/useQuery'
import noProduct from '_images/noProduct.png'

import ProductItem from '../components/ProductItem'

import './style.css'
import 'react-image-gallery/styles/css/image-gallery.css'

function EventDetail() {
  const { getByIndex } = useIndexedDB('events')
  const params = useQuery()

  const idEvent = params.get('eid')

  const [events, setEvents] = useState()
  const [imageEvent, setImageEvent] = useState(null)

  const isShowThumbnails = events?.photoUrls.length > 1

  useEffect(() => {
    getByIndex('id', idEvent).then((event) => {
      setEvents(event)
      setImageEvent(event?.photoUrls?.map((img) => ({ original: `${img}`, thumbnail: `${img}` })))
    })
  }, [])

  const lastModified = convertTimestampToCurrentGMT(events?.lastModifiedAt)
  const pickupDuration = convertTimestampToCurrentGMT(events?.pickupDuration)
  const pickupTime = convertToCurrentGMT(events?.pickupTime)
  const closingTime = convertToCurrentGMT(events?.closingTime)

  return (
    <>
      <Container>
        <Row style={{ marginTop: '44px' }}>
          <Col>
            {imageEvent ? (
              <ImageGallery
                items={imageEvent}
                showPlayButton={false}
                showNav={false}
                showBullets={isShowThumbnails}
                showThumbnails={isShowThumbnails}
              />
            ) : (
              noProduct
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h1 className='event__detail-title'>Event Name: {events?.title}</h1>
            <div className='event__detail_info'>
              <div className='event__detail_info-text'>
                <p>
                  <b>Total Product: </b>
                  <b id='event__detail-hot-data-text'>{events?.products.length}</b>
                </p>
                <p>
                  <b>Total Order: </b>
                  <b id='event__detail-hot-data-text'>{events?.totalOrderCount}</b>
                </p>
                <p>
                  <b>Status: </b>
                  {events?.status === IS_ACTIVE ? (
                    <b style={{ color: 'green' }}>Active</b>
                  ) : (
                    <b style={{ color: 'red' }}>Closed</b>
                  )}
                </p>

                <p>
                  <b>Pay Now: </b>
                  {events?.paynow === IS_PAYNOW ? 'Yes' : 'No'}
                </p>

                <p>
                  <b>Address: </b>
                  {events?.pickupAddress}
                </p>
                <p>
                  <b>Admin Cost: </b>
                  <b id='event__detail-hot-data-text'> $ {events?.adminCost} </b>
                </p>
                <p>
                  <b>Delivery Cost: </b>
                  <b id='event__detail-hot-data-text'> $ {events?.deliveryCost} </b>
                </p>
                <p>
                  <b>By Group: </b>
                  {events?.groupName}
                </p>
                <p>
                  <b>Description: </b>
                  {events?.description}
                </p>
              </div>

              <div className='event__detail_info-date-text'>
                <p>
                  <b>Date Created: </b>
                  {moment.unix(events?.createdAt).format('DD-MM-YYYY')}
                </p>
                <p>
                  <b>Last Modified: </b>
                  {moment.unix(lastModified).format('DD-MM-YYYY HH:mm:ss')}
                </p>
                <p>
                  <b>pickup Duration: </b>
                  {moment.unix(pickupDuration).format('HH:mm:ss')}
                </p>
                <p>
                  <b>Pickup Time: </b>
                  {pickupTime}
                </p>
                <p>
                  <b>Closing Time: </b>
                  {closingTime}
                </p>
                <div className='event__detail__info-userCreated'>
                  <h4 className='event__detail__info-userCreated-title'>
                    <b>Created by</b>
                  </h4>
                  <p>
                    <b>Name: </b>
                    <img
                      src={events?.createdUserPhotoUrl}
                      className='event__detail__info-user-avatar'
                      alt=''
                    />
                    {events?.createdUserName}
                  </p>

                  <p>
                    <b>Number Phone:</b>
                    {events?.createdUserPhone}
                  </p>

                  <p>
                    <b>Email:</b>
                    {events?.createdUserEmail}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <h1 className='product__heading-text'>Products</h1>
        <div className='mobile-scroll'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='product__title-text'>
                  <th style={{ width: '20px' }}>#</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Limit</th>
                  <th>Order</th>
                </tr>
              </thead>

              <tbody>
                {events?.products.map((product, i) => (
                  <ProductItem key={i} info={product} index={i} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </>
  )
}

export default EventDetail