import eventsApi from '_api/eventsApi'

const GET_LIST_EVENT_BY_DATE = 'GET_LIST_EVENT_BY_DATE'
const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
const SET_IS_SYNC_DATA_EVENT = 'SET_IS_SYNC_DATA_EVENT'


const initialState = {
  listAllEvents: null,
  listEventByDate: null,
  isSyncDataEvent: false

}

export const setIsSyncDataEvent = (bol)  => (dispatch) => {
  dispatch({
    type: SET_IS_SYNC_DATA_EVENT,
    payload:bol
  })
} 

export const getEventsByDate = (start, end) => async (dispatch) => {
  let listEventByDate = []
  try {
    const response = await eventsApi.getEventsByDate(start, end)
    if (response?.msgResp) {
      listEventByDate = response.msgResp
    }
    dispatch({
      type: GET_LIST_EVENT_BY_DATE,
      payload: listEventByDate,
    })
  } catch (error) {}
}

export const getAllEvent = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_EVENTS,
    payload: data,
  })
}

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EVENTS: {
      return {
        ...state,
        listAllEvents: [...action.payload],
      }
    }

    case GET_LIST_EVENT_BY_DATE: {
      return {
        ...state,
        listEventByDate: [...action.payload],
      }
    }

    case SET_IS_SYNC_DATA_EVENT: {
      return {
        ...state,
        isSyncDataEvent: action.payload,
      }
    }

    default:
      return state
  }
}

export default eventsReducer
