import React, { useEffect, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { IS_HOST, IS_BANNED } from '_utils/constants'
import { LinkContainer } from 'react-router-bootstrap'
import noAvatar from '_images/noAvartar.jpg'

import 'overlayscrollbars/css/OverlayScrollbars.css'

function GroupDetailListUser(props) {
  const { members } = props

  const [listMembers, setListMembers] = useState()

  useEffect(() => {
    setListMembers(members)
  }, [members])

  return (
    <OverlayScrollbarsComponent>
      <div className='scroll__detail__list-user'>
        <table className='table'>
          <thead>
            <tr style={{ color: '#51aaaa' }}>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>position</th>
              <th>Total Events</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {listMembers?.map((member, i) => (
              <LinkContainer
                key={i}
                to={`/users/detail-user?uid=${member.uid}`}
                style={{ cursor: 'pointer' }}
              >
                <tr key={i}>
                  <td style={{ color: '#51aaaa' }}>{i + 1}</td>
                  <td className='group__detail-name-avatar'>
                    <img src={member.photoUrl || noAvatar} alt='' />
                    {member.name}
                  </td>
                  <td className='group__detail-members-text-color'>{member.phone}</td>
                  <td className='group__detail-members-text-color'>{member.email}</td>
                  <td className='group__detail-members-text-color'>
                    {member.isHost === IS_HOST ? 'Host' : 'Buyer'}
                  </td>
                  <td style={{ color: '#f06a0f' }}>{member.totalEvents || 0}</td>
                  <td className='group__detail-members-text-color'>
                    {member.banned === IS_BANNED ? (
                      <span style={{ color: '#808080' }}>Banned</span>
                    ) : (
                      <span style={{ color: 'green' }}>Active</span>
                    )}
                  </td>
                </tr>
              </LinkContainer>
            ))}
          </tbody>
        </table>
      </div>
    </OverlayScrollbarsComponent>
  )
}
export default GroupDetailListUser
