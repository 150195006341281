/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { clearLocalStorage } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { Button, Container, Dropdown, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Logo from '../../assets/images/logo.svg'
import ButtonClearData from '../ButtonClearData'
import ButtonSyncData from '../ButtonSyncData'
import './style.css'

const mainColor = '#f06a0f'
const secondaryColor = '#6c757d'

function Header() {
  const { pathname } = useLocation()
  const [isActiveDropdownMenu, setIsActiveDropdownMenu] = useState(false)
  const [isActiveSubMenuItem, setIsActiveSubMenuItem] = useState(false)

  useEffect(() => {
    if (pathname.includes('package') || pathname.includes('list-payment')) {
      setIsActiveDropdownMenu(true)

      if (pathname.includes('subscription') || pathname.includes('lazada')) {
        setIsActiveSubMenuItem(true)
      }
    }

    return () => {
      setIsActiveDropdownMenu(false)
      setIsActiveSubMenuItem(false)
    }
  }, [pathname])

  const logout = () => {
    const confirmLogout = confirm('Are you sure you want to sign out?')
    if (confirmLogout) {
      clearLocalStorage()
    }
  }

  return (
    <>
      <Navbar expand='lg' className='header'>
        <Container fluid>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='me-auto my-3 my-lg-0'
              style={{ maxHeight: '100px', alignItems: 'center' }}
              navbarScroll
            >
              <LinkContainer to='/' exact>
                <Nav.Link className='header__logo'>
                  <img src={Logo} alt='' />
                  <span>Good things must share!</span>
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <ButtonSyncData />
            <ButtonClearData />
            <Button variant='secondary' onClick={logout} className='header__logout-btn'>
              Logout
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className='header__line container-fluid'>
        <ul className='header__line-list'>
          <li className='header__line-list-item'>
            <NavLink
              to='/groups/list-groups'
              activeStyle={{
                color: mainColor,
                borderColor: mainColor,
              }}
            >
              Groups
            </NavLink>
          </li>

          <li className='header__line-list-item'>
            <NavLink
              to='/users/list-users'
              activeStyle={{
                color: mainColor,
                borderColor: mainColor,
              }}
            >
              User
            </NavLink>
          </li>
          <li className='header__line-list-item'>
            <NavLink
              to='/live-report'
              activeStyle={{
                color: mainColor,
                borderColor: mainColor,
              }}
            >
              live report
            </NavLink>
          </li>
          <li className='header__line-list-item'>
            <NavLink
              to='/contact-requests'
              activeStyle={{
                color: mainColor,
                borderColor: mainColor,
              }}
            >
              Contact Requests
            </NavLink>
          </li>

          <li className='header__line-list-item'>
            <NavLink
              to='/lazada-app'
              activeStyle={{
                color: mainColor,
                borderColor: mainColor,
              }}
            >
              Lazada App
            </NavLink>
          </li>

          <Dropdown className='header__line-list-item wrap-dropdown'>
            <Dropdown.Toggle
              id='dropdown-package-1'
              className='bg-transparent border-top-0 border-start-0 border-end-0 py-2 px-0 btn-dropdown'
              style={{
                color:
                  pathname.includes('package') && isActiveDropdownMenu ? mainColor : secondaryColor,
                borderColor: pathname.includes('package') && isActiveDropdownMenu ? mainColor : '',
              }}
            >
              Package
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-1'>
              <Link
                to='/package/subscription'
                className='w-100 py-2 px-3 d-inline-block rounded mb-1'
                style={{
                  color:
                    pathname.includes('package') &&
                    pathname.includes('subscription') &&
                    isActiveSubMenuItem
                      ? '#fff'
                      : secondaryColor,
                  backgroundColor:
                    pathname.includes('package') &&
                    pathname.includes('subscription') &&
                    isActiveSubMenuItem
                      ? mainColor
                      : '',
                }}
              >
                Subscription
              </Link>
              <Link
                to='/package/lazada'
                className='w-100 py-2 px-3 d-inline-block rounded'
                style={{
                  color:
                    pathname.includes('package') &&
                    pathname.includes('lazada') &&
                    isActiveSubMenuItem
                      ? '#fff'
                      : secondaryColor,
                  backgroundColor:
                    pathname.includes('package') &&
                    pathname.includes('lazada') &&
                    isActiveSubMenuItem
                      ? mainColor
                      : '',
                }}
              >
                Lazada
              </Link>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className='header__line-list-item wrap-dropdown'>
            <Dropdown.Toggle
              id='dropdown-package-2'
              className='bg-transparent border-top-0 border-start-0 border-end-0 py-2 px-0 btn-dropdown'
              style={{
                color:
                  pathname.includes('list-payment') && isActiveDropdownMenu
                    ? mainColor
                    : secondaryColor,
                borderColor:
                  pathname.includes('list-payment') && isActiveDropdownMenu ? mainColor : '',
              }}
            >
              List Payment Request
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-1'>
              <Link
                to='/list-payment-request/subscription'
                className='w-100 py-2 px-3 d-inline-block rounded mb-1'
                style={{
                  color:
                    pathname.includes('list-payment') &&
                    pathname.includes('subscription') &&
                    isActiveSubMenuItem
                      ? '#fff'
                      : secondaryColor,
                  backgroundColor:
                    pathname.includes('list-payment') &&
                    pathname.includes('subscription') &&
                    isActiveSubMenuItem
                      ? mainColor
                      : '',
                }}
              >
                Subscription
              </Link>
              <Link
                to='/list-payment-request/lazada'
                className='w-100 py-2 px-3 d-inline-block rounded'
                style={{
                  color:
                    pathname.includes('list-payment') &&
                    pathname.includes('lazada') &&
                    isActiveSubMenuItem
                      ? '#fff'
                      : secondaryColor,
                  backgroundColor:
                    pathname.includes('list-payment') &&
                    pathname.includes('lazada') &&
                    isActiveSubMenuItem
                      ? mainColor
                      : '',
                }}
              >
                Lazada
              </Link>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Dropdown className='header__line-list-item wrap-dropdown'>
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              className='bg-transparent text-secondary border-0 py-2 px-0 btn-dropdown'
            >
              Lazada App
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-1'>
              <Link
                to='/lazada-feature/list-request'
                className='w-100 py-2 px-3 d-inline-block rounded mb-1'
              >
                Lazada Feature
              </Link>
              <Link to='/lazada-app' className='w-100 py-2 px-3 d-inline-block rounded'>
                Lazada App
              </Link>
            </Dropdown.Menu>
          </Dropdown> */}
        </ul>
      </div>
    </>
  )
}
export default Header
