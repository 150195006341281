import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'

import orderIcon from '_images/orderIcon.png'
import productIcon from '_images/productIcon.png'

function EventItem({ info, index }) {
  const { id, title, photoUrls, groupName, createdUserName, totalOrderCount, products, createdAt } =
    info

  return (
    <LinkContainer to={`/events/detail-event?eid=${id}`} style={{ cursor: 'pointer' }}>
      <tr>
        <th scope='row' className='event__list-mumber-index'>
          {index + 1}
        </th>
        <td className='event__list__photos__name'>
          {photoUrls?.map((img, i) => (
            <img src={img} alt='' key={i} />
          ))}
          <span>{title}</span>
        </td>
        <td>
          <div className='event__list__total__product'>
            <img src={productIcon} alt='' />
            {products?.length}
          </div>
        </td>
        <td className='event__list__group-name'>
          <span>{groupName}</span>
        </td>
        <td className='event__list__created-date'>
          <span>{moment.unix(createdAt).format('DD-MM-YYYY')}</span>
        </td>
        <td className='event__list__created-by-user-name'>
          <span>{createdUserName}</span>
        </td>
        <LinkContainer to={`/orders/list-order?eid=${id}`} style={{ textAlign: 'center' }}>
          <td>
            <OverlayTrigger
              placement='bottom'
              overlay={<Tooltip id='button-tooltip-2'>Click to watch order list</Tooltip>}
            >
              <div className='event__list__total__order'>
                <img src={orderIcon} alt='' />
                {totalOrderCount}
              </div>
            </OverlayTrigger>
          </td>
        </LinkContainer>
      </tr>
    </LinkContainer>
  )
}
export default EventItem
