/* eslint-disable no-alert */
import authApi from '_api/authApi'
import { getUserToken, setUserToken } from '_utils/localData'
import React, { useEffect, useRef, useState } from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

export default function Signin() {
  const codeRef = useRef(null)

  const [emailUser, setEmailUser] = useState()

  const history = useHistory()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false)

  function isToggleShow(isShow) {
    setShowMessage(isShow)
  }

  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
  })

  const onSubmit = async (data) => {
    if (data) {
      try {
        const dataAccount = {
          email: data.email,
          password: data.password,
        }
        setEmailUser(data.email)
        await authApi.login1st(dataAccount)

        setShow(true)
      } catch (error) {
        setErrorMessage('Invalid email or Invalid password ')
        isToggleShow(true)
      }
    }
  }

  const onLogin = async () => {
    try {
      const data = {
        email: emailUser,
        password: codeRef.current.value,
      }
      const res = await authApi.login2nd(data)
      setUserToken(res.msgResp.token)
      history.push('/')
    } catch (error) {
      alert('OTP code is incorrect, Please re-enter')
    }
  }

  useEffect(() => {
    const checkLogged = async () => {
      const token = await getUserToken()
      if (token) history.push('/')
    }
    checkLogged()
  }, [])

  return (
    <section className='vh-100 bg-co-bee'>
      <div className='container-fluid h-custom'>
        <div className='row d-flex justify-content-center align-items-center h-100'>
          <div className='col-md-8 col-lg-6 col-xl-4 Signin'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h1 className='Signin__heading'>Cobee Admin</h1>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <FloatingLabel controlId='floatingInput' label='Email address' className='mb-3'>
                  <Form.Control
                    type='email'
                    name='email'
                    placeholder='name@example.com'
                    {...register('email')}
                    className='signin__input-text'
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <FloatingLabel controlId='floatingPassword' label='Password' className='mb-3'>
                  <Form.Control
                    type='password'
                    name='password'
                    placeholder='Password'
                    {...register('password')}
                    className='signin__input-text'
                  />
                </FloatingLabel>
              </Form.Group>
              <Button variant='primary' type='submit' className='signin__submit-btn'>
                LOGIN
              </Button>
            </Form>
          </div>
        </div>
      </div>

      <Modal show={showMessage} backdrop='static' onHide={() => isToggleShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='modal__errorMessage-heading'>Login failed</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: 'red' }}>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => isToggleShow(false)}
            className='btn-color-grey'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='modal__opt-code'
      >
        <Modal.Header closeButton>
          <Modal.Title className='modal__opt-heading'>OTP code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onLogin)}>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <FloatingLabel controlId='floatingPassword' label='OTP CODE'>
                <Form.Control
                  ref={codeRef}
                  type='password'
                  placeholder='OTP CODE'
                  className='moda__opt-input'
                />
              </FloatingLabel>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose} className='btn-color-grey'>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={onLogin}
            className='modal__opt-btn-login btn-color-primary'
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  )
}
