import { yupResolver } from '@hookform/resolvers/yup'
import {
  createSubscriptionPackage,
  getListPackageSubscription,
  updateSubscriptionPackage,
} from '_redux/reducers/subscription'
import { ANNUALLY_VALUE, MONTHLY_VALUE, PACKAGE_SUBSCRIPTION } from '_utils/constants'
import React, { createContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import schema from '../validate/schemaSubscription'
import ListSubscription from './ListSubscription'
import ModalSubscription from './ModalSubscription'

export const SubscriptionContext = createContext()

const Subscription = () => {
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const { selectedSubscription } = useSelector((state) => state.subscription)

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      subscriptionType: selectedSubscription?.packageType,
      description: selectedSubscription?.description,
      limit: selectedSubscription?.limit,
      active: selectedSubscription?.active,
      variations: selectedSubscription?.variations,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variations',
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(getListPackageSubscription())
      setLoading(false)
    }

    fetchData().then(() => {})
  }, [])

  const toggleModal = () => setIsOpenModal(!isOpenModal)

  const onUpdate = async (data, id) => {
    const newPackage = {
      id,
      description: data.description,
      limit: data.limit,
      active: Number(data.active),
      subscriptionPackageType: selectedSubscription?.subscriptionPackageType,
      variations: data.variations?.map((variant) => {
        const valuePeriod = variant.isMonthly ? MONTHLY_VALUE : ANNUALLY_VALUE
        return {
          ...variant,
          discountPrice: Number(variant.discountPrice),
          expireValue: Number(variant.inputValue) * Number(valuePeriod),
        }
      }),
    }

    try {
      setIsSubmitting(true)
      await dispatch(updateSubscriptionPackage(newPackage))
      toast.success('update package success!')
      toggleModal()
    } catch (error) {
      toast.warn(error.message || error.msgResp)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onSubmit = async (data) => {
    const newPackage = {
      subscriptionType: data.subscriptionType,
      packageType: PACKAGE_SUBSCRIPTION,
      description: data.description,
      limit: data.limit,
      active: Number(data.active),
      variations: data.variations?.map((variant) => {
        const valuePeriod = variant.isMonthly ? MONTHLY_VALUE : ANNUALLY_VALUE
        return {
          ...variant,
          discountPrice: Number(variant.discountPrice),
          expireValue: Number(variant.inputValue) * Number(valuePeriod),
        }
      }),
    }

    try {
      setIsSubmitting(true)
      await dispatch(createSubscriptionPackage(JSON.parse(JSON.stringify(newPackage))))
      toast.success('create new package success!')
      toggleModal()
    } catch (error) {
      toast.warn(error.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <SubscriptionContext.Provider
        value={{
          register,
          handleSubmit,
          onSubmit,
          errors,
          fields,
          append,
          remove,
          control,
          toggleModal,
          isOpenModal,
          onUpdate,
          watch,
          reset,
          setValue,
          clearErrors,
          isSubmitting,
        }}
      >
        <div className='container-fluid'>
          <ToastContainer autoClose={2000} limit={1} />
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <h3 className='table-heading'>List Package Subscription</h3>
            <button
              type='button'
              className='btn btn-outline-success rounded-pill'
              onClick={toggleModal}
            >
              Add Package
            </button>
          </div>
          <ListSubscription loading={loading} toggleModal={toggleModal} />
        </div>

        {isOpenModal && <ModalSubscription />}
      </SubscriptionContext.Provider>
    </>
  )
}

export default Subscription
