import { USER_TOKEN, DATA_REPORT } from './constants'

export const setUserToken = (value) => {
  localStorage.setItem(USER_TOKEN, JSON.stringify(value))
}

export const getUserToken = () => {
  const value = localStorage.getItem(USER_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setDataReport = (data) => {
  localStorage.setItem(DATA_REPORT, JSON.stringify(data))
}

export const getDataReport = () => {
  const data = localStorage.getItem(DATA_REPORT)
  try {
    if (data) {
      return JSON.parse(data)
    }
    return null
  } catch (error) {
    return data
  }
}

export const clearLocalStorage = () => {
  localStorage.clear()
  window.location.assign('/sign-in')
}


