import { yupResolver } from '@hookform/resolvers/yup'
import {
  createLazadaPackage,
  fetchListPackageLazada,
  updateLazadaPackage,
} from '_redux/reducers/lazada'
import { PACKAGE_LAZADA } from '_utils/constants'
import React, { createContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import schemaLazada from '../validate/schemaLazada'

import ListLazada from './ListLazada'
import ModalLazada from './ModalLazada'

export const LazadaContext = createContext()

const Lazada = () => {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaLazada),
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(fetchListPackageLazada())
      setLoading(false)
    }

    fetchData().then(() => {})
  }, [])

  const toggleModal = () => setIsOpenModal(!isOpenModal)

  const onUpdate = async (data, id) => {
    const newPackage = {
      id,
      active: Number(data.active),
      description: data.description,
      name: data.name,
      price: data.price,
      expireValue: data.expireValue,
    }

    try {
      await dispatch(updateLazadaPackage(newPackage))
      toast.success('update package success!')
      toggleModal()
    } catch (error) {
      toast.warn(error.message)
    }
  }

  const onSubmit = async (data) => {
    const newPackage = {
      active: Number(data.active),
      description: data.description,
      name: data.name,
      packageType: PACKAGE_LAZADA,
      price: data.price,
      expireValue: data.expireValue,
      discountPrice: Number(data.discountPrice),
    }

    try {
      await dispatch(createLazadaPackage(newPackage))
      toast.success('create new package success!')
      toggleModal()
    } catch (error) {
      toast.warn(error.message)
    }
  }

  return (
    <LazadaContext.Provider
      value={{
        register,
        handleSubmit,
        onSubmit,
        errors,
        control,
        toggleModal,
        isOpenModal,
        onUpdate,
        watch,
        reset,
        setValue,
      }}
    >
      <div className='container-fluid'>
        <ToastContainer autoClose={2000} limit={1} />
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <h3 className='table-heading'>List Package Lazada</h3>
          <button
            type='button'
            className='btn btn-outline-success rounded-pill'
            onClick={toggleModal}
          >
            Add Package
          </button>
        </div>
        <ListLazada loading={loading} toggleModal={toggleModal} />
      </div>

      {isOpenModal && <ModalLazada />}
    </LazadaContext.Provider>
  )
}

export default Lazada
