import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import GroupDetail from './GroupDetail'
import GroupList from './GroupList'

function Groups() {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={`${path}/list-groups`} component={GroupList} />
        <Route path={`${path}/detail-groups`} component={GroupDetail} />
      </Switch>
    </>
  )
}

export default Groups
