import packagesApi from '_api/packages'
import { PACKAGE_SUBSCRIPTION } from '_utils/constants'
import subscriptionApi from '../../api/subscription'

const GET_LIST_PACKAGE_SUBSCRIPTION = 'GET_LIST_PACKAGE_SUBSCRIPTION'
const CREATE_PACKAGE_SUBSCRIPTION = 'CREATE_PACKAGE_SUBSCRIPTION'
const SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION'
const FILTER_LIST_SUBSCRIPTION = 'FILTER_LIST_SUBSCRIPTION'
const CLEAR_SELECT_SUBSCRIPTION = 'CLEAR_SELECT_SUBSCRIPTION'
const UPDATE_SUBSCRIPTION_PACKAGE = 'UPDATE_SUBSCRIPTION_PACKAGE'
const GET_LIST_PAYMENT_REQUEST = 'GET_LIST_PAYMENT_REQUEST'
const CHANGE_STATUS_PAYMENT_REQUEST = 'CHANGE_STATUS_PAYMENT_REQUEST'

const initialState = {
  listSubscription: [],
  selectedSubscription: {},
  listSubscriptionPaymentRequest: [],
  termListSubscriptionPaymentRequest: [],
}

export const changeStatusPaymentRequest =
  (postData, subscriptionId) => async (dispatch, getState) => {
    const { subscription } = getState()
    const { listSubscriptionPaymentRequest } = subscription
    const newListSubscriptionPaymentRequest = [...listSubscriptionPaymentRequest]
    const index = newListSubscriptionPaymentRequest.findIndex((item) => item.id === subscriptionId)

    if (index !== -1) {
      const postParams = {
        id: subscriptionId,
        status: postData.status,
      }

      const postDataToServer = {
        price: postData.priceRefund,
      }

      try {
        await subscriptionApi.updateStatusPaymentRequest(postParams, postDataToServer)

        newListSubscriptionPaymentRequest[index].status = Number(postData.status)
        newListSubscriptionPaymentRequest[index].paymentStatus = Number(postData.paymentStatus)

        dispatch({
          type: CHANGE_STATUS_PAYMENT_REQUEST,
          payload: {
            listSubscriptionPaymentRequest: newListSubscriptionPaymentRequest,
          },
        })
      } catch (e) {
        throw new Error(e.message || e.msgResp)
      }
    }
  }

export const getListSubscriptionPaymentRequest = () => async (dispatch) => {
  try {
    const { msgResp } = await subscriptionApi.getListPaymentRequest()
    dispatch({
      type: GET_LIST_PAYMENT_REQUEST,
      payload: {
        listSubscriptionPaymentRequest: msgResp,
        termListSubscriptionPaymentRequest: msgResp,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const updateSubscriptionPackage = (data) => async (dispatch, getState) => {
  const { subscription } = getState()
  const { listSubscription, selectedSubscription } = subscription
  const newListSubscription = [...listSubscription]
  const index = newListSubscription.findIndex((item) => item.id === data.id)

  if (index !== -1) {
    newListSubscription[index] = {
      ...data,
      createdDate: selectedSubscription.createdDate,
      forProject: selectedSubscription.forProject,
      packageType: selectedSubscription.packageType,
    }
  }

  try {
    await packagesApi.updateSubscriptionPackage(data)
    dispatch({
      type: UPDATE_SUBSCRIPTION_PACKAGE,
      payload: {
        listSubscription: newListSubscription,
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const selectStatusSubscription = (status) => (dispatch, getState) => {
  const { subscription } = getState()
  const { listSubscriptionPaymentRequest, termListSubscriptionPaymentRequest } = subscription

  if (status === 'all') {
    dispatch({
      type: FILTER_LIST_SUBSCRIPTION,
      payload: {
        listSubscriptionPaymentRequest: termListSubscriptionPaymentRequest,
      },
    })
  } else {
    dispatch({
      type: FILTER_LIST_SUBSCRIPTION,
      payload: {
        listSubscriptionPaymentRequest: listSubscriptionPaymentRequest.filter(
          (item) => item.status === Number(status),
        ),
      },
    })
  }
}

export const clearSelectSubscription = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECT_SUBSCRIPTION,
    payload: {
      selectedSubscription: {},
    },
  })
}

export const selectSubscription = (data) => (dispatch) => {
  dispatch({
    type: SELECT_SUBSCRIPTION,
    payload: {
      selectedSubscription: data,
    },
  })
}

export const createSubscriptionPackage = (data) => async (dispatch, getState) => {
  const { subscription } = getState()
  const { listSubscription } = subscription
  try {
    const { msgResp } = await packagesApi.createSubscriptionPackage(data)
    dispatch({
      type: CREATE_PACKAGE_SUBSCRIPTION,
      payload: {
        listSubscription: [...listSubscription, msgResp],
      },
    })
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const getListPackageSubscription = () => async (dispatch) => {
  try {
    const { msgResp } = await packagesApi.getListPackageByType(PACKAGE_SUBSCRIPTION)
    dispatch({
      type: GET_LIST_PACKAGE_SUBSCRIPTION,
      payload: {
        listSubscription: msgResp,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_STATUS_PAYMENT_REQUEST:
    case GET_LIST_PAYMENT_REQUEST:
    case UPDATE_SUBSCRIPTION_PACKAGE:
    case CLEAR_SELECT_SUBSCRIPTION:
    case FILTER_LIST_SUBSCRIPTION:
    case SELECT_SUBSCRIPTION:
    case CREATE_PACKAGE_SUBSCRIPTION:
    case GET_LIST_PACKAGE_SUBSCRIPTION:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default subscriptionReducer
