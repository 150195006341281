import React, { useEffect, useState } from 'react'
import { Modal, Pagination, Spinner } from 'react-bootstrap'
import { useIndexedDB } from 'react-indexed-db'
import { useSelector } from 'react-redux'

import useQuery from '_hooks/useQuery'
import EventItem from '../components/EventItem'
import './style.css'

function EventList() {
  const { getAll } = useIndexedDB('events')

  const { listAllEvents, isSyncDataEvent } = useSelector((state) => state.events)

  const [showNotification, setShowNotification] = useState(false)
  const [listEvent, setListEvent] = useState()

  const [paginationPage, setPaginationPage] = useState()
  const [paginationListEvent, setPaginationListEvent] = useState()

  const params = useQuery()
  const getGroupId = params.get('gid')

  useEffect(() => {
    getAll().then((dataEvents) => {
      if (dataEvents?.length > 0) {
        setListEvent(dataEvents?.filter((event) => event.groupId === getGroupId))
        setShowNotification(false)
      } else {
        setShowNotification(true)
      }
    })
  }, [])

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListEvent({
        events: listEvent?.slice(0, 10),
        index: 0,
        pageAtive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(listEvent?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListEvent({
        events: listEvent?.slice(currentPage, nextPage),
        index: currentPage,
        pageAtive: page,
      })
    }
  }

  useEffect(() => {
    if (listAllEvents?.length > 0) {
      const getEventsByGid = listAllEvents?.filter((event) => event.groupId === getGroupId)
      setListEvent(getEventsByGid)
    }
  }, [listAllEvents])

  useEffect(() => {
    setPaginationListEvent({
      events: listEvent?.slice(0, 10),
      index: 0,
      pageAtive: 1,
    })
  }, [listEvent])

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(listEvent?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListEvent?.pageAtive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    if (listEvent?.length >= 10) {
      const component = (
        <Pagination>
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListEvent?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListEvent?.pageAtive + 1)} />
          <Pagination.Last onClick={() => handlePagination(Math.ceil(listEvent?.length / 10))} />
        </Pagination>
      )
      setPaginationPage(component)
    }
  }, [paginationListEvent])

  if (showNotification) {
    const handleClose = () => setShowNotification(false)
    return (
      <>
        <Modal show={showNotification} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>No data</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sync data</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn-color-primary rounded-pill text-white px-3 py-1'
              onClick={handleClose}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  if (isSyncDataEvent) {
    return (
      <>
        <Modal show={isSyncDataEvent} animation={false} centered className='overlay__syncData'>
          <Modal.Body className='overlay__syncData-body'>
            <Spinner animation='border' variant='info' />
          </Modal.Body>
        </Modal>
      </>
    )
  }

  if (listEvent?.length === 0) {
    return (
      <p style={{ textAlign: 'center', lineHeight: '50vh' }}>
        <b>No Event !</b>
      </p>
    )
  }

  return (
    <>
      <div className='container-fluid'>
        <h3 className='event__list-heading'>Events</h3>
        <div className='scroll__mobile'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='event__list-title-text'>
                  <th scope='col' style={{ width: '28px' }}>
                    #
                  </th>
                  <th scope='col'>
                    <span>Title</span>
                  </th>
                  <th scope='col' style={{ width: '150', textAlign: 'center' }}>
                    <span>Total products</span>
                  </th>
                  <th scope='col'>
                    <span>Group by</span>
                  </th>
                  <th scope='col'>
                    <span>Date</span>
                  </th>
                  <th scope='col'>
                    <span>Created by</span>
                  </th>
                  <th scope='col' style={{ width: '150', textAlign: 'center' }}>
                    <span>Total orders</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginationListEvent?.events
                  ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                  ?.map((event, i) => (
                    <EventItem info={event} key={i} index={paginationListEvent?.index + i} />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
      </div>
    </>
  )
}
export default EventList
