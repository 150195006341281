/* eslint-disable jsx-a11y/control-has-associated-label */
import groupIcon from '_images/group-icon.png'
import { IS_ACTIVE, IS_ADMIN } from '_utils/constants'
import moment from 'moment'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import activeGroupIcon from '_images/activeGroupIcon.png'
import eventIcon from '_images/eventIcon.png'
import unActiveGroupIcon from '_images/unActiveGroupIcon.png'
import userIcon from '_images/userIcon.png'

function GroupItem({ info, index }) {
  const { id, name, photoUrl, active, totalMembers, totalEvents, description, members, createdAt } =
    info

  const administrators = members?.filter((member) => member.isAdmin === IS_ADMIN)

  return (
    <LinkContainer to={`/groups/detail-groups?gid=${id}`} style={{ cursor: 'pointer' }}>
      <tr>
        <th scope='row' className='group__list-number-index'>
          {index + 1}
        </th>
        <td className='group__list__name'>
          <img src={photoUrl || groupIcon} alt='' />
          <span className='group__list__name-text'>{name || 'No Name'}</span>
        </td>

        <td className='group__list__creation-date'>
          <span>{moment.unix(createdAt).format('DD/MM/YYYY')}</span>
        </td>

        <td className='group__list__desc'>
          <span> {description || 'No Description'} </span>
        </td>

        <td className='group__list__admin'>
          {administrators?.map((admin, i) => (
            <span key={i}>{administrators?.length > 1 ? `${admin.name},` : admin.name}</span>
          ))}
        </td>

        <td className='group__list__member'>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='button-tooltip-2'>Click to watch member list</Tooltip>}
          >
            <div className='group__list__member-box'>
              <img src={userIcon} alt='' />
              <span>{totalMembers || 0}</span>
            </div>
          </OverlayTrigger>
        </td>

        <LinkContainer to={`/events/list-event?gid=${id}`}>
          <td className='group__list__event'>
            <OverlayTrigger
              placement='bottom'
              overlay={<Tooltip id='button-tooltip-2'>Click to watch event list</Tooltip>}
            >
              <div className='group__list__event-box'>
                <img src={eventIcon} alt='' />
                <span>{totalEvents || 0}</span>
              </div>
            </OverlayTrigger>
          </td>
        </LinkContainer>

        <td className='group__list__status-icon'>
          <img src={active === IS_ACTIVE ? activeGroupIcon : unActiveGroupIcon} alt='' />
        </td>
      </tr>
    </LinkContainer>
  )
}
export default GroupItem
