import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import EventDetail from './EventDetail'
import EventList from './EventList'

function Events() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/list-event`} component={EventList} />
      <Route path={`${path}/detail-event`} component={EventDetail} />
    </Switch>
  )
}

export default Events
