/* eslint-disable no-unused-vars */
import premiumIcon from '_images/premiumIcon.png'
import { IS_APPROVED_PREMIUM_STATUS } from '_utils/constants'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'

import { listRequestContext } from '_context/ListRequest'

import 'react-toastify/dist/ReactToastify.css'

function UserPremium({ premiumFeature, userInfo }) {
  const { update } = useIndexedDB('users')

  const [isChecked, setIsChecked] = useState()
  const [expirationDate, setExpirationDate] = useState('')
  const { listUserWithPremium } = useContext(listRequestContext)

  useEffect(() => {
    const checkUserHavePremiumFeature = listUserWithPremium?.some((item) => {
      return item.id === userInfo.id
    })
    if (checkUserHavePremiumFeature) {
      const getPremiumByStatus = premiumFeature?.filter(
        (item) => item.premiumStatus === IS_APPROVED_PREMIUM_STATUS,
      )
      if (getPremiumByStatus?.length > 0) {
        const getExpirationDate = moment(getPremiumByStatus[0]?.approveTime, 'DD-MM-YYYY')
          .add(365, 'days')
          .format('DD/MM/YYYY')
        const currentDate = moment().format('YYYY-MM-DD')
        const convertExpirationDateByFormatToCheck = moment(
          getPremiumByStatus[0]?.approveTime,
          'DD-MM-YYYY',
        )
          .add(365, 'days')
          .format('YYYY-MM-DD')
        if (moment(currentDate).isAfter(convertExpirationDateByFormatToCheck)) {
          update({
            ...userInfo,
            premiumFeature: [
              {
                ...getPremiumByStatus[0],
                premiumStatus: 0,
              },
            ],
          })
          setIsChecked(false)
        } else {
          setExpirationDate(getExpirationDate)
          setIsChecked(true)
        }
      } else {
        setIsChecked(false)
      }
    }
  }, [listUserWithPremium])

  return (
    <>
      <div className='user__detail__premium'>
        <div className='user__detail__premium-box'>
          <div className='user__detail__premium-update'>
            <img src={premiumIcon} alt='' />
            <b>Premium user</b>
          </div>

          <div className='form-switch user__detail__premium-update-checkbox'>
            <input
              className='form-check-input user__detail__premium-input'
              role='switch'
              disabled
              type='checkbox'
              defaultChecked={isChecked}
            />
          </div>
        </div>

        {isChecked ? (
          <p className='user__detail__premium-expiration-date'>Expiration date: {expirationDate}</p>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default UserPremium
