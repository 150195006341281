import React, { useState, createContext } from 'react'

export const listRequestContext = createContext()

function ListRequest({ children }) {
    const [listUserWithSchedule,setListUserWithSchedule] = useState()
    const [listUserWithPremium,setListUserWithPremium] = useState()
    const [listUserWithSub, setListUserWithSub] = useState()

    return (
        <listRequestContext.Provider value={{ listUserWithSub, setListUserWithSub, listUserWithPremium, setListUserWithPremium, listUserWithSchedule, setListUserWithSchedule  }}  >
            {children}
        </listRequestContext.Provider>
    )
}

export default ListRequest