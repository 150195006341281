/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { SIZE_IMAGE, NUMBER_ENLARGE_DEFAULT } from '_utils/constants'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

function ImgGallery({ image, width }) {
  const [dimensions, setDimensions] = useState({
    dimensions: {
      height: SIZE_IMAGE,
      width: SIZE_IMAGE
    }
  })
  // get Size screen
  const screenSize = {
    height: window.innerHeight,
    width: window.innerWidth
  }
  const [numberEnlarge, setNumberEnlarge] = useState(NUMBER_ENLARGE_DEFAULT)
  const [imageSrc, setImageSrc] = useState(image)

  useEffect(() => {
    if (image && typeof image === 'object') {
      setImageSrc(image[0])
    } else {
      setImageSrc(image)
    }
  }, [image])

  useEffect(() => {
    if (screenSize.width && dimensions && dimensions.dimensions && dimensions.dimensions.width) {
      // enlargement ratio
      const newNumberEnlarge = Math.floor(screenSize.width / dimensions.dimensions.width)
      if (newNumberEnlarge < numberEnlarge) {
        setNumberEnlarge(newNumberEnlarge)
      } else {
        setNumberEnlarge(NUMBER_ENLARGE_DEFAULT)
      }
    }
  }, [
    dimensions?.dimensions?.height,
    dimensions?.dimensions?.width,
    screenSize?.width,
    screenSize?.height,
  ])
  return (
    <Gallery>
      <Item
        original={imageSrc}
        thumbnail={imageSrc}
        width={dimensions.dimensions.width * numberEnlarge}
        height={dimensions.dimensions.height * numberEnlarge}
      >
        {({ ref, open }) => (
          <img ref={ref}
            onClick={open}
            src={imageSrc}
            width={width}
            alt=''
          />
        )}
      </Item>
    </Gallery>
  )
}

export default ImgGallery