import React from 'react'
import moment from 'moment'
import { convertTimestampToCurrentGMT } from '_utils/functions'

import ImgGallery from '_components/ImgGallery'

function OrderItem({ info, index }) {
  const {
    uName,
    uPhotoUrl,
    uPhone,
    uEmail,
    pName,
    pQuantity,
    pPrice,
    createdAt,
    paymentScreenshot,
  } = info
  const convertCreatedAt = convertTimestampToCurrentGMT(createdAt)

  return (
    <>
      <tr>
        <td className='order__list-number-index'>{index + 1}</td>
        <td className='order__list__buyer'>
          <img src={uPhotoUrl} alt='' />
          <span>{uName}</span>
        </td>
        <td className='order__list__phone-number'>{uPhone}</td>
        <td className='order__list__email'>{uEmail}</td>
        <td className='order__list__productName'>
          <span>{pName}</span>
        </td>
        <td className='order__list__price'>${pPrice}</td>
        <td className='order__list__created-date'>
          {moment.unix(convertCreatedAt).format('DD-MM-YYYY')}
        </td>
        <td>
          <span className='btn btn-outline-warning'>{pQuantity}</span>
        </td>
        <td className='order__payment'>
          {paymentScreenshot ? (
            <ImgGallery image={paymentScreenshot} width='36px' />
          ) : (
            <span style={{ color: '#7B676B' }}>No Payment</span>
          )}
        </td>
      </tr>
    </>
  )
}

export default OrderItem
