import axiosClient from './axiosClient'

const shopApi = {
  getListShopWithLazadaFeatureStatus: () => {
    const url = '/list-shop-lazada-feature'
    return axiosClient.get(url)
  },
  updateShopWithLazadaFeatureStatus: (id, data, status) => {
    const url = `/update-shop-lazada-feature?id=${id}&status=${status}`
    return axiosClient.patch(url, data)
  },
  updateShopWithLazadaFeatureStatusCancel: (id, status) => {
    const url = `/update-shop-lazada-feature?id=${id}&status=${status}`
    return axiosClient.patch(url)
  },
  getListShop: (startDate, endDate) => {
    const url = '/list-shop'
    return axiosClient.get(url, {
      params: {
        start: startDate,
        end: endDate,
      },
    })
  },
  updateShop: (shopId, status) => {
    const url = 'update-status-shop'
    return axiosClient.patch(url, {
      shopId,
      status,
    })
  },
}

export default shopApi
