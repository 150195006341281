import useQuery from '_hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { Container, Modal, Pagination, Spinner } from 'react-bootstrap'
import { useIndexedDB } from 'react-indexed-db'
import { useSelector } from 'react-redux'
import OrderItem from '../components/OrderItem'
import './style.css'

function OrderList() {
  const { getAll } = useIndexedDB('orders')
  const { listAllOrders, isSyncDataOrder } = useSelector((state) => state.events)

  const params = useQuery()
  const eid = params.get('eid')

  const [showNotification, setShowNotification] = useState(false)

  const [listOrder, setListOrder] = useState()
  const [paginationPage, setPaginationPage] = useState()
  const [paginationListOrder, setPaginationListOrder] = useState()

  useEffect(() => {
    getAll().then((dataOrder) => {
      if (dataOrder?.length > 0) {
        const getListOrderByEid = dataOrder?.filter((order) => {
          return order.eid === eid
        })
        setListOrder(getListOrderByEid)
        setShowNotification(false)
      } else {
        setShowNotification(true)
      }
    })
  }, [])

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListOrder({
        orders: listOrder?.slice(0, 10),
        index: 0,
        pageAtive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(listOrder?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListOrder({
        orders: listOrder?.slice(currentPage, nextPage),
        index: currentPage,
        pageAtive: page,
      })
    }
  }

  useEffect(() => {
    if (listAllOrders?.length > 0) {
      setListOrder(listAllOrders)
    }
  }, [listAllOrders])

  useEffect(() => {
    setPaginationListOrder({
      orders: listOrder?.slice(0, 10),
      index: 0,
      pageAtive: 1,
    })
  }, [listOrder])

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(listOrder?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListOrder?.pageAtive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    if (listOrder?.length >= 10) {
      const component = (
        <Pagination>
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListOrder?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListOrder?.pageAtive + 1)} />
          <Pagination.Last onClick={() => handlePagination(Math.ceil(listOrder?.length / 10))} />
        </Pagination>
      )
      setPaginationPage(component)
    }
  }, [paginationListOrder])

  if (showNotification) {
    const handleClose = () => setShowNotification(false)
    return (
      <>
        <Modal show={showNotification} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>No data</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sync data</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn-color-primary rounded-pill text-white px-3 py-1'
              onClick={handleClose}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  if (isSyncDataOrder) {
    return (
      <>
        <Modal show={isSyncDataOrder} animation={false} centered className='overlay__syncData'>
          <Modal.Body>
            <Spinner animation='border' variant='info' />
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <Container fluid>
      <h3 className='order__list-heading-text'>Orders</h3>
      <div className='over-scroll'>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr className='order__list-title-text'>
                <th style={{ width: '24px' }}>#</th>
                <th>User</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Product</th>
                <th>Price</th>
                <th>Date</th>
                <th>Quantity</th>
                <th>Payment</th>
              </tr>
            </thead>
            <tbody>
              {paginationListOrder?.orders
                ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                ?.map((order, i) => (
                  <OrderItem info={order} index={paginationListOrder?.index + i} key={i} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
    </Container>
  )
}

export default OrderList
