import ContactRequests from './pages/ContactRequests'
import reportData from './pages/DashBroad/TablesData'
import Events from './pages/Events'
import Groups from './pages/Group'
import LazadaApp from './pages/LazadaApp'
import LazadaFeature from './pages/LazadaFeature'
import LiveReport from './pages/LiveReport'
import Orders from './pages/Orders'
import Package from './pages/Package'
import Subscription from './pages/Package/Subscription'
import PaymentRequest from './pages/PaymentRequest'
import Users from './pages/Users'

const config = [
  {
    route: '/',
    page: reportData,
  },
  {
    route: '/groups',
    page: Groups,
  },
  {
    route: '/dashboard',
    page: reportData,
  },
  {
    route: '/events',
    page: Events,
  },
  {
    route: '/users',
    page: Users,
  },
  {
    route: '/live-report',
    page: LiveReport,
  },
  {
    route: '/orders',
    page: Orders,
  },
  {
    route: '/lazada-feature',
    page: LazadaFeature,
  },
  {
    route: '/lazada-app',
    page: LazadaApp,
  },
  {
    route: '/subscription',
    page: Subscription,
  },
  {
    route: '/package',
    page: Package,
  },
  {
    route: '/list-payment-request',
    page: PaymentRequest,
  },
  {
    route: '/contact-requests',
    page: ContactRequests,
  },
]

export default config
