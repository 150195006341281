export const USER_TOKEN = 'USER_TOKEN'
export const DATA_REPORT = 'DATA_REPORT'

// Group
export const IS_HOST = 1
export const IS_ADMIN = 1
export const IS_BANNED = 1

// Event
export const IS_ACTIVE = 1
export const IS_PAYNOW = 1

// DATE_FORMAT
export const DATE_FORMAT = 'DD-MM-YYYY HH:mm'

// User premium

export const IS_PENDING_SCHEDULE_STATUS = 1
export const IS_APPROVED_SCHEDULE_STATUS = 2
export const IS_CANCEL_SCHEDULE_STATUS = 0

export const PAYMENT_STATUS_SCHEDULE_APPROVED = 3
export const PAYMENT_STATUS_SCHEDULE_CANCEL = -1

export const IS_PENDING_PREMIUM_STATUS = 1
export const IS_APPROVED_PREMIUM_STATUS = 2
export const IS_CANCEL_PREMIUM_STATUS = 0

export const PAYMENT_STATUS_PREMIUM_APPROVED = 3
export const PAYMENT_STATUS_PREMIUM_CANCEL = -1

export const SUBSCRIPTION_FEE_TRIAL = 0

export const SUBSCRIPTION_STATUS_PENDING = 1
export const SUBSCRIPTION_STATUS_APPROVED = 2
export const SUBSCRIPTION_STATUS_CANCEL = -3
export const SUBSCRIPTION_STATUS_REFUND = -1
export const SUBSCRIPTION_STATUS_REFUNDED = -2
export const SUBSCRIPTION_STATUS_EXPIRED = 0
export const SUBSCRIPTION_STATUS_FAIL = -4

export const SUBSCRIPTION_PAYMENT_STATUS_VERIFY = 3
export const SUBSCRIPTION_PAYMENT_STATUS_PAID = 2
export const SUBSCRIPTION_PAYMENT_STATUS_UNPAID = 1
export const SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN = -1
export const SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_HOST = -2
export const SUBSCRIPTION_PAYMENT_STATUS_FAILED = -3

export const PAYMENT_METHOD_TRANSFER = 1
export const PAYMENT_METHOD_HIT_PAY = 2

// Lazada feature
export const IS_PENDING_LAZADA_FEATURE_STATUS = 1
export const IS_APPROVED_LAZADA_FEATURE_STATUS = 2
export const IS_CANCEL_LAZADA_FEATURE_STATUS = 0
export const PAYMENT_STATUS_LAZADA_FEATURE_APPROVED = 3
export const PAYMENT_STATUS_LAZADA_FEATURE_CANCEL = -2

export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_FAILED = -3
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_CANCEL_BY_USER = -2
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_CANCEL_BY_ADMIN = -1
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_UNPAID = 1
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_PAID = 2
export const LAZADA_FEATURE_PAYMENT_STATUS_PAYMENT_VERIFY = 3

export const LAZADA_FEATURE_PAYMENT_STATUS_APPROVED = 2
export const LAZADA_FEATURE_PAYMENT_STATUS_PENDING = 1
export const LAZADA_FEATURE_PAYMENT_STATUS_CANCEL = 0
export const LAZADA_FEATURE_PAYMENT_STATUS_REFUND = -1
export const LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED = -2

// Wix feature
export const IS_PENDING_WIX_FEATURE_STATUS = 1
export const IS_APPROVED_WIX_FEATURE_STATUS = 2
export const IS_CANCEL_WIX_FEATURE_STATUS = 0
export const PAYMENT_STATUS_WIX_FEATURE_APPROVED = 3
export const PAYMENT_STATUS_WIX_FEATURE_CANCEL = -2

export const SIZE_IMAGE = 256
export const NUMBER_ENLARGE_DEFAULT = 3

export const TOKEN_EXPIRED = 99999
export const UNAUTHORIZED = 'Unauthorized'

export const LIST_PACKAGE_TYPE = [
  {
    name: 'Subscription',
    type: 1,
    forProject: 'co-kii',
  },
  {
    name: 'Lazada',
    type: 2,
    forProject: 'co-kii',
  },
  {
    name: 'Schedule',
    type: 3,
    forProject: 'co-bee',
  },
  {
    name: 'SubDomain',
    type: 4,
    forProject: 'co-bee',
  },
  {
    name: 'Co-bee Desktop',
    type: 5,
    forProject: 'co-bee',
  },
]

export const LIST_PROJECT = [
  {
    name: 'Co-bee',
    type: 1,
  },
  {
    name: 'Co-kii',
    type: 2,
  },
]

export const PACKAGE_SUBSCRIPTION = 1
export const PACKAGE_LAZADA = 2
export const TURN_ON = 1

export const LIST_TYPE_SUBSCRIPTION_PACKAGE = [
  {
    name: 'Basic',
    type: 1, // or bas1
    features: {
      maxProductQty: 5,
      bigDiscountRate: false,
      comprehensiveOffer: false,
      ChatWithAdmin: false,
      shopPlacement: false,
    },
  },
  {
    name: 'Professional',
    type: 2,
    features: {
      maxProductQty: null,
      bigDiscountRate: false,
      comprehensiveOffer: true,
      ChatWithAdmin: false,
      ShopPopular: true,
    },
  },
  {
    name: 'Founder',
    type: 3,
    features: {
      maxProductQty: null,
      bigDiscountRate: true,
      comprehensiveOffer: true,
      ChatWithAdmin: true,
      ShopPopular: true,
    },
  },
]

export const MONTHLY_VALUE = 1
export const ANNUALLY_VALUE = 12
export const OPTION_CURRENCY_SG = {
  style: 'currency',
  currency: 'SGD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
}

export const optionToast = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
}
