import React, { useEffect, useState } from 'react'
import { Modal, Pagination, Spinner } from 'react-bootstrap'
import { useIndexedDB } from 'react-indexed-db'
import { useSelector } from 'react-redux'

import UserItem from '../components/UserItem'

import './style.css'

function UserList() {
  const { listAllUsers, isSyncDataUser } = useSelector((state) => state.users)

  const { getAll } = useIndexedDB('users')
  const [showNotification, setShowNotification] = useState(false)
  const [listUser, setListUser] = useState()

  const [paginationPage, setPaginationPage] = useState()
  const [paginationListUser, setPaginationListUser] = useState()

  useEffect(() => {
    getAll().then((dataUser) => {
      if (dataUser?.length > 0) {
        setListUser(dataUser)
        setShowNotification(false)
      } else {
        setShowNotification(true)
      }
    })
  }, [])

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListUser({
        users: listUser?.slice(0, 10),
        index: 0,
        pageAtive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(listUser?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListUser({
        users: listUser?.slice(currentPage, nextPage),
        index: currentPage,
        pageAtive: page,
      })
    }
  }

  useEffect(() => {
    if (listAllUsers?.length > 0) {
      setListUser(listAllUsers)
    } else {
    }
  }, [listAllUsers])

  useEffect(() => {
    setPaginationListUser({
      users: listUser?.slice(0, 10),
      index: 0,
      pageAtive: 1,
    })
  }, [listUser])

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(listUser?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListUser?.pageAtive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    if (listUser?.length >= 10) {
      const component = (
        <Pagination>
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListUser?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListUser?.pageAtive + 1)} />
          <Pagination.Last onClick={() => handlePagination(Math.ceil(listUser?.length / 10))} />
        </Pagination>
      )

      setPaginationPage(component)
    }
  }, [paginationListUser])

  if (showNotification) {
    const handleClose = () => setShowNotification(false)
    return (
      <>
        <Modal show={showNotification} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>No data</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sync data</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn-color-primary rounded-pill text-white px-3 py-1'
              onClick={handleClose}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  if (isSyncDataUser) {
    return (
      <>
        <Modal show={isSyncDataUser} animation={false} centered className='overlay__syncData'>
          <Modal.Body className='overlay__syncData-body'>
            <Spinner animation='border' variant='info' />
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <>
      <div className='container-fluid'>
        <h3 className='user__list-heading'>Users</h3>
        <div className='scroll__mobile'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='user__list-title'>
                  <th style={{ width: '32px' }}>#</th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Email</span>
                  </th>
                  <th>
                    <span>Phone</span>
                  </th>
                  <th>
                    <span>Join date</span>
                  </th>
                  <th>
                    <span>Groups</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginationListUser?.users
                  ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                  ?.map((user, i) => (
                    <UserItem user={user} key={i} index={paginationListUser?.index + i} />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
      </div>
    </>
  )
}

export default UserList
