import dashboardApi from '_api/dashboardApi'
import { setDataReport } from '_utils/localData'

const GET_DASH_BROAD = 'GET_DASH_BROAD'
const GET_DATA_LIVE_REPORT = 'GET_DATA_LIVE_REPORT'
const GET_DATA_LIVE_REPORT_BY_DATE = ' GET_DATA_LIVE_REPORT_BY_DATE'
const GET_LIST_PACKAGE = 'GET_LIST_PACKAGE'

const initialState = {
  listDashBroad: null,
  dataLiveReport: null,
  dataLiveReportByDate: null,
  listPackage: null,
}

export const getListPackage = async (dispatch) => {
  let listPackage
  try {
    const response = await dashboardApi.getListPackage()
    if (response.msgResp) {
      listPackage = response.msgResp
    }
    dispatch({
      payload: listPackage,
      type: GET_LIST_PACKAGE
    })

  } catch (e) {
    throw Error(e)
  }
}

export const getDashBroad = async (dispatch) => {
  let listDashBroad
  try {
    const response = await dashboardApi.getDashboard()
    if (response?.msgResp) {
      listDashBroad = response.msgResp
      setDataReport(listDashBroad)
    }
    dispatch({
      type: GET_DASH_BROAD,
      payload: listDashBroad,
    })
  } catch (error) {}
}

export const getDataLiveReport = async (dispatch) => {
  let dataLiveReport
  try {
    const response = await dashboardApi.getDataLiveReport()
    if (response?.msgResp) {
      dataLiveReport = response.msgResp
    }
    dispatch({
      type: GET_DATA_LIVE_REPORT,
      payload: dataLiveReport,
    })
  } catch (error) {}
}

export const getDataLiveReportByDate = (start, end) => async (dispatch) => {
  let dataLiveReportByDate
  try {
    const response = await dashboardApi.getDataLiveReportByDate(start, end)
    if (response?.msgResp) {
      dataLiveReportByDate = response.msgResp
    }
    dispatch({
      type: GET_DATA_LIVE_REPORT_BY_DATE,
      payload: dataLiveReportByDate,
    })
  } catch (error) {}
}

const dashBroadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASH_BROAD: {
      return {
        ...state,
        listDashBroad: action.payload,
      }
    }

    case GET_DATA_LIVE_REPORT: {
      return {
        ...state,
        dataLiveReport: action.payload,
      }
    }

    case GET_DATA_LIVE_REPORT_BY_DATE: {
      return {
        ...state,
        dataLiveReportByDate: action.payload,
      }
    }

    case GET_LIST_PACKAGE: {
      return {
        ...state,
        listPackage: action.payload
      }
    }
    default:
      return state
  }
}

export default dashBroadReducer
