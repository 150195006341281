import React, { useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import { Tooltip, OverlayTrigger, Button, Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import moment from 'moment'

function EventItem(props) {
  const { uid } = props

  const { getAll } = useIndexedDB('events')
  const [eventByUid, setEventByUid] = useState()
  const [paginationPage, setPaginationPage] = useState()
  const [paginationListEvent, setPaginationListEvent] = useState()

  useEffect(() => {
    getAll().then((dataEvent) => {
      const getEventByUid = dataEvent?.filter((event) => event.createdUserId === uid)
      setEventByUid(getEventByUid)
    })
  }, [])

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListEvent({
        events: eventByUid?.slice(0, 10),
        index: 0,
        pageAtive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(eventByUid?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListEvent({
        events: eventByUid?.slice(currentPage, nextPage),
        index: currentPage,
        pageAtive: page,
      })
    }
  }

  useEffect(() => {
    setPaginationListEvent({
      events: eventByUid?.slice(0, 10),
      index: 0,
      pageAtive: 1,
    })
  }, [eventByUid])

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(eventByUid?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListEvent?.pageAtive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>
      )
    }

    if (eventByUid?.length >= 10) {
      const component = (
        <Pagination>
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListEvent?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListEvent?.pageAtive + 1)} />
          <Pagination.Last onClick={() => handlePagination(Math.ceil(eventByUid?.length / 10))} />
        </Pagination>
      )
      setPaginationPage(component)
    }
  }, [paginationListEvent])

  return (
    <>
      {eventByUid?.length > 0 ? (
        <>
          <table className='table'>
            <thead>
              <tr className='user__detail-event-list-title'>
                <th>#</th>
                <th>Event Name</th>
                <th>Description</th>
                <th>Date</th>
                <th>Group by</th>
                <th>Event detail</th>
              </tr>
            </thead>
            <tbody>
              {paginationListEvent?.events?.map((event, i) => (
                <tr key={i}>
                  <td className='user__detail-event-list-mumber-index'>
                    {paginationListEvent?.index + i + 1}
                  </td>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip id='button-tooltip-2'>{event.title}</Tooltip>}
                  >
                    <td className='user__detail-event-list-avatar-name'>
                      <img src={event.photoUrls[0]} alt='' />
                      <span>{event.title}</span>
                    </td>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip id='button-tooltip-2'>{event.description}</Tooltip>}
                  >
                    <td className='user__detail-event-list-description'>
                      <span>{event.description}</span>
                    </td>
                  </OverlayTrigger>
                  <td className='user__detail-event-list-created-date'>
                    {moment.unix(event.createdAt).format('DD/MM/YYYY')}
                  </td>
                  <td className='user__detail-event-list-group-name'>
                    <span>{event.groupName}</span>
                  </td>
                  <td>
                    <LinkContainer to={`/events/detail-event?eid=${event.id}`}>
                      <Button className='user__detail-event-list-to-watch-btn btn-color-primary'>
                        Detail
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
        </>
      ) : (
        <p className='text-secondary'>
          <b>This user has not created an event yet !</b>
        </p>
      )}
    </>
  )
}

export default EventItem
