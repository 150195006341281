import React from 'react'

function TableDataItem({ data }) {
  return (
    <>
      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='card-body-info'>
              <h5 className='card-title'>Total Users</h5>
              <p className='card-text'>
                <b>{data?.totalUsers || 0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='card-body-info'>
              <h5 className='card-title'>Total Groups</h5>
              <p className='card-text'>
                <b>{data?.totalGroups || 0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='card-body-info' style={{ alignItems: 'center' }}>
              <h5 className='card-title'>Total Events</h5>
              <p className='card-text'>
                <b>{data?.totalEvents || 0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='card-body-info'>
              <h5 className='card-title'>Total Value</h5>
              <p className='card-text'>
                <b>${data?.totalValue.toFixed(2) || 0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='card-body-info'>
              <h5 className='card-title'>Total Paid</h5>
              <p className='card-text'>
                <b>${data?.totalPaidValue.toFixed(2) || 0}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TableDataItem
