import axiosClient from './axiosClient'

const subscriptionApi = {
  getListPaymentRequest: () => {
    const url = '/get-list-subscription-payment'
    return axiosClient.get(url)
  },
  updateStatusPaymentRequest: (params, data) => {
    const url = `/update-subscription-status?id=${params.id}&status=${params.status}`
    return axiosClient.patch(url, data)
  },
}

export default subscriptionApi
