/* eslint-disable import/prefer-default-export */

import contactApi from '../../api/contactApi'

/* eslint-disable no-undef */
const initialState = {
  listContactRequest: [],
}

const GET_LIST_CONTACT = 'GET_LIST_CONTACT'

export const getListContact = () => async (dispatch) => {
  try {
    const { msgResp } = await contactApi.getListContact()

    dispatch({
      type: GET_LIST_CONTACT,
      payload: {
        listContactRequest: msgResp,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_CONTACT:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default contactReducer
