import { selectStatusSubscription } from '_redux/reducers/subscription'
import {
  SUBSCRIPTION_STATUS_APPROVED,
  SUBSCRIPTION_STATUS_CANCEL,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_REFUNDED,
} from '_utils/constants'
import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import {
  changeStatusPaymentRequest,
  getListSubscriptionPaymentRequest,
} from '../../../redux/reducers/subscription'
import { SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN } from '../../../utils/constants'
import '../../Package/style.css'
import '../style.css'
import ModalPaymentInfo from './ModalPaymentInfo'
import ModalRefund from './ModalRefund'
import RequestItem from './RequestItem'

const optionToast = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
}

const tableHeaders = [
  '#',
  'User Name',
  'Phone',
  'Email',
  'Package',
  'Price',
  'Expire Date',
  'Payment Method',
  'Status',
  'Payment Status',
  'Actions',
]

const ListSubscriptionPaymentRequest = () => {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubmittingForm, setIsSubmittingForm] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [isOpenModalRefund, setIsOpenModalRefund] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')
  const [priceRefund, setPriceRefund] = useState('')
  const inputRefundRef = useRef(null)

  const { listSubscriptionPaymentRequest } = useSelector((state) => state.subscription)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(getListSubscriptionPaymentRequest())
      setLoading(false)
    }

    fetchData().then(() => {})
  }, [])

  useEffect(() => {
    if (isOpenModalRefund && inputRefundRef) {
      inputRefundRef.current.focus()
    }
  }, [isOpenModalRefund])

  const toggleModalRefund = () => setIsOpenModalRefund(!isOpenModalRefund)

  const onSelectRequestItem = (requestItem) => {
    setSelectedSubscription(requestItem)
  }

  const handleRefund = async () => {
    const postData = {
      paymentStatus: SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN,
      status: SUBSCRIPTION_STATUS_REFUNDED,
      priceRefund,
    }

    try {
      setIsSubmittingForm(true)
      await dispatch(changeStatusPaymentRequest(postData, subscriptionId))

      toast.success('Refunded subscription', optionToast)
    } catch (e) {
      toast.error(e.message, optionToast)
    } finally {
      setIsSubmittingForm(false)
      setPriceRefund('')
      toggleModalRefund()
    }
  }

  const handleChangeStatus = async (e, _subscriptionId) => {
    const { value: status } = e.target
    setSubscriptionId(_subscriptionId)

    if (Number(status) === SUBSCRIPTION_STATUS_REFUNDED) {
      if (window.confirm('Are you sure you want to refund this subscription?')) {
        toggleModalRefund()
      }
    }

    // else {
    //   const confirmMessage = 'Are you sure you want to change status of this subscription?'
    //   let paymentStatus = SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN
    //
    //   if (Number(status) === SUBSCRIPTION_STATUS_APPROVED) {
    //     paymentStatus = SUBSCRIPTION_PAYMENT_STATUS_VERIFY
    //   }
    //
    //   const postStatus = {
    //     paymentStatus,
    //     status,
    //   }
    //
    //   try {
    //     if (!confirmMessage || window.confirm(confirmMessage)) {
    //       await dispatch(changeStatusPaymentRequest(postStatus, _subscriptionId))
    //       toast.success('Update Status Success', optionToast)
    //     }
    //   } catch (error) {
    //     toast.error(error.message, optionToast)
    //   }
    // }
  }

  return (
    <div className='list-subscription-payment-request'>
      <ToastContainer limit={1} />
      <div className='select'>
        <label className='table-choose-status'>Choose shop subscription status:</label>
        <select
          className='table-select-status'
          onChange={(e) => dispatch(selectStatusSubscription(e.target.value))}
        >
          <option value='all'>All</option>
          <option value={SUBSCRIPTION_STATUS_APPROVED}>Approved</option>
          <option value={SUBSCRIPTION_STATUS_PENDING}>Pending</option>
          <option value={SUBSCRIPTION_STATUS_CANCEL}>Cancel</option>
          <option value={SUBSCRIPTION_STATUS_REFUNDED}>Refunded</option>
        </select>
      </div>
      <div className='scroll__mobile'>
        <div className='table-responsive'>
          <table className='table subscription'>
            <thead>
              <tr className='table-list-title'>
                {tableHeaders.map((headerText, index) => (
                  <th scope='col' key={index} className='f-14'>
                    <span>{headerText}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='f-14'>
              {loading && (
                <tr>
                  <td colSpan={tableHeaders.length} className='text-center'>
                    Loading...
                  </td>
                </tr>
              )}

              {!loading && listSubscriptionPaymentRequest.length
                ? listSubscriptionPaymentRequest.map((info, i) => (
                    <RequestItem
                      item={info}
                      key={info.id}
                      index={i}
                      handleChangeStatus={handleChangeStatus}
                      onSelectRequestItem={onSelectRequestItem}
                    />
                  ))
                : null}

              {!loading && !listSubscriptionPaymentRequest.length && (
                <tr>
                  <td colSpan={tableHeaders.length} className='text-center'>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isOpenModal && (
        <ModalPaymentInfo
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          selectedSubscription={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
        />
      )}

      {isOpenModalRefund && (
        <ModalRefund
          isOpenModal={isOpenModalRefund}
          toggleModal={toggleModalRefund}
          handleRefund={handleRefund}
          priceRefund={priceRefund}
          setPriceRefund={setPriceRefund}
          isSubmittingForm={isSubmittingForm}
          inputRefundRef={inputRefundRef}
        />
      )}
    </div>
  )
}

export default ListSubscriptionPaymentRequest
