import ImgGallery from '_components/ImgGallery'
import { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'

function ModalPaymentInfo({
  isOpenModal,
  setIsOpenModal,
  selectedSubscription,
  setSelectedSubscription,
}) {
  useEffect(() => {
    return () => {
      setSelectedSubscription({})
    }
  }, [])

  return (
    <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Payment Info</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'text-center'}>
        {selectedSubscription?.paymentScreenshot ? (
          <ImgGallery image={selectedSubscription.paymentScreenshot} width={200} />
        ) : null}

        {selectedSubscription?.paymentLink ? (
          <a href={selectedSubscription.paymentLink} target='_blank' rel='noopener noreferrer'>
            {selectedSubscription.paymentLink}
          </a>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setIsOpenModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalPaymentInfo
