import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Lazada from './Lazada'
import Subscription from './Subscription'
import './style.css'

const Package = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/subscription`} component={Subscription} />
      <Route path={`${path}/lazada`} component={Lazada} />
    </Switch>
  )
}

export default Package
