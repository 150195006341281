/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { getUserToken } from '_utils/localData'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()

  function RenderComponent(props) {
    const checkToken = getUserToken()
    if (!checkToken) {
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
            state: { from: location },
          }}
        />
      )
    }
    return <Component {...props} />
  }
  return <Route {...rest} render={RenderComponent} />
}
export default ProtectedRoute
