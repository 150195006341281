import * as Yup from 'yup'

const schemaLazada = Yup.object()
  .shape({
    name: Yup.string()
      .min(3, 'Name must have at least 3 characters')
      .required('App name is required'),
    price: Yup.number()
      .typeError('Amount must be a number')
      .integer()
      .required('Price is required'),
    description: Yup.string()
      .min(3, 'Description must have at least 3 characters')
      .required('App country is required'),
    expireValue: Yup.number()
      .typeError('Amount must be a number')
      .integer()
      .required('Expire value is required'),
    discountPrice: Yup.mixed()
      .test('isNumberOrEmptyString', 'Discount must be a number or an empty string', (value) => {
        if (value === null || value === undefined || value === '') {
          return true
        }
        if (typeof value === 'string' && value.trim() === '') {
          return true
        }
        // eslint-disable-next-line no-restricted-globals
        return !isNaN(value)
      })
      .test(
        'discountLessThanPrice',
        'Discount must be less than or equal to Price',
        // eslint-disable-next-line func-names
        function (discountPrice) {
          // eslint-disable-next-line no-restricted-globals
          const { price } = this.parent
          return discountPrice <= price
        },
      )
      .test(
        'discountGreaterThanOrEqualZero',
        'Discount must be greater than or equal 0',
        // eslint-disable-next-line func-names
        function (discountPrice) {
          return discountPrice >= 0
        },
      )
      .required('Discount price is required'),
  })
  .required()

export default schemaLazada
