import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import OrderList from './OrderList'

function Orders() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/list-order`} component={OrderList} />
    </Switch>
  )
}

export default Orders
