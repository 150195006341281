/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Pagination } from 'react-bootstrap'
import activeGroupIcon from '_images/activeGroupIcon.png'
import unActiveGroupIcon from '_images/unActiveGroupIcon.png'

import { IS_ACTIVE } from '_utils/constants'
import groupIcon from '_images/group-icon.png'

function GroupItem({ groups }) {
  const [paginationPage, setPaginationPage] = useState()
  const [paginationListGroup, setPaginationListGroup] = useState()

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListGroup({
        groups: groups?.slice(0, 10),
        index: 0,
        pageAtive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(groups?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListGroup({
        groups: groups?.slice(currentPage, nextPage),
        index: currentPage,
        pageAtive: page,
      })
    }
  }

  useEffect(() => {
    setPaginationListGroup({
      groups: groups?.slice(0, 10),
      index: 0,
      pageAtive: 1,
    })
  }, [groups])

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(groups?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListGroup?.pageAtive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    if (groups?.length >= 10) {
      const component = (
        <Pagination>
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListGroup?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListGroup?.pageAtive + 1)} />
          <Pagination.Last onClick={() => handlePagination(Math.ceil(groups?.length / 10))} />
        </Pagination>
      )
      setPaginationPage(component)
    }
  }, [paginationListGroup])

  return (
    <div className='scroll__mobile'>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr className='liveReport__newGroups-title'>
              <th style={{ width: '30px' }}>#</th>
              <th>Name</th>
              <th style={{ textAlign: 'center' }}>Date</th>
              <th>Description</th>
              <th style={{ width: '145px', textAlign: 'center' }}>Total members</th>
              <th style={{ width: '145px', textAlign: 'center' }}>Total events</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {paginationListGroup?.groups
              ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              ?.map((group, i) => (
                <tr key={group.id}>
                  <th scope='row'>{paginationListGroup?.index + i + 1}</th>
                  <td className='liveReport__newGroups__name__avatar'>
                    <img src={group.photoUrl || groupIcon} alt='' />
                    <span>{group.name || 'No Name'}</span>
                  </td>
                  <td
                    className='liveReport__newGroups__created-date'
                    style={{ textAlign: 'center' }}
                  >
                    <span>{moment.unix(group.createdAt).format('DD/MM/YYYY')}</span>
                  </td>
                  <td className='liveReport__newGroups__description'>
                    <span> {group.description || 'No Description'} </span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span className='btn btn-outline-warning'>{group.totalMembers || 0}</span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span className='btn btn-outline-warning'>{group.totalEvents || 0}</span>
                  </td>
                  <td>
                    <div className='liveReport__newGroups__status'>
                      <img
                        src={group.active === IS_ACTIVE ? activeGroupIcon : unActiveGroupIcon}
                        alt=''
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
      </div>
    </div>
  )
}
export default GroupItem
