/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useIndexedDB } from 'react-indexed-db'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import { getAllEvent, getEventsByDate, setIsSyncDataEvent } from '_redux/reducers/events'
import { getAllGroups, getGroupsByDate, setIsSyncDataGroup } from '_redux/reducers/groups'
import { getAllOrder, getOrdersByDate, setIsSyncDataOrder } from '_redux/reducers/orders'
import { getAllUsers, getUsersByDate, setIsSyncDataUser } from '_redux/reducers/users'

import { checkDuplicate, clearDuplicate } from '_utils/functions'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import './style.css'

function PeriodModal(props) {
  const { onHide } = props

  const dispatch = useDispatch()

  const { listGroupByDate } = useSelector((state) => state.groups)
  const { listUserByDate } = useSelector((state) => state.users)
  const { listEventByDate } = useSelector((state) => state.events)
  const { listOrderByDate } = useSelector((state) => state.orders)

  const [checkInDate, setCheckInDate] = useState(null)
  const [checkOutDate, setCheckOutDate] = useState(null)

  const handleCheckInDate = (date) => {
    setCheckInDate(date)
    setCheckOutDate(null)
  }

  const handleCheckOutDate = (date) => {
    setCheckOutDate(date)
  }

  const handleSearchData = () => {
    const startDay = moment(checkInDate).utc('0').unix()
    const endDay = moment(checkOutDate).utc('0').unix()
    if (startDay && endDay) {
      dispatch(getGroupsByDate(startDay, endDay))
      dispatch(setIsSyncDataGroup(true))
      dispatch(getUsersByDate(startDay, endDay))
      dispatch(setIsSyncDataUser(true))
      dispatch(getEventsByDate(startDay, endDay))
      dispatch(setIsSyncDataEvent(true))
      dispatch(getOrdersByDate(startDay, endDay))
      dispatch(setIsSyncDataOrder(true))
      onHide()
    } else {
      toast.error('Please choose date 🤯')
    }
  }

  function handleDuplicateArray(dataIndex, data) {
    if (dataIndex?.length > 0) {
      const mergeArray = dataIndex?.concat(data)
      const arrayAfterCleanDuplicate = clearDuplicate(dataIndex, mergeArray)
      const isDuplicate = checkDuplicate(dataIndex, arrayAfterCleanDuplicate)
      if (isDuplicate) return arrayAfterCleanDuplicate
    } else {
      return data
    }
  }

  function addGroups(dataGroups) {
    const { add, getAll } = useIndexedDB('groups')
    if (dataGroups?.length > 0) {
      getAll()
        .then((data) => {
          const groups = handleDuplicateArray(data, dataGroups)
          return groups
        })
        .then((groups) => {
          dispatch(getAllGroups([]))
          const checkIsSuccess = groups?.map((group) => add({ ...group }))
          return checkIsSuccess
        })
        .then((checkIsSuccess) => {
          getAll().then((getGroups) => {
            dispatch(getAllGroups(getGroups))
            dispatch(setIsSyncDataGroup(false))
          })
        })
    }
  }

  function addUsers(dataUser) {
    const { add, getAll } = useIndexedDB('users')
    if (dataUser?.length > 0) {
      getAll()
        .then((data) => {
          const users = handleDuplicateArray(data, dataUser)
          return users
        })
        .then((users) => {
          dispatch(getAllUsers([]))
          const checkIsSuccess = users?.map((user) => add({ ...user }))
          return checkIsSuccess
        })
        .then((checkIsSuccess) => {
          getAll().then((getUsers) => {
            dispatch(getAllUsers(getUsers))
            dispatch(setIsSyncDataUser(false))
          })
        })
    }
  }

  function addEvents(dataEvents) {
    const { add, getAll } = useIndexedDB('events')
    if (dataEvents?.length > 0) {
      getAll()
        .then((data) => {
          const events = handleDuplicateArray(data, dataEvents)
          return events
        })
        .then((events) => {
          dispatch(getAllEvent([]))
          const checkIsSuccess = events?.map((event) => add({ ...event }))
          return checkIsSuccess
        })
        .then((checkIsSuccess) => {
          getAll().then((getEvents) => {
            dispatch(getAllEvent(getEvents))
            dispatch(setIsSyncDataEvent(false))
          })
        })
    }
  }

  function addOrders(dataOrders) {
    const { add, getAll } = useIndexedDB('orders')
    if (dataOrders?.length > 0) {
      getAll()
        .then((data) => {
          const orders = handleDuplicateArray(data, dataOrders)
          return orders
        })
        .then((orders) => {
          dispatch(getAllOrder([]))
          const checkIsSuccess = orders?.map((order) => add({ ...order }))
          return checkIsSuccess
        })
        .then((checkIsSuccess) => {
          getAll().then((getOrders) => {
            dispatch(getAllOrder(getOrders))
            dispatch(setIsSyncDataOrder(false))
          })
        })
    }
  }

  useEffect(() => {
    addGroups(listGroupByDate)
  }, [listGroupByDate])

  useEffect(() => {
    addEvents(listEventByDate)
  }, [listEventByDate])

  useEffect(() => {
    addUsers(listUserByDate)
  }, [listUserByDate])

  useEffect(() => {
    addOrders(listOrderByDate)
  }, [listOrderByDate])

  return (
    <Modal {...props} size='lg' centered backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Choose Period</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='datePicker'>
          <div className='datePicker__startDay'>
            <div>
              <b>Start Day</b>
            </div>
            <DatePicker
              selected={checkInDate}
              onChange={handleCheckInDate}
              placeholderText='DD/MM/YYYY'
              dateFormat='dd/MM/yyyy'
              className='ps-2'
            />
          </div>

          <div className='datePicker__endDay'>
            <div>
              <b>End Day</b>
            </div>
            <DatePicker
              selected={checkOutDate}
              minDate={checkInDate}
              onChange={handleCheckOutDate}
              placeholderText='DD/MM/YYYY'
              dateFormat='dd/MM/yyyy'
              className='ps-2'
            />
          </div>
          <button
            type='button'
            className='sync__data-btn btn-color-primary text-white px-3'
            onClick={handleSearchData}
          >
            Sync
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          variant='secondary'
          className='sync__data-btn-close btn-color-grey'
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ButtonSyncData() {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <ToastContainer autoClose={2000} limit={1} />
      <Button variant='primary' onClick={() => setModalShow(true)} className='btn__sync__data'>
        Sync Data
      </Button>

      <PeriodModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default ButtonSyncData
