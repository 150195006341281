import { isNaN } from 'lodash'
import { Form, Modal } from 'react-bootstrap'

const ModalRefund = (props) => {
  const {
    isOpenModal,
    toggleModal,
    handleRefund,
    priceRefund,
    setPriceRefund,
    isSubmittingForm,
    inputRefundRef,
  } = props

  return (
    <Modal show={isOpenModal} onHide={toggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>Refund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className='mb-3' controlId='priceRefund'>
          <Form.Label>Price Refund ($):</Form.Label>
          <Form.Control
            type='text'
            ref={inputRefundRef}
            value={priceRefund}
            onChange={(e) =>
              setPriceRefund(isNaN(Number(e.target.value)) ? 0 : Number(e.target.value))
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleRefund()
              }
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          type={'button'}
          className={'btn bg-transparent text-secondary border-0 shadow-none'}
          onClick={toggleModal}
        >
          Cancel
        </button>

        <button
          type={'button'}
          className={'btn btn-color-primary text-white shadow-none'}
          onClick={handleRefund}
          disabled={isSubmittingForm}
        >
          {isSubmittingForm ? 'Refunding...' : 'Refund'}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalRefund
