import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'

import shopApi from '_api/shopApi'
import 'react-toastify/dist/ReactToastify.css'

import {
  LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED,
} from '_utils/constants'

function ButtonCancelLazadaFeature({ shopWithLazadaFeature, updateNewStatus }) {
  const [show, setShow] = useState(false)

  const toggleShow = () => {
    setShow(show !== true)
  }

  const handleReject = async () => {
      try {

        await shopApi.updateShopWithLazadaFeatureStatusCancel(
          shopWithLazadaFeature?.id, LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED
        )
        setShow(false)
        updateNewStatus(LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED)
        toast.success('Update successful')
      } catch (error) {
        toast.error(error.msgResp)
      }
  }

  return (
    <>
      <ToastContainer autoClose={2000} limit={1} />
      <Button onClick={toggleShow} className='lazada-feature__cancel-btn ms-3' variant='danger'>
        Cancel
      </Button>
      <Modal show={show} onHide={toggleShow} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Reject Request Lazada Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure you want to cancel this subscription ? You must contact this shop owner for a refund
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant='danger'
            className='lazada-feature__cancel-btn'
            style={{ borderRadius: '6px' }}
            onClick={toggleShow}
          >
            Close
          </Button>
          <Button
            className='lazada-feature__approve-btn btn-color-primary'
            style={{ borderRadius: '6px' }}
            onClick={handleReject}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ButtonCancelLazadaFeature
