import { selectLazada } from '_redux/reducers/lazada'
import { LIST_PACKAGE_TYPE, TURN_ON } from '_utils/constants'
import moment from 'moment/moment'
import React from 'react'
import { useDispatch } from 'react-redux'

const LazadaItem = ({ info, index, toggleModal }) => {
  const dispatch = useDispatch()
  const onEdit = (item) => {
    dispatch(selectLazada(item))
    toggleModal()
  }

  return (
    <tr>
      <th scope='row' className='table__index' style={{ width: '30px' }}>
        {index + 1}
      </th>

      <td className='table__item'>
        <span>{info.name}</span>
      </td>

      <td className='table__item'>
        <span>{moment.unix(info.createdDate).format('DD/MM/YYYY')}</span>
      </td>

      <td className='table__item' width={200}>
        <span>{info.description}</span>
      </td>

      <td className='table__item'>
        <span>{info.forProject}</span>
      </td>

      <td className='table__item'>
        <span>{info.active === TURN_ON ? 'on' : 'off'}</span>
      </td>

      <td className='table__item'>
        <span>{LIST_PACKAGE_TYPE.find((item) => item.type === info.packageType)?.name}</span>
      </td>

      <td>
        <span className='table__item-actions justify-content-start'>
          <button
            type='button'
            className='btn btn-success rounded-pill'
            onClick={() => onEdit(info)}
          >
            Edit
          </button>
        </span>
      </td>
    </tr>
  )
}

export default LazadaItem
