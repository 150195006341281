import { IS_ADMIN } from '_utils/constants'
import { calculatePercentMatch } from '_utils/functions'
import React, { useEffect, useRef, useState } from 'react'
import { FormControl, Modal, Pagination, Spinner } from 'react-bootstrap'
import { useIndexedDB } from 'react-indexed-db'
import { useSelector } from 'react-redux'

import GroupItem from '../components/GroupItem.jsx'

import './style.css'

function GroupList() {
  const { listAllGroups, isSyncDataGroup } = useSelector((state) => state.groups)
  const { getAll } = useIndexedDB('groups')
  const [showNotification, setShowNotification] = useState(false)
  const [listGroup, setListGroup] = useState(null)
  const typingTimeoutRef = useRef(null)
  const [paginationPage, setPaginationPage] = useState()
  const [paginationListGroup, setPaginationListGroup] = useState()

  useEffect(() => {
    getAll().then((dataGroups) => {
      if (dataGroups?.length > 0) {
        setListGroup(dataGroups)
        setShowNotification(false)
      } else {
        setShowNotification(true)
      }
    })
  }, [])

  const handlePagination = (page) => {
    if (page === 1) {
      setPaginationListGroup({
        groups: listGroup?.slice(0, 10),
        index: 0,
        pageAtive: 1,
      })
    } else if (page <= 0) {
    } else if (page > Math.ceil(listGroup?.length / 10)) {
    } else {
      const nextPage = page * 10
      const currentPage = nextPage - 10
      setPaginationListGroup({
        groups: listGroup?.slice(currentPage, nextPage),
        index: currentPage,
        pageAtive: page,
      })
    }
  }

  useEffect(() => {
    if (listAllGroups?.length > 0) {
      setListGroup(listAllGroups)
    }
  }, [listAllGroups])

  useEffect(() => {
    setPaginationListGroup({
      groups: listGroup?.slice(0, 10),
      index: 0,
      pageAtive: 1,
    })
  }, [listGroup])

  useEffect(() => {
    const items = []
    for (let number = 1; number <= Math.ceil(listGroup?.length / 10); number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationListGroup?.pageAtive}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>,
      )
    }

    if (listGroup?.length >= 10) {
      const component = (
        <Pagination>
          <Pagination.First onClick={() => handlePagination(1)} />
          <Pagination.Prev onClick={() => handlePagination(paginationListGroup?.pageAtive - 1)} />
          {items}
          <Pagination.Next onClick={() => handlePagination(paginationListGroup?.pageAtive + 1)} />
          <Pagination.Last onClick={() => handlePagination(Math.ceil(listGroup?.length / 10))} />
        </Pagination>
      )
      setPaginationPage(component)
    }
  }, [paginationListGroup])

  const handleOnChange = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (listGroup) {
        if (e.target.value !== '') {
          const result = listGroup.filter((list) =>
            list.members.some(
              (item) =>
                item.isAdmin === IS_ADMIN &&
                calculatePercentMatch(item.name.trim(), e.target.value.trim()) >= 0.4,
            ),
          )
          if (result.length === 0) {
            setListGroup(listGroup)
          } else {
            setListGroup(result)
          }
        } else {
          getAll().then((groups) => {
            setListGroup(groups)
          })
        }
      }
    }, 500)
  }

  if (showNotification) {
    const handleClose = () => setShowNotification(false)
    return (
      <>
        <Modal show={showNotification} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>No data</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sync data</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn-color-primary rounded-pill text-white px-3 py-1'
              onClick={handleClose}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  if (isSyncDataGroup) {
    return (
      <>
        <Modal show={isSyncDataGroup} animation={false} centered className='overlay__syncData'>
          <Modal.Body className='overlay__syncData-body'>
            <Spinner animation='border' variant='info' />
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <>
      <div className='container-fluid'>
        <h3 className='group__list-heading'>Groups</h3>
        <div className='scroll__mobile'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr className='group__list-title-text'>
                  <th scope='col' style={{ width: '20px' }}>
                    <span>#</span>
                  </th>
                  <th scope='col' style={{ width: '204px' }}>
                    <span>Name</span>
                  </th>
                  <th scope='col' style={{ width: '220px', textAlign: 'center' }}>
                    <span>Date</span>
                  </th>
                  <th scope='col' style={{ width: '280px' }}>
                    <span>Description</span>
                  </th>
                  <th scope='col' style={{ width: '360px' }}>
                    <FormControl
                      type='search'
                      placeholder='Search Administrators'
                      className='me-2 group__list-search'
                      onChange={(e) => handleOnChange(e)}
                      aria-label='Search'
                      style={{ width: '360px' }}
                    />
                  </th>
                  <th scope='col' style={{ width: '100px', textAlign: 'center' }}>
                    <span>Members</span>
                  </th>
                  <th scope='col' style={{ width: '128px', textAlign: 'center' }}>
                    <span>Events</span>
                  </th>
                  <th scope='col' style={{ width: '70px' }}>
                    <span>Active</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginationListGroup?.groups
                  ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                  ?.map((group, i) => (
                    <GroupItem key={i} info={group} index={paginationListGroup?.index + i} />
                  ))}
              </tbody>
            </table>

            <div style={{ display: 'flex', justifyContent: 'center' }}>{paginationPage}</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GroupList
