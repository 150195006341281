/* eslint-disable no-unused-vars */
import scheduleIcon from '_images/scheduleIcon.png'
import { IS_APPROVED_SCHEDULE_STATUS } from '_utils/constants'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'

import { listRequestContext } from '_context/ListRequest'

import 'react-toastify/dist/ReactToastify.css'

function UserPremium({ scheduleFeatures, userInfo }) {
  const { update } = useIndexedDB('users')

  const [isChecked, setIsChecked] = useState()
  const [expirationDate, setExpirationDate] = useState('')
  const { listUserWithSchedule } = useContext(listRequestContext)

  useEffect(() => {
    const checkUserHaveScheduleFeature = listUserWithSchedule?.some((item) => {
      return item.id === userInfo.id
    })
    if (checkUserHaveScheduleFeature) {
      const getScheduleByStatus = scheduleFeatures?.filter(
        (item) => item.scheduleStatus === IS_APPROVED_SCHEDULE_STATUS,
      )
      if (getScheduleByStatus?.length > 0) {
        const getExpirationDate = moment(getScheduleByStatus[0]?.approveTime, 'DD-MM-YYYY')
          .add(365, 'days')
          .format('DD/MM/YYYY')
        const currentDate = moment().format('YYYY-MM-DD')
        const convertExpirationDateByFormatToCheck = moment(
          getScheduleByStatus[0]?.approveTime,
          'DD-MM-YYYY',
        )
          .add(365, 'days')
          .format('YYYY-MM-DD')
        if (moment(currentDate).isAfter(convertExpirationDateByFormatToCheck)) {
          update({
            ...userInfo,
            scheduleFeature: [
              {
                ...getScheduleByStatus[0],
                scheduleStatus: 0,
              },
            ],
          })
          setIsChecked(false)
        } else {
          setExpirationDate(getExpirationDate)
          setIsChecked(true)
        }
      } else {
        setIsChecked(false)
      }
    }
  }, [listUserWithSchedule])

  return (
    <>
      <div className='user__detail-schedule-event'>
        <div className='user__detail-schedule-event-box'>
          <div className='user__detail-schedule-event-update'>
            <img src={scheduleIcon} alt='' />
            <b>Schedule</b>
          </div>

          <div className='form-switch user__detail-schedule-event-update-checkbox'>
            <input
              className='form-check-input user__detail-schedule-event-input'
              role='switch'
              disabled
              type='checkbox'
              defaultChecked={isChecked}
            />
          </div>
        </div>

        {isChecked ? (
          <p className='user__detail-schedule-event-expiration-date'>
            Expiration date: {expirationDate}
          </p>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default UserPremium
