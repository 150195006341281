import React from 'react'

function AppItem({ item, index }) {
  if (!item) return null

  const { lazadaAppName, lazadaAppKey, createdDate, shopSeller, lazadaAppCountry } = item
  // let isFullSlot;
  return (
    <tr>
      <th scope='row' className='item-col item-number-index' style={{ width: '30px' }}>
        {index + 1}
      </th>

      <td className='item-col item-name-avatar'>
        <span>{lazadaAppName}</span>
      </td>

      <td className='item-col item-email'>
        <span>{lazadaAppKey}</span>
      </td>

      <td className='item-col item-phone'>
        <span>{lazadaAppCountry}</span>
      </td>

      {/* <td className='item-phone'>
        <span>{isFullSlot.toString()}</span>
      </td> */}
      <td className='item-col item-schedulePrice'>
        <span>{shopSeller.length}/20</span>
      </td>
      <td className='item-col item-statusGroup'>
        <span>{createdDate}</span>
      </td>
    </tr>
  )
}

export default AppItem
