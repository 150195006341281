import axiosClient from './axiosClient'

const contactApi = {
  getListContact: () => {
    const url = '/list-contact-email'
    return axiosClient.get(url)
  },
}

export default contactApi
