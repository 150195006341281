import HitPay from '_images/hitpay-logo.jpg'
import {
  LAZADA_FEATURE_PAYMENT_STATUS_APPROVED,
  LAZADA_FEATURE_PAYMENT_STATUS_CANCEL,
  LAZADA_FEATURE_PAYMENT_STATUS_PENDING,
  LAZADA_FEATURE_PAYMENT_STATUS_REFUND,
  LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED,
  PAYMENT_METHOD_HIT_PAY,
  PAYMENT_METHOD_TRANSFER,
  SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN,
  SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_HOST,
  SUBSCRIPTION_PAYMENT_STATUS_FAILED,
  SUBSCRIPTION_PAYMENT_STATUS_PAID,
  SUBSCRIPTION_PAYMENT_STATUS_UNPAID,
  SUBSCRIPTION_PAYMENT_STATUS_VERIFY,
  SUBSCRIPTION_STATUS_REFUNDED,
} from '_utils/constants'
import { isNull } from 'lodash'
import moment from 'moment'
import React, { useContext } from 'react'
import { AiFillCreditCard } from 'react-icons/ai'
import { useSelector } from 'react-redux'

import { LazadaPaymentRequestContext } from './index'

function getStatusClassName(status) {
  switch (status) {
    case LAZADA_FEATURE_PAYMENT_STATUS_APPROVED:
      return 'bg-success'
    case LAZADA_FEATURE_PAYMENT_STATUS_PENDING:
      return 'bg-warning'
    case LAZADA_FEATURE_PAYMENT_STATUS_CANCEL:
      return 'bg-secondary'
    case LAZADA_FEATURE_PAYMENT_STATUS_REFUND:
    case LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED:
      return 'text-secondary'
    default:
      return 'bg-info'
  }
}

function getStatusText(status) {
  switch (status) {
    case LAZADA_FEATURE_PAYMENT_STATUS_APPROVED:
      return 'Approved'
    case LAZADA_FEATURE_PAYMENT_STATUS_PENDING:
      return 'Pending'
    case LAZADA_FEATURE_PAYMENT_STATUS_REFUND:
      return 'Refund'
    case LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED:
      return 'Refunded'
    case LAZADA_FEATURE_PAYMENT_STATUS_CANCEL:
      return 'Cancel'
    default:
      return 'N/A'
  }
}

const paymentStatusToClass = {
  [SUBSCRIPTION_PAYMENT_STATUS_PAID]: 'bg-success',
  [SUBSCRIPTION_PAYMENT_STATUS_UNPAID]: 'bg-warning',
  [SUBSCRIPTION_PAYMENT_STATUS_VERIFY]: 'bg-info',
  [SUBSCRIPTION_PAYMENT_STATUS_FAILED]: 'bg-danger',
  [SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN]: 'bg-secondary',
  default: 'bg-info', // Default class
}

const paymentStatusToText = {
  [SUBSCRIPTION_PAYMENT_STATUS_PAID]: 'Paid',
  [SUBSCRIPTION_PAYMENT_STATUS_UNPAID]: 'Unpaid',
  [SUBSCRIPTION_PAYMENT_STATUS_VERIFY]: 'Verify',
  [SUBSCRIPTION_PAYMENT_STATUS_FAILED]: 'Failed',
  [SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_ADMIN]: 'Cancel By Admin',
  [SUBSCRIPTION_PAYMENT_STATUS_CANCEL_BY_HOST]: 'Cancel By User',
  default: 'N/A', // Default text
}

function RequestItem({ item, index }) {
  const { onChangeLazAppForPaymentReq, onViewPaymentInfo, onChangeStatus } = useContext(
    LazadaPaymentRequestContext,
  )

  const { listAppLazada } = useSelector((state) => state.lazada)

  return (
    <tr>
      <th scope='row' className='table__index' style={{ width: '30px' }}>
        {index + 1}
      </th>

      <td className='table__item'>
        <span>{item.shopName}</span>
      </td>

      <td className='table__item'>
        <span>{item.shopPhone}</span>
      </td>

      <td className='table__item'>
        <span>{item.shopEmail}</span>
      </td>

      <td className='table__item'>
        <span>{item.name}</span>
      </td>

      <td className='table__item'>
        <span>${item.price}</span>
      </td>

      <td className='table__item'>
        <span>
          {!isNull(item.lastModifiedAt)
            ? moment.unix(item.lastModifiedAt).format('DD/MM/YYYY')
            : 'N/A'}
        </span>
      </td>

      <td className='table__item'>
        {item.paymentType === PAYMENT_METHOD_HIT_PAY ? (
          <a href={item.paymentLink} target={'_blank'} rel='noreferrer'>
            <img src={HitPay} className={'img-fluid me-2'} width={30} alt='HitPay' />
            <span>Hitpay</span>
          </a>
        ) : item.paymentType === PAYMENT_METHOD_TRANSFER ? (
          <div className={'d-flex flex-column'}>
            <button
              type={'button'}
              className='btn p-0 group'
              onClick={() => onViewPaymentInfo(item)}
              style={{ color: '#7c797a', transform: 'translateX(-11px)' }}
            >
              <AiFillCreditCard color={'#f1c40f'} size={30} className={'me-2'} />
              <span className={'f-14'}>Payment Banking</span>
            </button>
            {/* <ImgGallery image={item?.paymentScreenshot} width={50} /> */}
          </div>
        ) : (
          'Free'
        )}
      </td>

      <td className='table__item'>
        <span className={`badge ${getStatusClassName(item.status)}`}>
          {getStatusText(item.status)}
        </span>
      </td>

      <td className='table__item'>
        <span
          className={`badge ${
            paymentStatusToClass[item.paymentStatus] || paymentStatusToClass.default
          }`}
        >
          {paymentStatusToText[item.paymentStatus] || paymentStatusToText.default}
        </span>
      </td>
      <td>
        {item.lazadaAppInfo?.lazadaAppKey &&
        item.status !== LAZADA_FEATURE_PAYMENT_STATUS_PENDING ? (
          <span className='text-secondary fw-bold'>{item.lazadaAppInfo?.lazadaAppName}</span>
        ) : (
          <select
            name='app'
            id={`app-${index}`}
            value={JSON.stringify(item.lazadaAppInfo)}
            onChange={(e) => onChangeLazAppForPaymentReq(e, item.id)}
            className={`custom-form-select form-select-sm border ${item.status === SUBSCRIPTION_STATUS_REFUNDED ? 'cursor-not-allowed' : ''}`}
            disabled={item.status === SUBSCRIPTION_STATUS_REFUNDED}
          >
            {listAppLazada.map((app) => (
              <option value={JSON.stringify(app)} key={`${app.id}-${index}`}>
                {app.lazadaAppName}
              </option>
            ))}
          </select>
        )}
      </td>

      <td>
        <select
          name='action-status'
          id='action-status'
          value={item.status}
          onChange={(e) => onChangeStatus(e, item)}
          className={`custom-form-select form-select-sm border ${
            item.status === SUBSCRIPTION_STATUS_REFUNDED ? 'cursor-not-allowed' : ''
          }`}
          disabled={item.status === SUBSCRIPTION_STATUS_REFUNDED}
        >
          <option value=''>Choose status</option>
          <option value={LAZADA_FEATURE_PAYMENT_STATUS_APPROVED}>Approve</option>
          {/* <option value={LAZADA_FEATURE_PAYMENT_STATUS_CANCEL}>Cancel</option> */}
          {/* <option value={LAZADA_FEATURE_PAYMENT_STATUS_REFUNDED}>Refund</option> */}
        </select>
      </td>
    </tr>
  )
}

export default RequestItem
