import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import LazadaFeatureRequestList from './LazadaFeatureRequestList'

const lazadaFeature = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/list-request`} component={LazadaFeatureRequestList} />
    </Switch>
  )
}

export default lazadaFeature
